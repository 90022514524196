import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Button,
  Avatar,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Stack,
  useToast,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import "./CalendarioAgendamentoMentorado.css";

import {
  IAgendamentoFree,
  IAgendamentoFreeAPI,
  IDateAgendamento,
  closeAgendaAPI,
} from "../helpers/agendaHelper";
import { useAuth } from "../context/AuthProvider/useAuth";

import { CustomModalOverlay } from "../pages/admin/trilha/components/CustomModalOverlay";
import { converterData } from "../utils/formatarData";
import { convertToClientTimezone } from "../utils/convertTimezone";
import { FaGlobeAmericas } from "react-icons/fa";

interface Props {
  mentor: string;
  img: string;
  desc: string;
  availableDates: IAgendamentoFree;
  refreshCB: () => void;
}

export function CardMentorAgendaAberta({
  mentor,
  img,
  desc,
  availableDates,
  refreshCB,
}: Props) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedDateConfirm, setSelectedDateConfirm] = useState<Date | null>(
    null
  );
  const [selectedTimeConfirm, setSelectedTimeConfirm] = useState<string | null>(
    null
  );
  const [idAgendamento, setIdAgendamento] = useState("");

  const auth = useAuth();
  const toast = useToast();

  const convertTime = (e: string) => {
    const date = e.split("-");
    return `${date[2]}/${date[1]}/${date[0]}`;
  };

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    openModal();
  };

  const handleTimeClick = (time_id: string, time: string) => {
    setSelectedDateConfirm(selectedDate);
    setSelectedTimeConfirm(time);
    setIdAgendamento(time_id);
    setIsConfirmModalOpen(true);
  };
  const getCurrentTimezone = (): string => {
    // Captura o fuso horário atual usando a API Intl
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  const timezone = getCurrentTimezone()
  const confirmTimeSelection = async () => {
    if (idAgendamento && selectedDateConfirm) {
      setIsLoading(true);
      try {
        const response = await closeAgendaAPI(
          auth.payLoad?.user.id as string,
          idAgendamento,
          timezone
        );

        if (response.msg === "ok") {
          toast({
            position: "top-right",
            title: "Sucesso",
            description: `Horário agendado com sucesso! ${converterData(
              String(selectedDate)
            )} às ${selectedTimeConfirm}`,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          refreshCB(); // Chama a função para atualizar os dados
          closeModal();
          closeConfirmModal();
        } else {
          const errorMsg =
            response.msg || "Ocorreu um erro ao realizar o agendamento.";
          toast({
            position: "top-right",
            title: "Erro",
            description: errorMsg,
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      } catch (error: any) {
        let errorMsg = "Erro desconhecido ao realizar o agendamento.";
        if (error.response && error.response.data && error.response.data.msg) {
          errorMsg = error.response.data.msg;
        }
        toast({
          position: "top-right",
          title: "Erro",
          description: errorMsg,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
        closeConfirmModal();
      }
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <HStack
        w={"100%"}
        h="123px"
        spacing={"16px"}
        borderRadius={"8px"}
        backgroundColor={"xmentor.background_content"}
        border="1px"
        borderColor={"xmentor.borderCards"}
      >
        <Image
          h="100%"
          w="auto"
          rounded={1}
          borderLeftRadius={"8px"}
          borderRightRadius={"0px"}
          // backgroundColor={"xmentor.primary"}
          alt={mentor}
          src={img}
        />
        <VStack align={"start"} spacing={0} py={2}>
          <Heading fontSize={"20px"} fontWeight={600}>
            {mentor}
          </Heading>
          <Text fontSize={"14px"} fontWeight={500} textColor={"gray.600"}>
            {desc}
          </Text>
          <Button
            h={"36px"}
            variant={"outline"}
            colorScheme="blue"
            mt="10px"
            onClick={() => handleDateClick(new Date())}
          >
            Agendar
          </Button>
        </VStack>
      </HStack>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size={
          selectedDate &&
            availableDates[convertTime(selectedDate.toISOString().split("T")[0])]
            ? "3xl"
            : "xl"
        }
        isCentered
      >
        {" "}
        <CustomModalOverlay />
        <ModalContent transition={"0.5s all"}>
          <ModalHeader>Selecione a data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              direction={["column", "row"]}
              spacing="24px"
              justify={"start"}
              pb={5}
            >
              <VStack>
                <Image
                  w="140px"
                  h="auto"
                  rounded={14}
                  // backgroundColor={"xmentor.primary"}
                  alt={mentor}
                  src={img}
                />
                <Heading fontSize={"20px"} fontWeight={500}>
                  {mentor}
                </Heading>
                <Text fontSize={"14px"} fontWeight={500}>
                  {desc}
                </Text>                
              </VStack>
              <Calendar
                tileClassName={({ date }) => {
                  const dateString = date.toISOString().split("T")[0];
                  return availableDates[convertTime(dateString)]
                    ? "highlight"
                    : "";
                }}
                onChange={(date) => handleDateClick(date as Date)}
                value={selectedDate || new Date()}
                tileDisabled={({ date }) => {
                  const currentDate = new Date();
                  return date < currentDate;
                }}
              />
              
              {selectedDate &&
                availableDates[
                convertTime(selectedDate.toISOString().split("T")[0])
                ] && (
                  <VStack
                    h={"100%"}
                    overflowX={"auto"}
                    overflowY={"hidden"}
                    whiteSpace={"nowrap"}
                    display={"block"}
                    w={"full"}
                  >
                    <Text fontSize={"19px"} fontWeight={500} mb={6}>
                      Horários disponíveis
                    </Text>

                    <SimpleGrid
                      columns={{ base: 1, md: 2, lg: 3 }}
                      w={"100%"}
                      spacing={"16px"}
                    >
                      {availableDates[
                        convertTime(selectedDate.toISOString().split("T")[0])
                      ]
                        .sort((a, b) => a.time.localeCompare(b.time)) // Aqui você aplica a ordenação
                        .map((time) => (
                          <Button
                            key={time.id}
                            size="sm"
                            onClick={() => handleTimeClick(time.id, time.time)}
                          >
                            {convertToClientTimezone({
                              date: selectedDate.toISOString().split("T")[0],
                              time: time.time,
                              clientTimezone: timezone,
                              serverTimezone: time.timezone
                            }).convertedDate.time}
                          </Button>
                        ))}
                    </SimpleGrid>
                  </VStack>
                )}
            </Stack>
            <HStack w='50%' align='center' justify='flex-start'>
              <FaGlobeAmericas />
              <Text fontSize={"x-small"}>{timezone}</Text>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        isCentered
        size="sm"
      >
        <CustomModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Você realmente deseja agendar?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={closeConfirmModal}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              isLoading={isLoading}
              onClick={confirmTimeSelection}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
