import {
  Divider,
  Button,
  Text,
  Flex,
  Icon,
  Box,
  useBreakpointValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Image,
  useTheme,
} from "@chakra-ui/react";
import { FaGraduationCap, FaUserGraduate } from "react-icons/fa";
import { RiAdminFill, RiPencilFill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { ReactNode, useState } from "react";
import { LogoSvg } from "../LogoSvg";
import { ButtonNavigation } from "../ButtonNavigation";
import { BsColumnsGap, BsKanban } from "react-icons/bs";
import { MdRoute } from "react-icons/md";
import { GrSchedules } from "react-icons/gr";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { motion } from "framer-motion";
import { LogoMenor } from "../LogoMenor";

interface Props {
  children?: ReactNode;
  isMenu?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsExpanded: (isExpanded: boolean) => void;
}

export function Sidebar({
  children,
  setIsExpanded,
  isMenu,
  isOpen,
  setIsOpen,
}: Props) {
  const theme = useTheme();

  const { logout, payLoad } = useAuth();
  const [isExpandedLocal, setIsExpandedLocal] = useState(false);
  const isVisible = useBreakpointValue({
    base: isMenu ? "flex" : "none",
    lg: "flex",
  });
  const isAuxiliar = payLoad?.user.perfil === "auxiliar";
  const isMentorAndAdmin =
    payLoad?.user.perfil === "mentor" && payLoad?.user.isAdmin;
  const corSidebar = payLoad?.configMentoria?.cor;
  const logoSidebar = payLoad?.configMentoria?.foto;
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;
  const handleMouseEnter = () => {
    setIsExpandedLocal(true);
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpandedLocal(false);
    setIsExpanded(false);
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => setIsOpen(false)}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <Flex
              flexDir="column"
              // background="#4963FF"
              background={"xmentor.primary"}
              color="white"
              paddingTop={16}
              paddingBottom={16}
              height="100%"
              overflow="hidden"
            >
              <Flex
                flexDir="column"
                w="100%"
                padding="0px 16px"
                alignItems="center"
                position="relative"
              >
                {/* <LogoSvg /> */}
                {logoSidebar ? (
                  <Image
                    src={`${process.env.REACT_APP_API}/tmp/mentoria/${logoSidebar}`}
                    alt="Logo"
                    width={"70px"} // Ajusta o tamanho da imagem conforme o estado expandido
                    height={"70px"} // Ajusta a altura conforme o estado expandido
                  />
                ) : (
                  <LogoMenor />
                )}
                {children && (
                  <Box position="absolute" right={0} top={0}>
                    {children}
                  </Box>
                )}
                <Divider my={5} opacity={0.2} />
              </Flex>
              <Flex
                padding="0px 16px"
                flexDirection="column"
                alignItems="flex-start"
                gap="16px"
                h="full"
              >
                <ButtonNavigation
                  key={1}
                  title="Início"
                  url="/admin/home"
                  icon={BsColumnsGap}
                  isExpanded={true}
                />
                <ButtonNavigation
                  key={2}
                  title="Gestão administrativa"
                  url="/admin/gestao"
                  icon={BsKanban}
                  isExpanded={true}
                />
                <ButtonNavigation
                  key={3}
                  title="Jornada da Mentoria"
                  url="/admin/jornada-trilha"
                  icon={MdRoute}
                  isExpanded={true}
                />
                <ButtonNavigation
                  key={4}
                  title="Reuniões"
                  url="/admin/agenda"
                  icon={FaRegCalendarCheck}
                  isExpanded={true}
                />
                <ButtonNavigation
                  key={5}
                  title="Eventos"
                  url="/admin/eventos"
                  icon={GrSchedules}
                  isExpanded={true}
                />
                {isAuxiliar ||
                  (isMentorAndAdmin && (
                    <>
                      <ButtonNavigation
                        key={6}
                        title="Auxiliares"
                        url="/admin/users"
                        icon={RiAdminFill}
                        isExpanded={true}
                      />
                      <ButtonNavigation
                        key={7}
                        title="Mentores"
                        url="/admin/users-mentores"
                        icon={FaUserGraduate}
                        isExpanded={true}
                      />
                    </>
                  ))}
                <ButtonNavigation
                  key={8}
                  title="Mentorados"
                  url="/admin/users-mentorados"
                  icon={FaGraduationCap}
                  isExpanded={true}
                />
                {isAuxiliar ||
                  (isMentorAndAdmin && (
                    <Box mt="auto" mb="32px">
                      <ButtonNavigation
                        key={9}
                        title="Configurações Mentoria"
                        url="/admin/config-mentoria"
                        icon={RiPencilFill}
                        isExpanded={true}
                      />
                    </Box>
                  ))}
              </Flex>
              <Flex flexDir="column" w="100%" alignItems="center">
                <Divider my={5} opacity={0.2} />
                <Button
                  display="flex"
                  padding={4}
                  alignItems="center"
                  color="white"
                  lineHeight="20px"
                  // bg={"xmentor.primary"}
                  bg={"xmentor.primary"}
                  onClick={logout}
                  colorScheme="facebook"
                >
                  <Text mr={4}>Sair</Text>
                  <Icon as={FiLogOut} fontSize="20" />
                </Button>
              </Flex>
            </Flex>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <motion.div
        onHoverStart={handleMouseEnter}
        onHoverEnd={handleMouseLeave}
        initial={{ width: 80 }}
        animate={{ width: isExpandedLocal ? 312 : 80 }}
        transition={{ duration: 0.3 }}
        style={{
          display: isVisible,
          flexDirection: "column",
          // background: "#4963FF",
          // background: corSidebar ? String(corSidebar) : "#4963FF" ,
          background: theme.colors.xmentor.primary,
          colorScheme: "facebook",
          color: "white",
          paddingTop: 16,
          paddingBottom: 16,
          height: "100%",
          position: "fixed",
          zIndex: 2,
          overflow: "hidden",
        }}
      >
        {/* <Flex
          flexDir="column"
          w="100%"
          padding="0px 16px"
          alignItems="center"
          position="relative"
        >
          {isExpandedLocal ? <LogoSvg /> : <LogoMenor />}
          {children && isExpandedLocal && (
            <Box position="absolute" right={0} top={0}>
              {children}
            </Box>
          )}
          <Divider my={5} opacity={0.2} />
        </Flex> */}

        <Flex
          flexDir="column"
          w="100%"
          padding="0px 16px"
          alignItems="center"
          position="relative"
          // bg="blue"
        >
          {logoSidebar ? (
            <Image
              src={`${process.env.REACT_APP_API}/tmp/mentoria/${logoSidebar}`}
              alt="Logo"
              width={isExpandedLocal ? "auto" : "50px"} // Ajusta o tamanho da imagem conforme o estado expandido
              height={isExpandedLocal ? "100px" : "50px"} // Ajusta a altura conforme o estado expandido
            />
          ) : isExpandedLocal ? (
            <LogoSvg />
          ) : (
            <LogoMenor />
          )}
          {children && isExpandedLocal && (
            <Box position="absolute" right={0} top={0}>
              {children}
            </Box>
          )}
          <Divider my={5} opacity={0.2} />
        </Flex>

        <Flex
          padding="0px 16px"
          flexDirection="column"
          alignItems={isExpandedLocal ? "flex-start" : "center"}
          gap="16px"
          h="100vh"
          bg="4963FF"
          overflow="auto"
        >
          <ButtonNavigation
            key={1}
            title="Início"
            url="/admin/home"
            icon={BsColumnsGap}
            isExpanded={isExpandedLocal}
          />
          <ButtonNavigation
            key={2}
            title="Gestão administrativa"
            url="/admin/gestao"
            icon={BsKanban}
            isExpanded={isExpandedLocal}
          />
          <ButtonNavigation
            key={3}
            title="Jornada da Mentoria"
            url="/admin/jornada-trilha"
            icon={MdRoute}
            isExpanded={isExpandedLocal}
          />
          <ButtonNavigation
            key={4}
            title="Reuniões"
            url="/admin/agenda"
            icon={FaRegCalendarCheck}
            isExpanded={isExpandedLocal}
          />
          <ButtonNavigation
            key={5}
            title="Eventos"
            url="/admin/eventos"
            icon={GrSchedules}
            isExpanded={isExpandedLocal}
          />
          <ButtonNavigation
            key={6}
            title="Auxiliares"
            url="/admin/users"
            icon={RiAdminFill}
            isExpanded={isExpandedLocal}
          />
          <ButtonNavigation
            key={7}
            title="Mentores"
            url="/admin/users-mentores"
            icon={FaUserGraduate}
            isExpanded={isExpandedLocal}
          />
          <ButtonNavigation
            key={8}
            title="Mentorados"
            url="/admin/users-mentorados"
            icon={FaGraduationCap}
            isExpanded={isExpandedLocal}
          />
          <Box w={"100%"} mt="auto" mb="32px">
            <ButtonNavigation
              key={9}
              title="Configurações Mentoria"
              url="/admin/config-mentoria"
              icon={RiPencilFill}
              isExpanded={isExpandedLocal}
            />
          </Box>
        </Flex>
        <Flex flexDir="column" w="100%" alignItems="center">
          <Divider my={5} opacity={0.2} />
          <Button
            display="flex"
            padding={4}
            alignItems="center"
            color="white"
            lineHeight="20px"
            // bg={corSidebar ? String(corSidebar) : "xmentor.primary"}
            background={"xmentor.primary"}
            _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
            onClick={logout}
            colorScheme="facebook"
          >
            {isExpandedLocal && <Text mr={4}>Sair</Text>}
            <Icon as={FiLogOut} fontSize="20" />
          </Button>
        </Flex>
      </motion.div>
    </>
  );
}
