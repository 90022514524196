import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Center,
  Heading,
  Stack,
  HStack,
  Text,
  Button,
  Image,
  useToast,
} from "@chakra-ui/react";
import { IoCalendarClearOutline } from "react-icons/io5";
import { TbClockHour5 } from "react-icons/tb";
import { IEvento } from "../../context/EventoProvider/types";
import { useEvento } from "../../context/EventoProvider/useEvento";
import { useAuth } from "../../context/AuthProvider/useAuth";

interface EventCardProps {
  evento: IEvento;
}

const EventCard: React.FC<EventCardProps> = ({ evento }) => {
  const { AdicionarParticipante } = useEvento();
  const { payLoad } = useAuth();
  const toast = useToast();

  const participante_id = payLoad?.user.id;
  const [inscrito, setInscrito] = useState(
    evento.participantes.some((p) => p.participante_id === participante_id)
  );

  // Função para calcular o horário de término
  const calculateEndTime = (startTime: string, duration: number): string => {
    const [hours, minutes] = startTime.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes + duration;
    const endHours = Math.floor(totalMinutes / 60);
    const endMinutes = totalMinutes % 60;
    const formattedEndHours = endHours.toString().padStart(2, "0");
    const formattedEndMinutes = endMinutes.toString().padStart(2, "0");

    return `${formattedEndHours}:${formattedEndMinutes}`;
  };

  // Função para formatar a data
  const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("pt-BR", { month: "long" });
    const year = date.getFullYear();

    return `${day} de ${month}, ${year}`;
  };

  // Função para inscrever o usuário no evento
  const handleSubscribe = async () => {
    try {
      const evento_id = evento.id;

      // Verifica se os valores estão presentes
      if (!participante_id || !evento_id) {
        toast({
          title: "Erro ao inscrever-se",
          description: "Participante ou evento não definido.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      // Adiciona o participante ao evento
      await AdicionarParticipante(evento_id, participante_id);

      // Atualiza o estado para indicar que o usuário está inscrito
      setInscrito(true);
    } catch (error: any) {
      toast({
        title: "Erro ao inscrever-se",
        description:
          error.response?.data?.msg ||
          "Ocorreu um erro ao inscrever-se no evento.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Card
      key={evento.id}
      borderRadius={"lg"}
      overflow={"hidden"}
      shadow={"none"}
      border={"1px"}
      borderColor={"xmentor.borderCards"}
    >
      <CardBody p={0}>
        {evento.img ? (
          <Image
            src={`${process.env.REACT_APP_API}/tmp/event/${evento.img}`}
            alt={`Banner do evento ${evento.nome}`}
            objectFit="cover"
            w="full"
            h="150px"
          />
        ) : (
          <Center
            w="full"
            h="150px"
            bg={"xmentor.primary"}
            p={4}
            overflow={"hidden"}
          >
            <Heading textAlign={"center"} color={"rgba(255,255,255,0.6)"}>
              <i>{evento.nome}</i>
            </Heading>
          </Center>
        )}
        <Stack p={4} spacing="3">
          <Heading fontSize={"xl"}>{evento.nome}</Heading>
          <HStack>
            <HStack
              textColor={"xmentor.primary"}
              alignItems={"start"}
              spacing={1}
            >
              <IoCalendarClearOutline />
              <Text fontSize={"xs"} textColor={"black"}>
                {formatDate(evento.data_evento.split("T")[0])}
              </Text>
            </HStack>
            <HStack
              textColor={"xmentor.primary"}
              alignItems={"start"}
              spacing={1}
            >
              <TbClockHour5 />
              <Text fontSize={"xs"} textColor={"black"}>
                {evento.data_evento.split("T")[1]?.substring(0, 5)} às{" "}
                {calculateEndTime(
                  evento.data_evento.split("T")[1]?.substring(0, 5),
                  evento.duracao
                )}
              </Text>
            </HStack>
          </HStack>
        </Stack>
      </CardBody>
      <CardFooter>
        <Button
          size="sm"
          variant="solid"
          bg={inscrito ? "gray.700" : "xmentor.primary"}
          _hover={{ bg: inscrito ? "gray.800" : "xmentor.primaryDark" }}
          color={"white"}
          onClick={handleSubscribe}
          isDisabled={inscrito}
        >
          {inscrito ? "Inscrito" : "Inscrever-se"}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default EventCard;
