import { Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { TemplateAdmin } from "../../../../components/layout/admin/TemplateAdmin";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { TemplateMentorado } from "../../../../components/layout/mentorado/TemplateMentorado";
import { useLocation, useNavigate } from "react-router-dom";
import { sendOAuthTokenAPI } from "../../../../helpers/googleOAuthHelper";
import { useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import Cookies from "js-cookie";

export function GoogleOAuth2Redirect() {
  const navigate = useNavigate();
  const user = useAuth();
  const { setIsGoogle } = useAuth();
  const toast = useToast();

  useEffect(() => {
    const handleSendToken = async () => {
      const id = user.payLoad?.user.id as string;
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("code") as string;
      try {
        const response = await sendOAuthTokenAPI({ id: id, token: token });
        console.log(response);
        if (response.msg !== "ok") {
          toast({
            position: "top",
            description: "Ocorreu um erro ao registrar o token",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
        Cookies.set("xmentor-user-isGoogle", "true", {
          expires: 30 / 1440,
        });
        setIsGoogle("true");
        setTimeout(() => navigate("/admin/agenda"), 2000);
      } catch {
        toast({
          position: "top",
          description: "Ocorreu um erro ao registrar o token",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };

    handleSendToken();
  }, []);

  const TextContainer = () => {
    return (
      <Flex
        w="100%"
        h="100%"
        justify={"center"}
        align={"center"}
        p={"24px"}
        borderRadius={"xl"}
      >
        <Flex
          w="100%"
          h="100%"
          justify={"center"}
          align={"center"}
          p={"24px"}
          bg={"#fff"}
          flexDir={"column"}
          gap={"24px"}
        >
          <FcGoogle size={"160px"} />
          <Text fontWeight={"600"}>Conta atrelada com sucesso a Google !</Text>
          <Spinner />
        </Flex>
      </Flex>
    );
  };
  return (
    <TemplateAdmin>
      <TextContainer />
    </TemplateAdmin>
  );
}
