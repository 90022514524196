type TimezoneData = {
    date: string;           // Data no formato "YYYY-MM-DD" (ex.: "2024-11-21")
    time: string;           // Hora no formato "HH:mm:ss" (ex.: "14:00:00")
    clientTimezone: string; // Nome do fuso do cliente (ex.: "Arizona")
    serverTimezone: string; // Nome do fuso do servidor (ex.: "Brasília")
};


// Mapa de fusos horários para offsets
const timezoneOffsets: { [key: string]: number } = {
    // Estados Unidos
    "Pacific/Midway": -11,
    "Pacific/Honolulu": -10,
    "America/Anchorage": -9,
    "America/Los_Angeles": -8,
    "America/Tijuana": -8,
    "America/Phoenix": -7,
    "America/Denver": -7,
    "America/Chicago": -6,
    "America/Mexico_City": -6,
    "America/Regina": -6,
    "America/New_York": -5,
    "America/Detroit": -5,
    "America/Indianapolis": -5,
    "America/Lima": -5,

    // Brasil
    "America/Rio_Branco": -5,
    "America/Eirunepe": -5,
    "America/Manaus": -4,
    "America/Boa_Vista": -4,
    "America/Porto_Velho": -4,
    "America/Campo_Grande": -4,
    "America/Cuiaba": -4,
    "America/Sao_Paulo": -3,
    "America/Fortaleza": -3,
    "America/Recife": -3,
    "America/Bahia": -3,
    "America/Belem": -3,
    "America/Maceio": -3,
    "America/Araguaina": -3,
    "America/Noronha": -2,

    // Outros principais fusos
    "Atlantic/South_Georgia": -2,
    "Atlantic/Azores": -1,
    "Atlantic/Cape_Verde": -1,
    "Europe/London": 0,
    "Europe/Lisbon": 0,
    "Africa/Casablanca": 0,
    "Europe/Paris": 1,
    "Europe/Berlin": 1,
    "Africa/Lagos": 1,
    "Europe/Istanbul": 2,
    "Africa/Cairo": 2,
    "Asia/Jerusalem": 2,
    "Asia/Riyadh": 3,
    "Europe/Moscow": 3,
    "Africa/Nairobi": 3,
    "Asia/Tehran": 3.5,
    "Asia/Dubai": 4,
    "Asia/Kabul": 4.5,
    "Asia/Karachi": 5,
    "Asia/Kolkata": 5.5,
    "Asia/Kathmandu": 5.75,
    "Asia/Dhaka": 6,
    "Asia/Bangkok": 7,
    "Asia/Shanghai": 8,
    "Asia/Tokyo": 9,
    "Australia/Sydney": 10,
    "Pacific/Auckland": 12,
};


export type ConvertedTime = {
    originalDate: {
        date: string;         // Data original
        time: string;         // Hora original
        timezone: string;     // Fuso horário original
    };
    convertedDate: {
        date: string;         // Data convertida
        time: string;         // Hora convertida
        timezone: string;     // Fuso horário convertido
    };
    utcDate: {
        date: string;         // Data em UTC
        time: string;         // Hora em UTC
    };
};
export const convertToClientTimezone = ({ date, time, clientTimezone, serverTimezone }: TimezoneData): ConvertedTime => {
    const [year, month, day] = date.split("-").map(Number);
    const [hours, minutes] = time.split(":").map(Number);

    const clientOffset = timezoneOffsets[clientTimezone] ?? 0; // Offset do cliente
    const serverOffset = timezoneOffsets[serverTimezone] ?? 0; // Offset do servidor

    // Calcular UTC a partir do horário do servidor
    const utcHours = hours - serverOffset;

    // Calcular horário no fuso do cliente
    const clientHours = utcHours + clientOffset;

    // Normalizar o horário para manter dentro de 0-23 horas
    const normalizeTime = (hours: number): { hours: number; dayOffset: number } => {
        const normalizedHours = ((hours % 24) + 24) % 24; // Ajusta para 0-23
        const dayOffset = Math.floor(hours / 24); // Ajusta o dia, se necessário
        return { hours: normalizedHours, dayOffset };
    };

    // Normalizar UTC e horário do cliente
    const normalizedUtc = normalizeTime(utcHours);
    const normalizedClient = normalizeTime(clientHours);

    // Ajustar data com base no deslocamento de dias
    const adjustDate = (day: number, dayOffset: number): string => {
        const adjustedDay = day + dayOffset;
        return `${year}-${String(month).padStart(2, "0")}-${String(adjustedDay).padStart(2, "0")}`;
    };

    return {
        originalDate: {
            date,
            time,
            timezone: serverTimezone,
        },
        convertedDate: {
            date: adjustDate(day, normalizedClient.dayOffset),
            time: `${String(normalizedClient.hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`,
            timezone: clientTimezone,
        },
        utcDate: {
            date: adjustDate(day, normalizedUtc.dayOffset),
            time: `${String(normalizedUtc.hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`,
        },
    };
};