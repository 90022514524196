import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IAtualizaEvento,
  IContext,
  IEvento,
  IEventoProvider,
  INewEvento,
} from "./types";
import { useToast } from "@chakra-ui/react";
import {
  atualizarEvento,
  consultaEvento,
  consultaEventosFuturos,
  consultaEventosPassados,
  criarEvento,
  deletarEvento,
  buscarEventoPorId,
  toggleParticipacao,
  adicionarParticipante, // Importa a função para adicionar participante
} from "./util";

export const EventoContext = createContext<IContext>({} as IContext);

export const EventoProvider = ({ children }: IEventoProvider) => {
  const navigate = useNavigate();
  const [eventos, setEventos] = useState<IEvento[]>([]);
  const [eventosFuturos, setEventosFuturos] = useState<IEvento[]>([]);
  const [eventosPassados, setEventosPassados] = useState<IEvento[]>([]);
  const [eventoDetalhes, setEventoDetalhes] = useState<IEvento | null>(null);
  const toast = useToast();

  const showErrorToast = (error: any) => {
    toast({
      position: "top",
      description: error.response?.data?.message || error || "Error",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const ConsultaEvento = async (mentoria_id: string) => {
    try {
      const response = await consultaEvento(mentoria_id);
      setEventos(response);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const ConsultaEventosFuturos = async (mentoria_id: string) => {
    try {
      const response = await consultaEventosFuturos(mentoria_id);
      setEventosFuturos(response);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const ConsultaEventosPassados = async (mentoria_id: string) => {
    try {
      const response = await consultaEventosPassados(mentoria_id);
      setEventosPassados(response);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const BuscarEventoPorId = async (id: string) => {
    try {
      const response = await buscarEventoPorId(id);
      setEventoDetalhes(response);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const CriarEvento = async (form: FormData | INewEvento) => {
    try {
      await criarEvento(form);
      setTimeout(() => navigate("/admin/eventos"), 2000);
    } catch (error) {
      showErrorToast(error);
    } finally {
      const mentoriaId =
        form instanceof FormData
          ? form.get("mentoria_id")?.toString()
          : form.mentoria_id;
      if (mentoriaId) {
        await ConsultaEvento(mentoriaId);
        setTimeout(() => ConsultaEvento(mentoriaId), 1500);
      }
    }
  };

  const AtualizarEvento = async (
    id: string,
    form: FormData | IAtualizaEvento
  ) => {
    try {
      await atualizarEvento(id, form);
      toast({
        title: "Evento atualizado com sucesso",
        description: "Os detalhes do evento foram atualizados.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      setTimeout(() => navigate("/admin/eventos"), 2000);
      if (eventoDetalhes?.mentoria_id) {
        await ConsultaEvento(eventoDetalhes.mentoria_id);
        BuscarEventoPorId(id);
      }
    } catch (error: any) {
      toast({
        title: "Erro ao atualizar evento",
        description:
          error.response?.data?.message || error || "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const DeletarEvento = async (id: string, mentoria_id: string) => {
    try {
      const response = await deletarEvento(id);
      setEventos(response);
    } catch (error) {
      showErrorToast(error);
    } finally {
      await ConsultaEvento(mentoria_id);
      setTimeout(() => ConsultaEvento(mentoria_id), 700);
    }
  };

  const ToggleParticipacao = async (
    evento_id: string,
    participante_id: string
  ) => {
    try {
      await toggleParticipacao(evento_id, participante_id);
      toast({
        title: "Participação atualizada com sucesso!",
        description: "Os detalhes do evento foram atualizados.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      if (eventoDetalhes) {
        const response = await BuscarEventoPorId(eventoDetalhes.id);
        return response;
      }
    } catch (error: any) {
      toast({
        title: "Erro ao atualizar participação",
        description: error.response?.data?.message || error || "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // Função para adicionar um participante a um evento
  const AdicionarParticipante = async (
    evento_id: string,
    participante_id: string
  ) => {
    try {
      await adicionarParticipante(evento_id, participante_id);
      toast({
        title: "Participante adicionado com sucesso!",
        description: "O participante foi adicionado ao evento.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      if (eventoDetalhes) {
        // Atualiza os detalhes do evento para refletir a adição
        await BuscarEventoPorId(eventoDetalhes.id);
      }
    } catch (error: any) {
      toast({
        title: "Erro ao adicionar participante",
        description: error.response?.data?.message || error || "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <EventoContext.Provider
      value={{
        ConsultaEvento,
        ConsultaEventosFuturos,
        ConsultaEventosPassados,
        BuscarEventoPorId,
        CriarEvento,
        AtualizarEvento,
        DeletarEvento,
        ToggleParticipacao,
        AdicionarParticipante, // Adiciona a função ao contexto
        eventos,
        eventosFuturos,
        eventosPassados,
        eventoDetalhes,
      }}
    >
      {children}
    </EventoContext.Provider>
  );
};
