import { Card, Flex, Text, HStack, useToast, Link, Button, VStack } from "@chakra-ui/react";
import logo from "../assets/logo.svg";
import { useEffect } from "react";
import { useAuth } from "../context/AuthProvider/useAuth";
import { authUser } from "../context/AuthProvider/util";
import { useNavigate } from "react-router-dom";

export default function SelecionaMentoria() {
  const { payLoadLogin, AuthUser } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (payLoadLogin?.login?.mentorias?.length === 1 && payLoadLogin.login.mentorias[0]) {
      authenticateUser(payLoadLogin.login.mentorias[0].id);
    }
    console.log(payLoadLogin)
  }, [payLoadLogin]);

  async function authenticateUser(mentoriaId: string) {
    try {
      if (payLoadLogin?.login?.id) {
        // aqui preciso retornar os dados da config da mentoria
        const data = await authUser(payLoadLogin.login.id, mentoriaId);
        AuthUser(data);
      } else {
        throw new Error("Usuário não autenticado");
      }
    } catch (error) {
      toast({
        title: "Erro ao fazer login",
        description: "Verifique suas credenciais e tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex w="100%" maxW={360} p="8" borderRadius={8} flexDir="column">
        <Flex justifyContent="center" mb={4}>
          <img height={300} width={220} src={logo} alt="XMENTOR" />
        </Flex>
        <Text textAlign="center" mt={8} mb={8}>
          {payLoadLogin?.login?.mentorias?.length ? "Escolha a mentoria" : "Você não possui mentoria."}
        </Text>
        {!payLoadLogin?.login?.mentorias?.length && (
          <Link textAlign="center" onClick={() => navigate('/')}>
            Voltar
          </Link>
        )}
        <VStack>
          {payLoadLogin?.login?.mentorias?.map((mentoria) => (
            mentoria && (
              <Button pt="3" key={mentoria.id} onClick={() => authenticateUser(mentoria.id)}>
                <Text>{mentoria.nome}</Text>
              </Button>
            )
          ))}
          {payLoadLogin?.login?.isMaster && (
            <Card onClick={() => authenticateUser("master")}>
              <img height={300} width={220} src={logo} alt="MASTER" />
              <Text>MASTER</Text>
            </Card>
          )}
        </VStack>
      </Flex>
    </Flex>
  );
}
