import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Button, Heading, HStack, Stack, useToast } from "@chakra-ui/react";
import { TemplateAdmin } from "../../../../components/layout/admin/TemplateAdmin";
import { MentoriaProvider } from "../../../../context/MentoriaProvider";
import AboutSection from "../section/AboutSection";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { useEffect, useState } from "react";
import { ModalSobre } from "../components/ModalSobre";
import MentorProfileSection from "./sections/MentorProfileSection";
import { ResultRouteProps } from "../view";
import InformationSection from "../section/InformationSession";
import AboutSectionMentor from "../section/AboutSectionMentor";
import { ModalUpdateDataUser } from "../components/MoldalUpdateDataUser";
import { ModalAddRedesSociais } from "../components/ModalAddRedesSociais";

export function MentorProfile() {
  const { FindUserById, userSelected, UpdateDescriptionProfile } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast()
  const [isModalAddRedesSociais, setIsModalAddRedesSociais] = useState(false);
  const [isEditingData, setIsEditingData] = useState(false);
  const [description, setDescription] = useState("");
  const [isModalSobreOpen, setIsModalSobreOpen] = useState(false);
  const resultRoute: ResultRouteProps = location.state || {
    email: "",
    nome: "",
    foto: "",
    id: "",
  };

  useEffect(() => {
    FindUserById(resultRoute.id);
  }, [resultRoute]);

  const handleOpenSobre = async () => {
    await FindUserById(resultRoute.id);
    setIsModalSobreOpen(!isModalSobreOpen);
  };

  const handleSaveNewText = async (text: string) => {
    try {
      await UpdateDescriptionProfile(userSelected?.id || "", text);
      setDescription(() => text)
    } catch (error) {
      showErrorToast(error)
    } finally {
      setTimeout(async ()=> await FindUserById(resultRoute.id), 750)
     
    }
  };
  const showErrorToast = (error: any) => {
    toast({
      position: "top",
      description: error.response?.data?.message || error || "Error",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  return (
    <TemplateAdmin>
      <MentoriaProvider>
        <Stack
          maxW="1440px"
          mx="auto">
          <HStack display="flex" w="100%" py={2} justifyContent="space-between">
            <Heading size="md">Perfil do mentor</Heading>
            <Button
              px={0}
              variant={"ghost"}
              leftIcon={<IoIosArrowRoundBack size={30} />}
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
          </HStack>
          <Stack spacing={6}>
            {/* Perfil */}
            <MentorProfileSection resultRoute={resultRoute} />
            {/* Sobre */}
            <AboutSectionMentor
              description={description}
              handleOpenSobre={handleOpenSobre}
            />
          
            {/* Modais */}
            {isModalSobreOpen &&
              <ModalSobre
                isOpen={isModalSobreOpen}
                onClose={() => setIsModalSobreOpen(false)}
                text={userSelected?.descricao || ""}
                onSave={handleSaveNewText}
              />
            }
            {/* Informações */}

          </Stack>
          {userSelected && (
            <>
              <ModalUpdateDataUser
                isOpen={isEditingData}
                onClose={() => setIsEditingData(false)}
                usuario_cliente_id={resultRoute.id}
              />
              <ModalAddRedesSociais
                isOpen={isModalAddRedesSociais}
                onClose={() => setIsModalAddRedesSociais(false)}
                usuario_cliente_id={resultRoute.id}
              />
            </>
          )}
        </Stack>        
      </MentoriaProvider>
    </TemplateAdmin>
  );
}
