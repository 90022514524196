import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  HStack,
  Avatar,
  Textarea,
  VStack,
  IconButton,
  Text,
  Divider,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Reuniao } from "../../pages/admin/agenda/sections/Agendamentos";
import TimezoneSelect from "react-timezone-select"; // Importa o componente de seleção de fusos horários
import ConfirmDeleteModal from "./ConfirmDeleteModal";

interface Convidado {
  id: string;
  email: string;
  perfil: "mentorado" | "mentor" | "convidado";
  foto?: string;
  responseStatus: "accepted" | "declined" | "tentative" | "needsAction";
  isOrganizer: boolean;
}

interface EditarReuniaoModalProps {
  isOpen: boolean;
  onClose: () => void;
  reuniao: Reuniao;
  onSave: (dadosReuniao: any) => void;
}

// Função para converter 'dd/mm/yyyy' para 'yyyy-mm-dd'
const formatDateToInput = (date: string) => {
  const [day, month, year] = date.split("/");
  return `${year}-${month}-${day}`;
};

// Função para converter 'yyyy-mm-dd' para 'dd/mm/yyyy'
const formatInputToDate = (date: string) => {
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

const EditarReuniaoModal: React.FC<EditarReuniaoModalProps> = ({
  isOpen,
  onClose,
  reuniao,
  onSave,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [titulo, setTitulo] = useState<string>("");
  const [data, setData] = useState<string>(""); // Armazena a data no formato 'yyyy-mm-dd'
  const [horaInicio, setHoraInicio] = useState<string>(""); // Formato 'HH:MM'
  const [horaFim, setHoraFim] = useState<string>(""); // Formato 'HH:MM'
  const [fusoHorarioInicial, setFusoHorarioInicial] = useState({ value: "", label: "" })
  const [fusoHorario, setFusoHorario] = useState({ value: "", label: "" }); // Inicializa com o fuso horário padrão
  const [convidados, setConvidados] = useState<Convidado[]>([]);
  const [ata, setAta] = useState<string>("");
  const [isAddConvidadoOpen, setIsAddConvidadoOpen] = useState(false); // Estado para controlar o modal de adicionar convidado
  const [novoConvidadoEmail, setNovoConvidadoEmail] = useState<string>(""); // Estado para o e-mail do novo convidado
  const timezones = {
    // Estados Unidos
    "Pacific/Midway": "Midway Island, Samoa",
    "Pacific/Honolulu": "Hawaii",
    "America/Anchorage": "Alaska",
    "America/Los_Angeles": "Pacific Time",
    "America/Tijuana": "Tijuana",
    "America/Phoenix": "Arizona",
    "America/Denver": "Mountain Time",
    "America/Dawson": "Dawson, Yukon",
    "America/Chicago": "Central Time",
    "America/Mexico_City": "Mexico City",
    "America/New_York": "Eastern Time",
    "America/Detroit": "Detroit",
    "America/Indianapolis": "Indiana (East)",
    "America/Lima": "Pittsburgh", // Sobrescrevendo com Pittsburgh
    "America/Rio_Branco": "Rio Branco",
    "America/Eirunepe": "Eirunepé",
    "America/Manaus": "Manaus",
    "America/Boa_Vista": "Boa Vista",
    "America/Porto_Velho": "Porto Velho",
    "America/Campo_Grande": "Campo Grande",
    "America/Cuiaba": "Cuiabá",
    "America/Sao_Paulo": "São Paulo",
    "America/Fortaleza": "Fortaleza",
    "America/Recife": "Recife",
    "America/Bahia": "Bahia",
    "America/Belem": "Belém",
    "America/Maceio": "Maceió",
    "America/Araguaina": "Araguaína",
    "America/Noronha": "Fernando de Noronha",

    // Brasil
    "Atlantic/South_Georgia": "South Georgia",
    "Atlantic/Azores": "Azores",
    "Atlantic/Cape_Verde": "Cape Verde",
    "Europe/London": "London",
    "Europe/Lisbon": "Lisbon",
    "Africa/Casablanca": "Casablanca",
    "Europe/Paris": "Paris",
    "Europe/Berlin": "Frankfurt", // Sobrescrevendo com Frankfurt
    "Africa/Lagos": "Lagos",
    "Europe/Istanbul": "Istanbul",
    "Africa/Cairo": "Cairo",
    "Asia/Jerusalem": "Jerusalem",
    "Asia/Riyadh": "Riyadh",
    "Europe/Moscow": "Moscow",
    "Africa/Nairobi": "Nairobi",
    "Asia/Tehran": "Tehran",
    "Asia/Dubai": "Dubai",
    "Asia/Kabul": "Kabul",
    "Asia/Karachi": "Karachi",
    "Asia/Kolkata": "India Standard Time",
    "Asia/Kathmandu": "Kathmandu",
    "Asia/Dhaka": "Dhaka",
    "Asia/Bangkok": "Bangkok",
    "Asia/Shanghai": "China Standard Time",
    "Asia/Tokyo": "Tokyo",
    "Australia/Sydney": "Sydney",
    "Pacific/Auckland": "Auckland",
  };
  // Estado para controle do modal de confirmação
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [participanteToRemove, setParticipanteToRemove] =
    useState<Convidado | null>(null);

  // Função para converter ISO para 'yyyy-mm-dd' e 'HH:MM'
  const extractHour = (isoString: string) => {
    const time = isoString.toString().split("T")[1].slice(0, 5); // 'HH:MM'
    return time;
  };

  useEffect(() => {
    if (reuniao) {
      setTitulo(
        reuniao?.tituloReuniao || `Reunião com ${reuniao?.participacoes[0]}`
      );
      setData(formatDateToInput(reuniao?.data || ""));
      setHoraInicio(extractHour(reuniao?.inicioEvento) || "");
      setHoraFim(extractHour(reuniao?.fimEvento) || "");
      setAta(reuniao?.descricaoReuniao || "");

      if (reuniao?.fusoHorario) {
        setFusoHorarioInicial({
          value: reuniao.fusoHorario, // Ex: "America/Sao_Paulo"
          label: reuniao.fusoHorario, // O TimezoneSelect vai gerar o label correto
        });
      }

      const novosConvidados: Convidado[] = reuniao.participacoes.map((p) => ({
        ...p.participante,
      }));

      setConvidados(novosConvidados);
    }
  }, [reuniao]);

  const openAddConvidadoModal = () => setIsAddConvidadoOpen(true);
  const closeAddConvidadoModal = () => setIsAddConvidadoOpen(false);

  const addNovoConvidado = () => {
    const novoConvidado: Convidado = {
      id: "",
      email: novoConvidadoEmail,
      perfil: "convidado",
      foto: "",
      responseStatus: "needsAction",
      isOrganizer: false,
    };

    setConvidados((prevConvidados) => [...prevConvidados, novoConvidado]);
    setNovoConvidadoEmail("");
    closeAddConvidadoModal();
  };

  const openConfirmDeleteModal = (convidado: Convidado) => {
    setParticipanteToRemove(convidado);
    setIsConfirmDeleteOpen(true);
  };

  const closeConfirmDeleteModal = () => {
    setIsConfirmDeleteOpen(false);
    setParticipanteToRemove(null);
  };

  const handleConfirmDelete = () => {
    if (participanteToRemove) {
      setConvidados(
        convidados.filter((p) => p.email !== participanteToRemove.email)
      );
    }
    closeConfirmDeleteModal();
  };

  const handleSave = async () => {
    setIsLoading(true);
    const start = `${data}T${horaInicio}:00`;
    const end = `${data}T${horaFim}:00`;

    const dadosReuniaoAtualizada = {
      titulo,
      data: formatInputToDate(data),
      horaInicio,
      horaFim,
      start,
      end,
      fusoHorario: fusoHorario.value || fusoHorario.label,
      convidados,
      descricao: ata,
    };

    await onSave(dadosReuniaoAtualizada);

    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader py={6}>
            <Text>Editar Reunião</Text>
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody pt={6}>
            <FormControl mb={4}>
              <FormLabel mb={1} fontSize={"md"} fontWeight={"semibold"}>
                Título
              </FormLabel>
              <Input
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                bg="#f2f4f7"
                border="1px solid #EAECF0"
                h={12}
              />
            </FormControl>

            <HStack spacing={4} mb={4}>
              <FormControl>
                <FormLabel mb={1} fontSize={"md"} fontWeight={"semibold"}>
                  Data
                </FormLabel>
                <Input
                  type="date"
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                  bg="#f2f4f7"
                  border="1px solid #EAECF0"
                  h={12}
                />
              </FormControl>
              <FormControl>
                <FormLabel mb={1} fontSize={"md"} fontWeight={"semibold"}>
                  Horário de Início
                </FormLabel>
                <HStack>
                  <Input
                    type="time"
                    value={horaInicio}
                    onChange={(e) => setHoraInicio(e.target.value)}
                    bg="#f2f4f7"
                    border="1px solid #EAECF0"
                    h={12}
                  />
                  <span>até</span>
                  <Input
                    type="time"
                    value={horaFim}
                    onChange={(e) => setHoraFim(e.target.value)}
                    bg="#f2f4f7"
                    border="1px solid #EAECF0"
                    h={12}
                  />
                </HStack>
              </FormControl>
            </HStack>

            <FormControl mb={4}>
              <FormLabel mb={1} fontSize={"md"} fontWeight={"semibold"}>
                Fuso Horário
              </FormLabel>
              <TimezoneSelect
                value={fusoHorarioInicial.label ? fusoHorarioInicial.value:fusoHorario}
                onChange={(e) => {
                  setFusoHorarioInicial({label:'',value:''})
                  setFusoHorario(e)
                }}
                labelStyle="original"
                timezones={{
                  ...timezones,
                }}
              />
            </FormControl>

            <FormControl mb={4}>
              <HStack justifyContent={"space-between"}>
                <FormLabel mb={1} fontSize={"md"} fontWeight={"semibold"}>
                  Convidados
                </FormLabel>
                <IconButton
                  colorScheme="primary"
                  bgColor={"xmentor.primary"}
                  color={"white"}
                  icon={<FaPlus />}
                  aria-label="Adicionar Convidado"
                  onClick={openAddConvidadoModal}
                />
              </HStack>
              <VStack spacing={2} p={4} gap={5}>
                {convidados
                  .sort((a, b) => (a.isOrganizer ? -1 : 1))
                  .map((convidado, index) => (
                    <HStack
                      key={index}
                      w="100%"
                      justifyContent={"space-between"}
                    >
                      <HStack>
                        <Avatar
                          name={convidado.email}
                          src={`${process.env.REACT_APP_API}/tmp/user/${convidado.foto}`}
                          size="sm"
                        />
                        <VStack align="start" gap={0}>
                          <Text>
                            {convidado.email}
                            <span style={{ color: "gray" }}>
                              {convidado.isOrganizer && " - Organizador(a)"}
                            </span>
                          </Text>
                          <HStack>
                            <Text fontSize={"sm"} color={"gray"}>
                              {convidado.perfil}
                            </Text>
                            {convidado.responseStatus === "accepted" ||
                            convidado.isOrganizer ? (
                              <Text
                                fontSize={"xs"}
                                bg={"#BCF0AA"}
                                color={"#174606"}
                                py={0.5}
                                px={2}
                                borderRadius={"full"}
                              >
                                Confirmado
                              </Text>
                            ) : convidado.responseStatus === "tentative" ||
                              convidado.responseStatus === "needsAction" ? (
                              <Text
                                fontSize={"xs"}
                                bg={"#FFE3B9"}
                                color={"#FD5E05"}
                                py={0.5}
                                px={2}
                                borderRadius={"full"}
                              >
                                Pendente
                              </Text>
                            ) : (
                              convidado.responseStatus === "declined" && (
                                <Text
                                  fontSize={"xs"}
                                  bg={"#f79797"}
                                  color={"#a01f1f"}
                                  py={0.5}
                                  px={2}
                                  borderRadius={"full"}
                                >
                                  Recusado
                                </Text>
                              )
                            )}
                          </HStack>
                        </VStack>
                      </HStack>
                      <IconButton
                        bg={"none"}
                        aria-label="Remover convidado"
                        icon={<MdClose color="gray" size={20} />}
                        onClick={() => openConfirmDeleteModal(convidado)}
                      />
                    </HStack>
                  ))}
              </VStack>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel mb={1} fontSize={"md"} fontWeight={"semibold"}>
                Ata da Reunião
              </FormLabel>
              <Textarea
                value={ata}
                onChange={(e) => setAta(e.target.value)}
                placeholder="Descrição da reunião"
                bg="#f2f4f7"
                border="1px solid #EAECF0"
              />
            </FormControl>
          </ModalBody>

          <Divider />
          <ModalFooter gap={4}>
            <Button
              fontSize={"sm"}
              variant="outline"
              size="lg"
              color={"gray.600"}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              fontSize={"sm"}
              size="lg"
              bg={"xmentor.primary"}
              _hover={{ background: "xmentor.primaryDark", color: "#E2E8F0" }}
              color={"white"}
              onClick={handleSave}
              isLoading={isLoading}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal para adicionar novo convidado */}
      <Modal isOpen={isAddConvidadoOpen} onClose={closeAddConvidadoModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar Novo Convidado</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Email do Convidado</FormLabel>
              <Input
                value={novoConvidadoEmail}
                onChange={(e) => setNovoConvidadoEmail(e.target.value)}
                placeholder="Digite o e-mail"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={closeAddConvidadoModal}>
              Cancelar
            </Button>
            <Button
              bg={"xmentor.primary"}
              _hover={{ background: "xmentor.primaryDark", color: "#E2E8F0" }}
              onClick={addNovoConvidado}
              color={"white"}
              isDisabled={!novoConvidadoEmail}
            >
              Adicionar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal de confirmação de deleção */}
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteOpen}
        onClose={closeConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        participanteEmail={participanteToRemove?.email || ""}
      />
    </>
  );
};

export default EditarReuniaoModal;
