import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  VStack,
  Text,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Input,
  IconButton,
  Icon,
  Avatar,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { CustomModalOverlay } from "../pages/admin/trilha/components/CustomModalOverlay";
import { formatarData, formatarDataHora } from "../utils/formatarData";
import { useAuth } from "../context/AuthProvider/useAuth";
import { downloadFile } from "../utils/downloadFile";
import { DownloadIcon } from "@chakra-ui/icons";
import { UploadAvatar } from "./avatarUpload";

interface Props {
  mentor: string;
  foto: string;
  mentorado?: string;
  horario: string;
  atividade: string;
  vencimento: string;
  typeFile?: boolean;
  resposta?: string;
  id: string;
  feedback: boolean;
}

export function CardAtividadesMentorado({
  mentor,
  foto,
  mentorado,
  atividade,
  horario,
  vencimento,
  typeFile,
  resposta,
  id,
  feedback,
}: Props) {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const {
    ResponderAtividadeComArquivo,
    ResponderAtividadeComTexto,
    FindActivity,
    payLoad,
  } = useAuth();
  const [file, setFile] = useState<File | null>(null);
  const toast = useToast();
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const handleRemove = () => {
    setFile(null);
    setPreviewUrl(null);
  };

  const handleSaveFeedback = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (feedback && payLoad && payLoad.user.id) {
      const formData = new FormData();
      formData.append(`id`, id);
      if (file) {
        formData.append("file", file);
        await ResponderAtividadeComArquivo(formData, payLoad.user.id);
        setFile(null);
      }
    } else if (payLoad && payLoad.user.id) {
      await ResponderAtividadeComTexto(id, feedbackText, payLoad.user.id);
      setFeedbackText("");
    }

    setIsOpen(false);
  };

  useCallback(() => {
    if (payLoad && payLoad.user.id) {
      const id = payLoad.user.id;
      new Promise(async (resolve) => {
        FindActivity(id, "0");
      });
      setTimeout(async () => {
        FindActivity(id, "0");
      }, 750);
    }
  }, [handleSaveFeedback, setIsOpen]);

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files ? event.target.files[0] : null);
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    const maxSize = 15 * 1024 * 1024; // Define o tamanho máximo permitido (1 MB)
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];

    if (file) {
      if (file.size > maxSize) {
        toast({
          position: "top",
          description: "Envie um arquivo menor que 15MB",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          setPreviewUrl(fileReader.result as string);
        };
      }
    }
  }, [file]);

  useEffect(() => {
    if (!isOpen) {
      handleRemove();
    }
  }, [isOpen]);
  return (
    <>
      <VStack
        spacing={0}
        align={"start"}
        w={"100%"}
        p={4}
        cursor={"pointer"}
        transition={"0.5s"}
        _hover={{
          backgroundColor: "gray.100",
        }}
        onClick={handleOpenModal}
      >
        <Text fontSize={14} fontWeight={"700"}>
          {mentorado ? mentorado : mentor}
        </Text>
        <Text fontSize={14} fontWeight={"500"}>
          {atividade}
        </Text>
        <Text fontSize={12} fontWeight={"400"} color={"#8A8490"}>
          Criada em {formatarDataHora(horario)}
        </Text>
      </VStack>

      <Modal isOpen={isOpen} isCentered onClose={handleCloseModal}>
        <CustomModalOverlay />
        <ModalContent as="form" onSubmit={handleSaveFeedback}>
          <ModalHeader>{atividade}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack mb={5}>
              <Avatar
                src={`${process.env.REACT_APP_API}/tmp/user/${foto}`}
                w="48px"
                h="48px"
                bgColor="white"
                borderRadius="50%"
                alignSelf="center"
                transition="0.5"
              />
              <VStack align="flex-start" spacing={0}>
                <Text fontWeight="bold">{mentorado ? mentorado : mentor}</Text>
                <Text color="gray.500">Expira {formatarData(vencimento)}</Text>
              </VStack>
            </HStack>

            {feedback && resposta ? (
              <>
                <Text mt={4} fontWeight="bold">
                  Resposta do mentorado:
                </Text>
                <Text>
                  <IconButton
                    aria-label="Search database"
                    p={1}
                    mr={1}
                    size="60px"
                    icon={<Icon as={DownloadIcon} fontSize={20} />}
                    onClick={() =>
                      downloadFile({
                        src: `${process.env.REACT_APP_API}/tmp/step_attachment/${resposta}`,
                        uri: String(resposta),
                      })
                    }
                  />
                  {resposta?.substring(String(resposta).indexOf("-") + 1)}
                </Text>
              </>
            ) : (
              feedback == false &&
              resposta && (
                <>
                  <Text mt={4} fontWeight="bold">
                    Resposta do mentorado:
                  </Text>
                  <Textarea mt="1" placeholder="" value={resposta} />
                </>
              )
            )}

            {!feedback
              ? payLoad?.user.perfil == "mentorado" && (
                  <>
                    <Text mb={2}>Resposta</Text>
                    <Textarea
                      required
                      placeholder="Digite sua resposta"
                      value={feedbackText}
                      onChange={(e) => setFeedbackText(e.target.value)}
                    />
                  </>
                )
              : payLoad?.user.perfil == "mentorado" && (
                  <>
                    <Text mb={2}>Resposta</Text>
                    <UploadAvatar
                      bg="white"
                      borderColor="black"
                      brandColor="black"
                      file={file}
                      previewUrl={previewUrl}
                      handleChange={handleUpload}
                      handleRemove={handleRemove}
                      typesFile="*"
                      isFile
                    />
                  </>
                )}
          </ModalBody>

          <ModalFooter pt={2}>
            <HStack display="flex" w="100%">
              <Button
                type="reset"
                variant="outline"
                w="50%"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
              {payLoad?.user.perfil == "mentorado" && (
                <Button colorScheme="blue" w="50%" type="submit">
                  Salvar
                </Button>
              )}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
