import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Circle, Flex, HStack, IconButton, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, VStack, useDisclosure, useTheme, useToast } from "@chakra-ui/react";

import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { InputSearch } from "../../../../components/Form/InputSearch";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { TemplateAdmin } from "../../../../components/layout/admin/TemplateAdmin";
import { FaPlus, FaRegTrashAlt, FaFilter } from "react-icons/fa";
import { CustomModalOverlay } from "../../trilha/components/CustomModalOverlay";
import { IoWarningOutline } from "react-icons/io5";
import { formatarDataHora } from "../../../../utils/formatarData";
import { IMentores } from "../view";
import { searchUser } from "../../../../context/AuthProvider/util";
import { IUser } from "../../../../context/AuthProvider/types";

export function UsersMentorado() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const { SearchUser, users, payLoad, FindUserById } = useAuth()
    const [page, setPage] = useState(0)
    const [search, setSearch] = useState("")
    const [name, setName] = useState("")
    const [idUser, setIdUser] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const theme = useTheme();
    const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;
    const toast = useToast()
    const [mentorSelected, setMentorSelected] = useState("")
    const [mentores, setMentores] = useState<IMentores[]>([]);
    useEffect(() => {
        if (search.length >= 3) {
            handlePage(search, page)
        } else if (search.length == 0) {
            setPage(0)
            handlePage("", 0)
        }
    }, [, search, mentorSelected])
    useEffect(() => {
        loadMentor();
    }, []);
    function handlePage(search: string, page: number) {
        if (page >= 0)
            if (payLoad?.user.mentoria_id) {
                setIsLoading(true)
                Promise.resolve(SearchUser({
                    nome: search,
                    perfil: "mentorado",
                    pagina: page,
                    mentoria_id: payLoad?.user.mentoria_id,
                    mentor: mentorSelected
                }))
                setIsLoading(false)
            }
    }
    const deleteUser = (name: string, idUser: string) => {
        setName(name)
        setIdUser(idUser)
        onOpen()
    }
    const loadMentor = async () => {
        if (payLoad?.user?.mentoria_id)
            try {
                const response = await searchUser({
                    mentoria_id: payLoad.user.mentoria_id,
                    nome: "",
                    pagina: 0,
                    perfil: "mentor",
                    mentor: ''
                });
                const prev = response.data.map((i: IUser) => {
                    return {
                        id: i.id,
                        name: i.nome,
                        foto: i.foto,
                    };
                });
                setMentores(prev);
            } catch (error: any) {
                toast({
                    position: "top",
                    description: "Error",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
    };
    return (
        <TemplateAdmin>
            <VStack spacing={4} align={"flex-start"} mb={4}>
                <Text
                    fontSize="xl"
                    fontWeight={700}
                    fontStyle="normal"
                    lineHeight="normal"
                    letterSpacing={"-0.227px"}
                >Mentorados</Text>
                <Button
                    type="submit"
                    colorScheme="blue"
                    size="md"
                    bg="xmentor.primary"
                    leftIcon={<GoPlus />}
                    onClick={() => navigate("/admin/users/register-new-user", { state: { perfil: "mentorado" } })}
                    _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
                >
                    Novo mentorado
                </Button>
                <HStack w="100%">
                    <InputSearch
                        placeholder="Pesquisa mentorado"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    <Menu>
                        <MenuButton
                            as={IconButton}
                            colorScheme="primary"
                            bgColor={"xmentor.primary"}
                            color={"white"}
                            icon={<FaFilter />}
                            aria-label="Edit"
                            ml={{ base: 0, md: "auto" }}
                            mt={{ base: 4, md: 0 }}
                        />
                        <MenuList>
                            <MenuItem>
                                <Box textAlign={"left"} ml={2}>
                                    <Text fontWeight="bold">Filtro por mentor</Text>
                                </Box>
                            </MenuItem>
                            {mentores &&
                                mentores
                                    .map((i: IMentores) => (
                                        <MenuItem key={i.id} onClick={() => setMentorSelected(i.id)}>
                                            <Flex align="center" borderRadius="lg">
                                                <Image
                                                    rounded={10}
                                                    w="48px"
                                                    h="48px"
                                                    src={`${process.env.REACT_APP_API}/tmp/user/${i.foto}`}
                                                    alt=""
                                                />
                                                <Box textAlign={"left"} ml={2}>
                                                    <Text fontWeight="bold">{i.name}</Text>
                                                    {/* <Text fontSize="sm">Mentor Educação</Text> */}
                                                </Box>
                                            </Flex>
                                        </MenuItem>
                                    ))}
                            <MenuItem onClick={() => setMentorSelected("")}>
                                <Box textAlign={"left"} ml={2}>
                                    <Text fontWeight="bold">Sem filtro</Text>
                                </Box>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>

            </VStack>

            <Box
                bg="#FFF"
                borderRadius="8px"
                border="1px solid #EAECF0"
            >
                <Table variant="simple" w="full" >
                    <Thead >
                        <Tr justifyContent="space-between">
                            <Th>Nome</Th>
                            <Th display={['none', 'flex', 'flex']}>Email</Th>
                            <Th>Último acesso</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users &&
                            users.data.length > 0 &&
                            users.data.map((i) => {
                                return (
                                    <Tr
                                        cursor={'pointer'}
                                        transition={"0.5s"}
                                        _hover={{
                                            backgroundColor: "gray.100",
                                        }}
                                        key={i.id}

                                        justifyContent="space-between"
                                        onClick={async () => {
                                            await FindUserById(String(i.id))
                                            navigate("/admin/users/view", {
                                                state: {
                                                    id: i.id,
                                                    nome: i.nome,
                                                    email: i.email,
                                                    foto: i.foto,
                                                    perfil: "Mentorado"
                                                }
                                            })
                                        }}>
                                        <Td>
                                            <HStack align="center" justify={'start'}>
                                                {i.foto && (
                                                    <Avatar mr={1} h={'60px'} w={'60px'} borderRadius={'14px'} src={`${process.env.REACT_APP_API}/tmp/user/${i.foto}`} />
                                                )}
                                                <Text>{i.nome}</Text>
                                            </HStack>

                                        </Td>
                                        <Td  >{i.email}</Td>
                                        <Td  >{i.ultimo_acesso ? formatarDataHora(String(i.ultimo_acesso)) : "***"}</Td>
                                        <Td color="blue.400" textAlign="right" fontWeight="bold">
                                            <IconButton
                                                aria-label={""}
                                                size="sx"
                                                icon={<FaRegTrashAlt />}
                                                onClick={(event) => {
                                                    event.stopPropagation(); // Impede a propagação do evento de clique
                                                    deleteUser(String(i.nome), String(i.id));
                                                }}
                                            />
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </Tbody>
                    {users && (
                        <Tfoot >
                            <Tr justifyContent="space-between">
                                <Th>
                                    <Button
                                        variant={"outline"}
                                        onClick={() => {
                                            setPage(page - 1);
                                            handlePage(search, page - 1);
                                        }}
                                        isDisabled={page === 0}
                                        isLoading={isLoading}
                                    >
                                        Anterior
                                    </Button>
                                </Th>
                                <Th textAlign="center">Página {page + 1} de {Math.ceil(users.total / 20)}</Th>
                                <Th textAlign="right">
                                    <Button
                                        variant={"outline"}
                                        onClick={() => {
                                            setPage(page + 1);
                                            handlePage(search, page + 1);
                                        }}
                                        isDisabled={Math.ceil(users.total / 20) === page + 1}
                                        isLoading={isLoading}
                                    >
                                        Próximo
                                    </Button>
                                </Th>
                            </Tr>
                        </Tfoot>
                    )}
                </Table>
            </Box>
            <ModalDelete
                isOpen={isOpen}
                onClose={() => {
                    handlePage("", page)
                    onClose()
                    setTimeout(() => handlePage("", page), 750)
                }}
                idUser={idUser}
                name={name}
            />
        </TemplateAdmin>
    )
}


interface IModalDelete {
    isOpen: boolean
    onClose: () => void
    idUser: string
    name: string
}

function ModalDelete({ isOpen, onClose, idUser, name }: IModalDelete) {
    const { DeleteMentorado } = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    async function handleDelete() {
        setIsLoading(true)
        const result = await DeleteMentorado(idUser)
        if (result) {
            onClose()
        }
        setIsLoading(false)
    }
    return (
        <Modal isOpen={isOpen} isCentered onClose={onClose}>
            <CustomModalOverlay />
            <ModalContent>
                <ModalHeader display="flex" justifyContent="flex-start" alignContent="flex-start" alignItems="flex-start">
                    <Circle bg="yellow.100" p={2}>
                        <IoWarningOutline color="red" size={28} />
                    </Circle>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Deseja excluir o "{name}"?
                </ModalBody>
                <ModalFooter gap={2}>
                    <Button variant="outline" size="lg" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button colorScheme="blue" size="lg" onClick={handleDelete} isLoading={isLoading}>
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}