import { useState } from "react";
import { useEvento } from "../../../../context/EventoProvider/useEvento";
import {
  Button,
  Circle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { CustomModalOverlay } from "../../trilha/components/CustomModalOverlay";
import { IoWarningOutline } from "react-icons/io5";

interface IModalDelete {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  mentoria_id: string;
}

export function ModalDeleteEvento({
  isOpen,
  onClose,
  id,
  mentoria_id,
}: IModalDelete) {
  const { DeletarEvento } = useEvento();

  const [isLoading, setIsLoading] = useState(false);
  async function handleDelete() {
    setIsLoading(true);
    await DeletarEvento(id, mentoria_id);
    onClose();
    setIsLoading(false);
  }
  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <CustomModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Circle bg="yellow.100" p={2}>
            <IoWarningOutline color="red" size={28} />
          </Circle>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>Deseja excluir o evento?</ModalBody>
        <ModalFooter gap={2}>
          <Button variant="outline" size="lg" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={handleDelete}
            isLoading={isLoading}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
