import { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Heading,
  VStack,
  SimpleGrid,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { EventoProvider } from "../../../context/EventoProvider";
import { useEvento } from "../../../context/EventoProvider/useEvento";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { ModalAddEvent } from "./register/modalAddEvent";
import { FaPlus } from "react-icons/fa";
import { FaCalendarDays } from "react-icons/fa6";
import { Search } from "../../../components/Search";
import { ModalDeleteEvento } from "./sections/ModalConfirmDelete";
import EventCardMentor from "./sections/EventCardMentor";
import Calendar from "react-calendar";
import "../../../components/Calendar.css";
import { IEvento } from "../../../context/EventoProvider/types";

function EventoChildren() {
  const {
    ConsultaEventosFuturos,
    ConsultaEventosPassados,
    eventosFuturos,
    eventosPassados,
  } = useEvento();
  const { payLoad } = useAuth();
  const [idEvento, setIdEvento] = useState("");
  const [isModalDeleteEvento, setIsModalDeleteEvento] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState(0); // Estado para rastrear a aba ativa
  const [loading, setLoading] = useState(false); // Estado para rastrear o carregamento
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [tempSelectedDate, setTempSelectedDate] = useState<Date | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleReloadEvents = () => {
    const mentoriaId = payLoad?.user.mentoria_id || " ";
    setLoading(true);
    if (activeTab === 0) {
      ConsultaEventosFuturos(mentoriaId).finally(() => setLoading(false));
    } else {
      ConsultaEventosPassados(mentoriaId).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    handleReloadEvents(); // Recarregar eventos ao carregar o componente
  }, [activeTab, payLoad]);

  const filtrarEventos = (eventos: IEvento[]) => {
    return eventos.filter((evento) => {
      const matchSearch = evento.nome
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchDate = selectedDate
        ? evento.data_evento.startsWith(
            selectedDate.toISOString().split("T")[0]
          )
        : true;
      return matchSearch && matchDate;
    });
  };

  const eventosFuturosFiltrados = filtrarEventos(eventosFuturos);
  const eventosPassadosFiltrados = filtrarEventos(eventosPassados);

  const hasEventOnDate = (date: Date) => {
    const dateString = date.toISOString().split("T")[0];
    return eventosFuturos
      .concat(eventosPassados)
      .some((evento) => evento.data_evento.startsWith(dateString));
  };

  return (
    <TemplateAdmin>
      <VStack align="start">
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Heading fontSize={24} my="24px" fontWeight={700}>
            Eventos
          </Heading>
          <HStack spacing={4}>
            <Search
              inputProps={{ h: "40px" }}
              search={searchTerm}
              setSearch={setSearchTerm}
            />
            <Popover
              isOpen={showCalendar}
              onClose={() => setShowCalendar(false)}
              placement="bottom"
              closeOnBlur={false}
            >
              <PopoverTrigger>
                <Button
                  gap={2}
                  textColor={"gray.500"}
                  bg="white"
                  variant={"outline"}
                  fontSize={"sm"}
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <FaCalendarDays /> <span>Data</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Calendar
                    tileClassName={({ date }) =>
                      hasEventOnDate(date) ? "highlight" : ""
                    }
                    onChange={(date) => {
                      if (!Array.isArray(date)) {
                        setTempSelectedDate(date);
                      }
                    }}
                    value={tempSelectedDate || selectedDate}
                    className="custom-calendar"
                  />
                </PopoverBody>
                <PopoverFooter display="flex" justifyContent="space-between">
                  <Button
                    variant="outline"
                    onClick={() => {
                      setTempSelectedDate(null);
                      setSelectedDate(null);
                      setShowCalendar(false);
                    }}
                  >
                    Limpar
                  </Button>
                  <Button
                    bg="xmentor.primary"
                    color={"white"}
                    _hover={{ bg: "xmentor.primaryDark" }}
                    onClick={() => {
                      setSelectedDate(tempSelectedDate);
                      setShowCalendar(false);
                    }}
                  >
                    Aplicar
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
            <Button
              gap={2}
              colorScheme="primary"
              bgColor={"xmentor.primary"}
              color={"white"}
              fontSize={"sm"}
              onClick={onOpen}
            >
              <FaPlus /> <span>Novo evento</span>
            </Button>
          </HStack>
        </HStack>
        <Tabs w="full" onChange={(index) => setActiveTab(index)}>
          <TabList>
            <Tab fontSize={"sm"} fontWeight={700}>
              Próximos eventos
            </Tab>
            <Tab fontSize={"sm"} fontWeight={700}>
              Histórico
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {loading ? (
                <Center w="100%" h="200px">
                  <Spinner color="xmentor.primary" size="lg" />
                </Center>
              ) : eventosFuturosFiltrados.length > 0 ? (
                <SimpleGrid columns={[1, 2, 3]} spacing={10} w="full">
                  {eventosFuturosFiltrados.map((evento) => (
                    <EventCardMentor key={evento.id} evento={evento} />
                  ))}
                </SimpleGrid>
              ) : (
                <VStack
                  align="center"
                  justifyContent="center"
                  textAlign={"center"}
                  color={"gray.500"}
                  w={"full"}
                  mt={4}
                >
                  <FaCalendarDays />
                  <Text fontSize={"sm"}>Ainda não há eventos</Text>
                </VStack>
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <Center w="100%" h="200px">
                  <Spinner color="xmentor.primary" size="lg" />
                </Center>
              ) : eventosPassadosFiltrados.length > 0 ? (
                <SimpleGrid columns={[1, 2, 3]} spacing={10} w="full">
                  {eventosPassadosFiltrados.map((evento) => (
                    <EventCardMentor key={evento.id} evento={evento} />
                  ))}
                </SimpleGrid>
              ) : (
                <VStack
                  align="center"
                  justifyContent="center"
                  textAlign={"center"}
                  color={"gray.500"}
                  w={"full"}
                  mt={4}
                >
                  <FaCalendarDays />
                  <Text fontSize={"sm"}>Ainda não há eventos passados</Text>
                </VStack>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
      <ModalDeleteEvento
        id={idEvento}
        isOpen={isModalDeleteEvento}
        onClose={() => setIsModalDeleteEvento(false)}
        mentoria_id={payLoad?.user.mentoria_id || " "}
      />
      <ModalAddEvent
        isOpen={isOpen}
        onClose={() => {
          onClose();
          handleReloadEvents();
        }}
      />
    </TemplateAdmin>
  );
}

export function Evento() {
  return (
    <EventoProvider>
      <EventoChildren />
    </EventoProvider>
  );
}
