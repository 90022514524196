import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Input,
  Text,
  Textarea,
  useToast,
  useTheme,
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { useEvento } from "../../../../context/EventoProvider/useEvento";
import { UploadEventCapa } from "../../../../components/UploadEventCapa";
import { IEvento } from "../../../../context/EventoProvider/types";

interface ModalEditEventType {
  isOpen: boolean;
  onClose: () => void;
  eventoDetalhes: IEvento;
}

export function ModalEditEvent({
  isOpen,
  onClose,
  eventoDetalhes,
}: ModalEditEventType) {
  const { AtualizarEvento } = useEvento();
  const { payLoad } = useAuth();
  const toast = useToast();
  const theme = useTheme();
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputData, setInputData] = useState({
    nome: "",
    data_evento: "",
    descricao_instrucoes: "",
    duracao: 0,
    mentoria_id: payLoad?.user.mentoria_id || "",
    usuario_criador_id: payLoad?.user.id || "",
  });

  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const isInitialMount = useRef(true);

  // Atualizar os campos com os dados do evento apenas uma vez
  useEffect(() => {
    if (isOpen && eventoDetalhes && isInitialMount.current) {
      setInputData({
        nome: eventoDetalhes.nome || "",
        data_evento: eventoDetalhes.data_evento || "",
        descricao_instrucoes: eventoDetalhes.descricao_instrucoes || "",
        duracao: eventoDetalhes.duracao || 0,
        mentoria_id:
          eventoDetalhes.mentoria_id || payLoad?.user.mentoria_id || "",
        usuario_criador_id:
          eventoDetalhes.usuario_criador_id || payLoad?.user.id || "",
      });
      setImagePreview(
        eventoDetalhes.img
          ? `${process.env.REACT_APP_API}/tmp/event/${eventoDetalhes.img}`
          : null
      );
      isInitialMount.current = false;
    }
  }, [isOpen, eventoDetalhes, payLoad]);

  // Resetar a flag para true quando o modal for fechado
  useEffect(() => {
    if (!isOpen) {
      isInitialMount.current = true;
      setCroppedImage(null);
      setImagePreview(null);
    }
  }, [isOpen]);

  const handleInputChange = (field: string, value: any) => {
    setInputData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const isFormValid = () => {
    return (
      inputData.nome &&
      inputData.data_evento &&
      inputData.data_evento.includes("T") &&
      inputData.descricao_instrucoes &&
      inputData.duracao > 0 &&
      (croppedImage || imagePreview) // Verifica se há uma imagem cortada ou pré-visualização
    );
  };

  const handleSubmit = async () => {
    if (!croppedImage && !imagePreview) {
      toast({
        title: "Imagem inválida",
        description: "Por favor, selecione uma imagem.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("id", eventoDetalhes.id);
      formData.append("nome", inputData.nome);
      formData.append("data_evento", inputData.data_evento);
      formData.append("descricao_instrucoes", inputData.descricao_instrucoes);
      formData.append("duracao", inputData.duracao.toString());
      formData.append("mentoria_id", inputData.mentoria_id);
      formData.append("usuario_criador_id", inputData.usuario_criador_id);

      if (croppedImage) {
        formData.append("img", croppedImage, "evento-capa.jpeg");
      }

      await AtualizarEvento(eventoDetalhes.id, formData);
      onClose();
    } catch (err) {
      console.error("Erro ao atualizar evento:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
      <ModalOverlay />
      <ModalContent rounded={"xl"}>
        <ModalHeader>Editar evento</ModalHeader>
        <ModalCloseButton mt={2} />
        <Divider />
        <ModalBody>
          <Stack spacing="4" w={"full"} my="4">
            <Stack spacing={2} w="full">
              <Text fontSize="sm" fontWeight="bold" color="black">
                Capa do evento
              </Text>
              <UploadEventCapa
                setImage={setCroppedImage}
                imagePreview={imagePreview}
                setImagePreview={setImagePreview}
                onRemoveImage={() => {
                  setCroppedImage(null);
                  setImagePreview(null);
                }}
              />
            </Stack>

            <Stack spacing={2} w="full">
              <Text fontSize="sm" fontWeight="bold" color="black">
                Título
              </Text>
              <Input
                name="name"
                type="text"
                fontSize={"sm"}
                bg={"xmentor.background_template"}
                rounded={"md"}
                placeholder="Insira o nome do evento"
                value={inputData.nome}
                onChange={(e) => handleInputChange("nome", e.target.value)}
              />
            </Stack>

            <Stack spacing={2} w="full">
              <Text fontSize="sm" fontWeight="bold" color="black">
                Descrição do evento
              </Text>
              <Textarea
                name="description"
                fontSize={"sm"}
                bg={"xmentor.background_template"}
                rounded={"md"}
                placeholder="Insira a descrição do evento"
                maxLength={1000}
                value={inputData.descricao_instrucoes}
                onChange={(e) =>
                  handleInputChange("descricao_instrucoes", e.target.value)
                }
              />
            </Stack>

            <Stack direction="row" spacing={4} w="full">
              <Stack spacing={2} w="full">
                <Text fontSize="sm" fontWeight="bold" color="black">
                  Data
                </Text>
                <Input
                  name="date"
                  type="date"
                  fontSize={"sm"}
                  bg={"xmentor.background_template"}
                  rounded={"md"}
                  value={inputData.data_evento.split("T")[0]}
                  onChange={(e) =>
                    handleInputChange("data_evento", e.target.value + "T00:00")
                  }
                />
              </Stack>

              <Stack spacing={2} w="full">
                <Text fontSize="sm" fontWeight="bold" color="black">
                  Horário
                </Text>
                <Input
                  name="time"
                  type="time"
                  fontSize={"sm"}
                  bg={"xmentor.background_template"}
                  rounded={"md"}
                  value={inputData.data_evento.split("T")[1] || "00:00"}
                  onChange={(e) =>
                    handleInputChange(
                      "data_evento",
                      inputData.data_evento.split("T")[0] + "T" + e.target.value
                    )
                  }
                />
              </Stack>

              <Stack spacing={2} w="full">
                <Text fontSize="sm" fontWeight="bold" color="black">
                  Duração (min)
                </Text>
                <Input
                  name="duration"
                  type="number"
                  fontSize={"sm"}
                  bg={"xmentor.background_template"}
                  rounded={"md"}
                  placeholder="Duração"
                  value={inputData.duracao}
                  onChange={(e) =>
                    handleInputChange("duracao", Number(e.target.value))
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
        <Divider />
        <ModalFooter gap={2}>
          <Button
            fontSize={"sm"}
            px={7}
            variant="outline"
            size="md"
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            fontSize={"sm"}
            px={8}
            size="md"
            bg={"xmentor.primary"}
            textColor={"white"}
            _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
            type="submit"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={!isFormValid()}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
