import React, { useState } from "react";
import {
  VStack,
  Text,
  Heading,
  InputGroup,
  InputLeftElement,
  Input,
  HStack,
  Box,
  Avatar,
  Icon,
  Center,
  Spinner,
} from "@chakra-ui/react";
import {
  IDateAgendamento,
  IMentorAgendamento,
} from "../../../../helpers/agendaHelper";
import { SearchIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface MentorListProps {
  mentorData: IMentorAgendamento[];
  handleMentorClick: (id: string) => void;
  isLoading: boolean;
}

export const MentorList: React.FC<MentorListProps> = ({
  mentorData,
  handleMentorClick,
  isLoading,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Função para contar o número total de agendamentos
  const countTotalAgendamentos = (agendamentos: IDateAgendamento): number => {
    return Object.values(agendamentos).reduce(
      (total, schedules) => total + schedules.length,
      0
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredMentors = mentorData.filter((mentor) =>
    mentor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <VStack alignItems="start" w="100%" spacing={4} p={4}>
      {/* Cabeçalho */}
      <Heading fontSize="sm">Gerenciar agenda dos mentores</Heading>

      {/* Campo de Pesquisa */}
      <InputGroup maxW="100%">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.500" />
        </InputLeftElement>
        <Input
          placeholder="Pesquisar mentor"
          bg="#FFF"
          border="1px solid #EAECF0"
          h={10}
          pl={10}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </InputGroup>

      {/* Lista de Mentores */}
      {!isLoading ? (
        filteredMentors.length > 0 ? (
          <VStack
            w="100%"
            maxH={"calc(100vh - 345px)"}
            alignItems="stretch"
            spacing={3}
            overflowY={"auto"}
          >
            {filteredMentors.map((mentor, index) => {
              const availableAgendamentos = countTotalAgendamentos(
                mentor.agendamentos
              );
              return (
                <HStack
                  key={index}
                  onClick={() => handleMentorClick(mentor.id)}
                  w="100%"
                  p={3}
                  bg="white"
                  border="1px solid #EAECF0"
                  borderRadius="md"
                  alignItems="center"
                  cursor="pointer"
                  _hover={{ bg: "#f9f9f9" }}
                >
                  {/* Avatar do Mentor */}
                  <Avatar
                    size="md"
                    borderRadius={"md"}
                    src={
                      mentor.img &&
                      `${process.env.REACT_APP_API}/tmp/user/${mentor.img}`
                    }
                  />

                  {/* Detalhes do Mentor */}
                  <Box flex="1" ml={1}>
                    <Text fontWeight="bold" fontSize={"sm"}>
                      {mentor.name}
                    </Text>
                    <Text fontWeight={"medium"} fontSize="xs" color="gray.500">
                      <span
                        style={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {availableAgendamentos}
                      </span>{" "}
                      horários disponíveis
                    </Text>
                  </Box>

                  {/* Ícone de Ação */}
                  <Icon as={ChevronRightIcon} boxSize={6} color="gray.500" />
                </HStack>
              );
            })}
          </VStack>
        ) : (
          <Text>Não foi localizado nenhum mentor</Text>
        )
      ) : (
        <Center w={"100%"} h={"calc(100vh - 345px)"}>
          <Spinner color="xmentor.primary" size="xl" />
        </Center>
      )}
    </VStack>
  );
};
