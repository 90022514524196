import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  HStack,
  useToast,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Divider,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  useBreakpointValue,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Grid,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { searchUser } from "../../../../context/AuthProvider/util";
import { IUser } from "../../../../context/AuthProvider/types";
import { MdModeEditOutline } from "react-icons/md";

export interface IMentor {
  id: string;
  name: string;
  foto: string;
}

export interface IModalAddMentor {
  mentoria_id: string;
  onMentoresChange: (mentoresIds: string[]) => void;
}

export const ModalAddMentorRegisterUser: React.FC<IModalAddMentor> = (
  props: IModalAddMentor
) => {
  const toast = useToast();
  const [mentores, setMentores] = useState<IMentor[]>([]);
  const [selectedMentores, setSelectedMentores] = useState<IMentor[]>([]);

  const theme = useTheme();
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;

  const templateColumns = useBreakpointValue({ base: "1fr", md: "1fr 1fr" });
  const templateColumnsModalAdd = useBreakpointValue({
    base: "1fr",
    sm: "1fr 1fr",
  });
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    loadMentor();
  }, []);

  const handleRemove = async (id: string) => {
    if (id.trim()) {
      setSelectedMentores((prev) => prev.filter((mentor) => mentor.id !== id));
    }
  };

  const handleAdd = async (id: string) => {
    if (id.trim()) {
      const addedMentor = mentores.find((mentor) => mentor.id === id);
      if (addedMentor) {
        setSelectedMentores((prev) => [...prev, addedMentor]);
      }
    }
  };

  const loadMentor = async () => {
    if (props.mentoria_id.length > 0)
      try {
        const response = await searchUser({
          mentoria_id: props.mentoria_id,
          nome: "",
          pagina: 0,
          perfil: "mentor",
          mentor: ""
        });
        const prev = response.data.map((i: IUser) => {
          return {
            id: i.id,
            name: i.nome,
            foto: i.foto,
          };
        });
        setMentores(prev);
      } catch (error: any) {
        toast({
          position: "top",
          description: "Error",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
  };

  useEffect(() => {
    if (!isOpen) {
      props.onMentoresChange(selectedMentores.map((mentor) => mentor.id));
    }
  }, [onToggle]);

  return (
    <>
      <VStack display="flex" w="100%" alignItems="flex-start">
        <HStack justifyContent={"center"} spacing={3} alignItems={"center"}>
          <Text fontWeight={"bold"}>Mentores</Text>
          {selectedMentores.length > 0 && (<IconButton
            colorScheme="primary"
            bgColor={"xmentor.primary"}
            color={"white"}
            size={"sm"}
            fontSize="16px"
            onClick={onToggle}
            icon={<MdModeEditOutline />}
            aria-label="Add"
          />)}
        </HStack>

        <Grid templateColumns={templateColumns} gap={4} w="100%">
          {selectedMentores.map((i) => (
            <Flex
              key={i.id}
              align="center"
              p={3}
              bgColor={"white"}
              borderWidth="1px"
              borderRadius="lg"
            >
              <Image
                rounded={10}
                w="48px"
                h="48px"
                src={`${process.env.REACT_APP_API}/tmp/user/${i.foto}`}
                alt=""
              />
              <Box textAlign={"left"} ml={2}>
                <Text fontWeight="bold">{i.name}</Text>
              </Box>
            </Flex>
          ))}
        </Grid>
        {selectedMentores.length <= 0 && (
          <Button
            fontSize={"xs"}
            px={6}
            size="md"
            bg={"xmentor.primary"}
            textColor={"white"}
            _hover={{ bg: "xmentor.primary" }}
            onClick={onToggle}
          >
            Adicionar mentores
          </Button>
        )}
      </VStack>

      <Modal isOpen={isOpen} onClose={onToggle} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mentores do mentorado</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody minH={300}>
            <HStack w="100%" justifyContent="space-between" mb="5">
              <Text fontWeight="bold" mb={3}>
                Mentores
              </Text>
              <Menu>
                <MenuButton
                  as={IconButton}
                  colorScheme="primary"
                  bgColor={"xmentor.primary"}
                  color={"white"}
                  icon={<FaPlus />}
                  aria-label="Edit"
                  ml={{ base: 0, md: "auto" }}
                  mt={{ base: 4, md: 0 }}
                />
                <MenuList>
                  {mentores &&
                    mentores
                      .filter(
                        (itemPrincipal) =>
                          !selectedMentores.some(
                            (itemExcluir) => itemExcluir.id === itemPrincipal.id
                          )
                      )
                      .map((i: IMentor) => (
                        <MenuItem
                          key={i.id}
                          display={"flex"}
                          onClick={() => handleAdd(i.id)}
                        >
                          <Flex align="center" borderRadius="lg">
                            <Image
                              rounded={10}
                              w="48px"
                              h="48px"
                              src={`${process.env.REACT_APP_API}/tmp/user/${i.foto}`}
                              alt=""
                            />
                            <Box textAlign={"left"} ml={2}>
                              <Text fontWeight="bold">{i.name}</Text>
                            </Box>
                          </Flex>
                        </MenuItem>
                      ))}
                </MenuList>
              </Menu>
            </HStack>
            <Grid templateColumns={templateColumnsModalAdd} gap={4} w="100%">
              {selectedMentores.map((i) => (
                <Flex
                  key={i.id}
                  align="center"
                  p={3}
                  bgColor={"white"}
                  borderWidth="1px"
                  borderRadius="lg"
                >
                  <Image
                    rounded={10}
                    w="48px"
                    h="48px"
                    src={`${process.env.REACT_APP_API}/tmp/user/${i.foto}`}
                    alt=""
                  />
                  <Box textAlign={"left"} ml={2}>
                    <Text fontWeight="bold">{i.name}</Text>
                  </Box>
                  <IconButton
                    icon={<FaRegTrashAlt />}
                    color="xmentor.text_gray"
                    aria-label="Delete"
                    ml="auto"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove(i.id);
                    }}
                  />
                </Flex>
              ))}
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter gap={2}>
            <Button variant="outline" size="lg" onClick={onToggle}>
              Cancelar
            </Button>
            <Button
              size="lg"
              bg={"xmentor.primary"}
              textColor={"white"}
              onClick={onToggle}
              _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
