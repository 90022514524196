import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  MenuGroup,
  MenuDivider,
  Input,
  Textarea,
  IconButton,
  Badge,
  Select,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormLabel,
  Flex,
  Divider,
  Avatar,
} from "@chakra-ui/react";
import { FaEllipsisVertical, FaPlus } from "react-icons/fa6";
import { useMentoria } from "../../../../context/MentoriaProvider/useMentoria";
import { ICardStep, IMentor } from "..";
import { CloseIcon, InfoIcon } from "@chakra-ui/icons";
import { searchUser } from "../../../../context/AuthProvider/util";
import { IUser } from "../../../../context/AuthProvider/types";
import { formatHours, minutesToIndex, timeMapping } from "../../../../utils/calculateHours";
import { FileUpload } from "../../../../components/uploadFile";
import { IMentores } from "../../users/view";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { UpdateStepModal } from "./UpdateStepModal";
import { IoMdTime } from "react-icons/io";

export function CardStep({
  sendIndex,
  isOpenModal,
  title,
  qtl,
  mentoria_id,
  etapa_id,
  children,
  edit,
  description,
  mentores,
  index,
  anexos
}: ICardStep) {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const handleMenuToggle = () => {
    setIsMenuExpanded(!isMenuExpanded);
  };
  const { AlterarEtapaMentoria, DeletarEtapaMentoria } = useMentoria();
  const [isEdit, setIsEdit] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const toast = useToast();
  const [mentoresDisponiveis, setMentoresDisponiveis] = useState<IMentor[]>([]);

  const [mentoresList, setMentoresList] = useState<IMentor[]>([]);
  const [mentorSelected, setMentorSelected] = useState("");
  const [newDescription, setNewDescription] = useState(description);


  const handleInfo = () => {
    sendIndex();
    setTimeout(() => isOpenModal(), 500);
  };
  useEffect(() => {
    loadMentor();
  }, [isEdit]);

  const handleRemove = async (id: string) => {
    if (id.trim()) {
      const value: any = mentores.filter(
        (itemPrincipal) => id !== itemPrincipal.id
      );
      setMentoresList(value);
      setMentorSelected("");
    }
  };

  const loadMentor = async () => {
    if (mentoria_id.length > 0)
      try {
        const response = await searchUser({
          mentoria_id: mentoria_id,
          nome: "",
          pagina: 0,
          perfil: "mentor",
          mentor: ""
        });
        const prev = response.data.map((i: IUser) => {
          return {
            id: i.id,
            name: i.nome,
          };
        });
        setMentoresDisponiveis(prev);
      } catch (error: any) {
        toast({
          position: "top",
          description: "Error",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
  };
  const handleDeleteClick = () => {
    setIsUpdating(true);
    DeletarEtapaMentoria(mentoria_id, etapa_id);
    setIsUpdating(false);
  };

  return (
    <VStack align="center">
      <VStack
        borderRadius={"14px"}
        w={{ base: "100%", md: "auto" }}
        bgColor={"xmentor.background_content"}
        p={"16px"}
        borderColor={"xmentor.borderCards"}
        maxH="74vh"

        mb={5}
        overflow="hidden"
      >
        <HStack w={"100%"}>
          <HStack w="100%" justifyContent="start">

            <HStack w={"100%"} justifyContent={"space-between"}>
              <HStack>
                <Text fontSize={"16px"}>{title}</Text>
                {qtl !== 0 && (
                  <Box
                    w={"20px"}
                    h={"20px"}
                    bg={"#EAECF0"}
                    borderRadius="20px"
                    border={"1px"}
                    borderColor={"xmentor.borderCards"}
                    p={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize={"12px"}>{qtl}</Text>
                  </Box>
                )}

                {title !== "Início" && (
                  <IconButton
                    onClick={handleInfo}
                    isRound={true}
                    size="xs"
                    aria-label="Descrição"
                    icon={<InfoIcon />}
                  />
                )}
              </HStack>

              {index > 0 && (
                <Menu size="sm">
                  <MenuButton
                    variant="outline"
                    size={"sm"}
                    as={IconButton}
                    aria-label="Options"
                    border="0px"
                    icon={<FaEllipsisVertical />}
                    onClick={handleMenuToggle}
                  ></MenuButton>
                  <MenuList zIndex={30}>
                    <MenuGroup title="Opções">
                      <MenuDivider />
                      <MenuItem
                        px={4}
                        fontSize={"16px"}
                        onClick={() => {
                          setIsEdit(true);
                        }}
                      >
                        Editar
                      </MenuItem>
                      {edit && (
                        <MenuItem
                          px={4}
                          fontSize={"16px"}
                          onClick={handleDeleteClick}
                          disabled={isUpdating}
                        >
                          Excluir
                        </MenuItem>
                      )}
                    </MenuGroup>
                  </MenuList>
                </Menu>
              )}
            </HStack>
          </HStack>
        </HStack>
        <HStack w="100%" justify={"flex-start"}>
          {!isEdit &&
            mentores.length > 0 &&
            mentores.map((i, index) =>
              <HStack>
                {i.mentor.foto.trim().length > 0 &&
                  <Avatar
                    src={`${process.env.REACT_APP_API}/tmp/user/${i.mentor.foto}`}
                    w="30px"
                    h="30px"
                    bgColor="white"
                    borderRadius="50%"
                    alignSelf="center"
                    transition="0.5"
                  />
                }
                <VStack display="flex" align="flex-start" spacing={0}>
                  <Text fontSize={"sm"}>{i.mentor.nome}</Text>
                  <HStack
                    spacing={1}
                    color={"xmentor.primary"}
                    _hover={{
                      // cursor: "pointer",
                      color: "blue.500",
                    }}
                  >
                    <IoMdTime />
                    <Text fontSize={"sm"}>
                      {formatHours(i.carga_horaria)}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
            )}
        </HStack>
        <VStack overflowY="auto"
          maxHeight="100%"
          align="flex-start"
          minW={300}
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0)",
              transition: "background-color 0.3s",
              borderRadius: "4px",
              marginRight: "-8px", // Ajuste a margem para mover o scroll para a direita
            },
            "&:hover::-webkit-scrollbar-thumb": {
              backgroundColor: "#c4c4c4",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
              marginRight: "8px", // Ajuste a margem para mover o scroll para a direita
            },
          }}>
          {children}
        </VStack>
      </VStack>
      <UpdateStepModal
        isOpenModal={isEdit}
        onClose={() => setIsEdit(!isEdit)}
        title={newTitle}
        description={newDescription}
        mentoresAtuais={mentores.map(i=>({
          mentorId: i.mentor.id,
          carga_horaria: i.carga_horaria
        }))}
        mentoria_id={mentoria_id}
        etapa_id={etapa_id}
        anexos={anexos}
      />
    </VStack>
  );
}


