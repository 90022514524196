import { ICreateNewTime } from "../pages/mentor/agenda/index";
import apiXmentor from "../server/apiServer";

export interface IDateAgendamento {
  [date: string]: {
    time: string;
    id: string;
    link: string;
  }[];
}

export interface IMentorAgendamento {
  id: string;
  name: string;
  img: string;
  agendamentos: IDateAgendamento;
}

interface IGetMentorsAgendaAPI {
  msg: string;
  agendamentos: IMentorAgendamento[];
}

interface IDeleteAgendaAPI {
  msg: "ok";
  ack: string[];
}

interface IError {
  msg: "error";
  error: string;
}

export interface IAgendamento {
  id: string;
  data: string;
  link: string;
  mentor: string;
  mentor_name: string;
  time: string;
}

export interface IAgendamentoFree {
  [key: string]: [
    {
      id: string;
      time: string;
      timezone: string
    }
  ];
}
export interface IAgendamentoFreeAPI {
  mentor_id: string;
  nome: string;
  foto: string;
  agendamentos: IAgendamentoFree;
}

export interface IAgendamentoClosed {
  meet_link: string | undefined;
  id: string;
  timezone: string;
  data: string;
  time: string;
  link: string;
  participantes?: string;
  mentor: string;
  mentor_name: string;
  mentorado?: string;
  mentorado_name?: string;
}
/*
{
    "msg": "ok",
    "events": {
        "marked": [],
        "free": [
            {
                "mentor_id": "0f360ec9-bc5d-4d79-9a00-6927be45a8a3",
                "nome": "mentor",
                "foto": "ec6ba64ecf62a3112806b83a0ed5896d-341563080_241851548336117_5698556719198456231_n.webp",
                "agendamentos": {
                    "12/04/2024": [
                        {
                            "id": "0ba3130a-dc20-4dde-902a-9350f130e8a3",
                            "time": "0ba3130a-dc20-4dde-902a-9350f130e8a3"
                        },
                        {
                            "id": "8c14b351-1be2-4021-9aca-88f12ff258ff",
                            "time": "8c14b351-1be2-4021-9aca-88f12ff258ff"
                        }
                    ]
                }
            }
        ]
    }
}
*/
export interface IMarkedAgendamento {
  id: string;
  participante_id: string;
  agendamento_id: string;
  agendamento: IAgendamento;
}

interface IGetUserAgendaAPI {
  msg: string;
  events: {
    free: IAgendamentoFreeAPI[];
    marked: IAgendamentoClosed[];
  };
}

export async function getMentorsAgendaAPI(): Promise<
  IGetMentorsAgendaAPI | IError
> {
  const response = await apiXmentor.get(`/agendas/mentors`);
  return response.data;
}

export async function deleteAgendaAPI(
  id: string[]
): Promise<IDeleteAgendaAPI | IError> {
  return (
    await apiXmentor.delete(`/agenda`, {
      data: {
        agenda_ids: id,
      },
    })
  ).data;
}

export async function createAgendaAPI(payload: {
  user_id: string;
  agendamento: ICreateNewTime[];
}): Promise<
  { msg: "ok"; agendamento: ICreateNewTime[] } | { msg: "error"; error: string }
> {
  return (
    await apiXmentor.post(`/agenda`, {
      ...payload,
    })
  ).data;
}

export async function getUserAgendaAPI(
  id: string,
  is_mentor: boolean
): Promise<IGetUserAgendaAPI | IError> {
  return (await apiXmentor.get(`/agenda?id=${id}&is_mentor=${is_mentor}`)).data;
}

export async function closeAgendaAPI(
  id: string,
  agendamento_id: string,
  timezone: string
): Promise<{ msg: "ok" } | IError> {
  return (
    await apiXmentor.patch(`/agenda`, {
      user_id: id,
      agendamento_id: agendamento_id,
      timezone
    })
  ).data;
}
export async function getAgendamentos(id: string, user_id: string) {
  try {
    const request = await apiXmentor.get(
      `/agendas/mentoria/${id}/user/${user_id}`
    );
    return request.data;
  } catch (error) {
    console.error("Erro ao buscar agendamentos:", error);
    return Promise.reject(error);
  }
}

export async function updateAgendaAPI(
  agendamento_id: string,
  payload: {
    titulo: string;
    descricao: string;
    start: string; // Data e hora de início no formato ISO 8601
    end: string; // Data e hora de término no formato ISO 8601
    convidados: { email: string }[]; // Lista de e-mails dos participantes
    time: string; // Hora do agendamento
    data: string; // Data do agendamento no formato "dd/mm/yyyy"
  }
): Promise<{ msg: "ok" } | IError> {
  try {
    const response = await apiXmentor.put(`/agenda/${agendamento_id}`, {
      ...payload,
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar o agendamento:", error);
    return Promise.reject({
      msg: "error",
      error: "Erro ao atualizar o agendamento",
    });
  }
}

export async function getMentorByIdAPI(
  mentorId: string
): Promise<IMentorAgendamento | IError> {
  try {
    const response = await apiXmentor.get(`/mentors/${mentorId}`);

    // Supondo que a resposta da API pode vir com 'nome' e 'foto'
    const data = response.data.mentor;

    // Verificar e garantir que a estrutura corresponda a IMentorAgendamento
    if (data.name === undefined && data.nome) {
      data.name = data.nome; // Normaliza 'nome' para 'name'
    }
    if (data.img === undefined && data.foto) {
      data.img = data.foto; // Normaliza 'foto' para 'img'
    }

    return data as IMentorAgendamento;
  } catch (error) {
    console.error("Erro ao buscar dados do mentor:", error);
    return { msg: "error", error: "Erro ao buscar dados do mentor" };
  }
}

export async function cancelAgendaAPI(
  agendamento_id: string
): Promise<{ msg: string } | IError> {
  try {
    const response = await apiXmentor.delete(
      `/agenda/cancelar/${agendamento_id}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao cancelar o agendamento:", error);
    return { msg: "error", error: "Erro ao cancelar o agendamento" };
  }
}
