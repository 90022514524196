import React, { ReactNode, useEffect, useState } from "react";
import {
  Button,
  HStack,
  Tooltip,
  Heading,
  Stack,
  VStack,
  useDisclosure,
  IconButton,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { ItemMentorado } from "./components/ItemMentorado";
import { CreateStepModal } from "./components/CreateStepModal";
import { CardStep } from "./components/CardStep";
import { useMentoriaGestao } from "../../../context/MentoriaGestaoProvider/useMentoriaGestao";
import { MentoriaGestaoProvider } from "../../../context/MentoriaGestaoProvider";
import "./style.css";
import { ModalTutorial } from "../../../components/ModalTutorial";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { CheckListGestaoItem } from "../../../context/MentoriaGestaoProvider/types";

function GestaoAdmChildren() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const { payLoad } = useAuth();

  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { ConsultaEtapaMentoria, etapasMentoria, AvancarEtapaMentoria, ReordenarEtapasMentoria } =
    useMentoriaGestao();

  useEffect(() => {
    if (payLoad && payLoad.user && payLoad.user.mentoria_id)
      ConsultaEtapaMentoria(payLoad.user.mentoria_id);
  }, []);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    if (result.type === "COLUMN") {
      // Verifica se a primeira etapa está sendo movida
      if (source.index === 0 || destination.index === 0) {
        toast({
          title: "A etapa inicial não pode ser movida!",
          status: "warning",
          position: "top",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const newEtapas = Array.from(etapasMentoria);
      const [removed] = newEtapas.splice(source.index, 1);
      newEtapas.splice(destination.index, 0, removed);

      // Mapear etapas para incluir apenas id e posicao
      const mappedEtapas: { id: string, posicao: number }[] = newEtapas.map((etapa, index) => ({
        id: etapa.id,
        posicao: index
      }));

      ReordenarEtapasMentoria({
        mentoria_id: String(payLoad?.user.mentoria_id),
        etapas: mappedEtapas
      }, newEtapas);
    } else {
      AvancarEtapaMentoria(
        source.droppableId,
        String(payLoad?.user.mentoria_id),
        destination.droppableId,
        result.draggableId
      );
    }
  };

  return (
    <>
      <TemplateAdmin isWidthFull>
        <VStack
          align="start"
          mt={1}
          minH={"87vh"}
          maxH={"87vh"}
          overflowX="auto"
          overflowY="hidden"
        >
          <HStack spacing={5}>
            <Heading fontSize={18} my="24px" fontWeight={700}>
              Gestão administrativa
            </Heading>
            <Tooltip
              onClick={onOpen}
              label="Gestão Administrativa, onde os administradores podem criar e gerenciar etapas, mover mentorados e realizar outras ações exclusivas."
              fontSize="sm"
              borderRadius={"5px"}
            >
              <QuestionOutlineIcon fontSize={"20px"} />
            </Tooltip>
          </HStack>
          <Stack
            gap={"15px"}
            cursor={"default"}
            display="flex"
            flexDirection={["column", "row"]}
            minW={"100%"}
            whiteSpace="nowrap"
            zIndex={0}
            overflowX="hidden"
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="all-columns" direction="horizontal" type="COLUMN">
                {(provided) => (
                  <Stack
                    gap={"15px"}
                    cursor={"default"}
                    display="flex"
                    flexDirection={["column", "row"]}
                    minW={"100%"}
                    whiteSpace="nowrap"
                    zIndex={0}
                    overflowX="hidden"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {etapasMentoria &&
                      etapasMentoria.map((i, index) => (
                        <Draggable draggableId={i.id} index={index} key={i.id}>
                          {(provided) => (
                            <Stack
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              borderRadius={"14px"}
                            >
                              <Droppable key={`${i.id}`} droppableId={`${i.id}`}>
                                {(provided, snapshot) => (
                                  <Stack
                                    ref={provided.innerRef}
                                    borderRadius={"14px"}
                                    {...provided.droppableProps}
                                  >
                                    <CardStep
                                      key={index}
                                      index={index}
                                      edit={!(i.mentorados.length > 0)}
                                      title={i.titulo}
                                      qtl={i.mentorados.length}
                                      colorHeader="gray.200"
                                      etapa_id={i.id}
                                      mentoria_id={i.mentoria_id}
                                      isDraggingOver={snapshot.isDraggingOver}
                                      CheckListGestao={i.CheckListGestao}
                                    >
                                      {i.mentorados &&
                                        i.mentorados.map((j, index) => (
                                          <Draggable
                                            key={j.id}
                                            draggableId={String(j.id)}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <Flex
                                                w="full"
                                                bgColor={'white'}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                borderRadius={'14px'}
                                                boxShadow={snapshot.isDragging ? "0px 4px 12px rgba(0, 0, 0, 0.1)" : "none"}
                                              >
                                                <ItemMentorado
                                                  key={index}
                                                  foto={j.foto || ""}
                                                  nome={j.nome || ""}
                                                  mentoria_id={i.mentoria_id}
                                                  usuario_id={j.id || ""}
                                                  etapa={i.titulo}
                                                  etapa_id={i.id}
                                                />
                                              </Flex>
                                            )}
                                          </Draggable>
                                        ))}
                                    </CardStep>
                                  </Stack>
                                )}
                              </Droppable>
                            </Stack>
                          )}
                        </Draggable>
                      ))}
                    <Button
                      borderRadius={"14px"}
                      w={{ base: "100%", md: "100%" }}
                      bgColor={"xmentor.background_content"}
                      p={"16px"}
                      color={"xmentor.primary"}
                      h={"55px"}
                      fontSize={"16px"}
                      borderColor={"xmentor.borderCards"}
                      onClick={openModal}
                    >
                      Nova etapa
                    </Button>
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>


          </Stack>
        </VStack>
      </TemplateAdmin>
      <CreateStepModal isOpen={isModalOpen} onClose={closeModal} />

      <ModalTutorial
        isOpen={isOpen}
        onClose={onClose}
        idYouTube="hd2B7XQAFls"
      />
    </>
  );
}

export interface ICardStep {
  colorHeader: string;
  title: string;
  qtl: number;
  mentoria_id: string;
  etapa_id: string;
  children?: ReactNode;
  edit?: boolean;
  index: number;
  isDraggingOver: boolean;
  CheckListGestao: CheckListGestaoItem[];
}

export interface IItemMentorado {
  foto: string;
  nome: string;
  mentoria_id: string;
  usuario_id: string;
  etapa: string;
  etapa_id: string;
}

export interface ICreateStepModal {
  isOpen: boolean;
  onClose: () => void;
}

export function GestaoAdm() {
  return (
    <MentoriaGestaoProvider>
      <GestaoAdmChildren />
    </MentoriaGestaoProvider>
  );
}
