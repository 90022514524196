import React from "react";
import { VStack, Text } from "@chakra-ui/react";
import Calendar from "react-calendar";
import { IMentorAgendamento } from "../../../../helpers/agendaHelper";

interface CalendarComponentProps {
  agendamentos: IMentorAgendamento["agendamentos"];
  handleCalendarDateClick: (date: Date) => void;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({
  agendamentos,
  handleCalendarDateClick,
}) => {
  const convertTime = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  return (
    <VStack>
      <Text fontSize={"24px"} fontWeight={500}>
        Visualização
      </Text>
      <Calendar
        tileClassName={({ date }) => {
          const dateString = date.toISOString().split("T")[0];
          return agendamentos[convertTime(dateString)] ? "highlight" : "";
        }}
        onChange={(date) => handleCalendarDateClick(date as Date)}
        value={new Date()}
      />
    </VStack>
  );
};

export default CalendarComponent;
