import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Avatar,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { TemplateMentorado } from "../../../components/layout/mentorado/TemplateMentorado";
import { FaCheckCircle, FaEye, FaRegTrashAlt } from "react-icons/fa";
import { MentoriaProvider } from "../../../context/MentoriaProvider";
import { useMentoria } from "../../../context/MentoriaProvider/useMentoria";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { IEtapaMentoria } from "../../../context/MentoriaProvider/types";
import { DownloadIcon } from "@chakra-ui/icons";
import { downloadFile } from "../../../utils/downloadFile";
import { IoMdTime } from "react-icons/io";
import { ModalEditTime } from "../../admin/users/components/ModalTimeEdit";
import { formatHours } from "../../../utils/calculateHours";
import ModalPDFViewer from "../../../components/ModalPDFViewer";
import { ModalDeleteHistoricoTrilha } from "../../admin/users/components/ModalDeleteHistoricoTrilha";

const MentoradoTrilhaChildren: React.FC = () => {
  const { payLoad } = useAuth();
  const { ListarTrilhaUsuario, trilha } = useMentoria();

  useEffect(() => {
    if (payLoad && payLoad.user && payLoad.user.id) {

      ListarTrilhaUsuario(payLoad.user.id);
    }
  }, [payLoad]);
  return (
    <TemplateMentorado>
      <VStack align="start">
        <Heading fontSize={24} my="24px" fontWeight={700}>
          Trilha da mentoria
        </Heading>
        <VStack
          align="start"
          bgColor="white"
          p="24px"
          borderRadius={14}
          w="100%"
          minH="60vh"
        >
          <Timeline
            events={trilha?.etapas || []}
            currentState={trilha?.posicao || 0}
          />
        </VStack>
      </VStack>
    </TemplateMentorado>
  );
};

export default function MentoradoTrilha() {
  return (
    <MentoriaProvider>
      <MentoradoTrilhaChildren />
    </MentoriaProvider>
  );
}

interface TimelineProps {
  events: IEtapaMentoria[];
  currentState: number;
}

interface IExtraHours {
  carga_horaria: number;
  id: string;
  data_cadastro: string;
  autor: {
    id: string;
    nome: string;
    foto: string;
  };
}

export const Timeline: React.FC<TimelineProps> = ({ events, currentState }) => {
  const { payLoad, userSelected, FindUserById } = useAuth();
  const { ListarTrilhaUsuario } = useMentoria();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMentor, setSelectedMentor] = useState<any>(null);
  const [mentorHours, setMentorHours] = useState<number>(0);
  const [historicoId, setHistoricoId] = useState("");
  const [extraHours, setExtraHours] = useState<IExtraHours[]>([]);
  const [idDelete, setIdDelete] = useState('')
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("")
  const handleClosePDFViewer = () => {
    setIsPDFViewerOpen(false);
  };
  const handleViewPDF = (url: string) => {
    setPdfUrl(url)
    setIsPDFViewerOpen(true);
  };
  const openEditTimeModal = (mentor: any, hours: number) => {
    setSelectedMentor(mentor);
    setMentorHours(hours);
    setIsOpen(true);
  };

  const closeEditTimeModal = () => {
    setIsOpen(false);
    setSelectedMentor(null);
    setMentorHours(0);
    setExtraHours([]);
  };
  return (
    <>
      <Accordion allowMultiple w="100%" borderRadius={24} transition="0.5">
        {events.length > 0 &&
          events.map((event, index) => (
            <Flex
              key={index}
              alignItems="stretch"
              align="center"
              w="100%"
              mb="5px"
            >
              <HStack spacing={5} alignItems="flex-start" w="100%">
                <VStack
                  h="100%"
                  spacing={"5px"}
                  transition="0.5"
                  justify="center"
                  mt={5}
                >
                  { index < currentState ? (
                    <FaCheckCircle
                      color="#00B383"
                      style={{
                        minWidth: "24px",
                        minHeight: "24px",
                        alignSelf: "center",
                      }}
                    />
                  ) : index === currentState ? (
                    <Avatar
                      src={`${process.env.REACT_APP_API}/tmp/user/${payLoad?.user?.foto}`}
                      w="24px"
                      h="24px"
                      bgColor="white"
                      borderRadius="50%"
                      alignSelf="center"
                      transition="0.5"
                    />
                  ) : (
                    <Box
                      minW="24px"
                      minH="24px"
                      bgColor="white"
                      borderRadius="50%"
                      alignSelf="center"
                      transition="0.5"
                      border={"2px"}
                      borderColor={"xmentor.borderCards"}
                    />
                  )}
                  {index < events.length - 1 ? (
                    <Box
                      w="2px"
                      borderRadius="2px"
                      h="100%"
                      bgColor={
                        index < currentState ? "#00B383" : "xmentor.borderCards"
                      }
                      transition="0.5"
                    />
                  ) : (
                    <Box
                      w="0px"
                      borderRadius="2px"
                      h="100%"
                      bgColor="green"
                      transition="0.5"
                    />
                  )}
                </VStack>
                <AccordionItem
                  w="100%"
                  mb="16px"
                  border="1px"
                  borderColor="xmentor.borderCards"
                  borderRadius="10px"
                  p="0px"
                >
                  <h2>
                    <AccordionButton w="100%" p="24px">
                      <Box as="span" flex="1" textAlign="left" p={0}>
                        <VStack align="start" spacing={"2px"} p={0}>
                          <HStack align={"center"}>
                            <Text fontSize={"16px"} fontWeight={700}>
                              {event.titulo}
                            </Text>
                            {event.mentores.map((i) => (
                              <HStack key={i.mentor.id} alignItems={"center"}>
                                <HStack alignItems={"center"}>
                                  <Box
                                    h="8px"
                                    w="8px"
                                    rounded={"full"}
                                    bg={"xmentor.background_template"}
                                  />
                                  <Avatar
                                    src={`${process.env.REACT_APP_API}/tmp/user/${i.mentor.foto}`}
                                    w="30px"
                                    h="30px"
                                    bgColor="white"
                                    borderRadius="50%"
                                    alignSelf="center"
                                    transition="0.5"
                                  />
                                  <Text fontSize={"sm"}>{i.mentor.nome}</Text>
                                  <Text fontSize={"sm"}> | </Text>
                                  <HStack
                                    spacing={1}
                                    color={"xmentor.primary"}
                                    _hover={{
                                      cursor: "pointer",
                                      color: "blue.500",
                                    }}
                                    onClick={() => {
                                      if (i.horasExtras) {
                                        setExtraHours(i.horasExtras);
                                      }
                                      setHistoricoId(event.historico_id || "");
                                      openEditTimeModal(
                                        i.mentor,
                                        i.carga_horaria
                                      );
                                    }}
                                  >
                                    <IoMdTime />
                                    <Text fontSize={"sm"}>
                                      {formatHours(i.carga_horaria)}
                                      {i.horasExtras && (
                                        <HorasExtras data={i.horasExtras} />
                                      )}
                                    </Text>
                                  </HStack>
                                  
                                </HStack>
                              </HStack>
                            ))}
                            {(payLoad?.user.perfil === "auxiliar" || payLoad?.user.isAdmin) && (
                              <IconButton
                                icon={<FaRegTrashAlt />}
                                variant="ghost"
                                aria-label="Delete"
                                color="xmentor.text_gray"
                                onClick={(i) => {
                                  i.stopPropagation(); // Impede a propagação do evento de clique
                                  setIdDelete(event.id || "")
                                  setIsDeleteOpen(true)
                                }}
                              />
                            )}
                          </HStack>
                         
                        </VStack>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel px="24px" py="10px">
                    {event.descricao}

                    {event.anexos.length > 0 && <Text mt={4}>Anexos:</Text>}
                    <VStack
                      display="flex"
                      w="100%"
                      align="flex-start"
                      spacing={1}
                    >
                      {event.anexos.map((i) => {
                        const isPDF = i.url.toLowerCase().endsWith(".pdf");
                        return (
                          <Text key={i.url}>
                            {isPDF && (
                              <IconButton
                                icon={<FaEye />} // Ícone para visualização
                                variant="ghost"
                                aria-label="Visualizar"
                                color={"xmentor.primary"}
                                onClick={() => handleViewPDF(`${process.env.REACT_APP_API}/tmp/step_attachment/${i.url}`)}
                              />)}
                            <IconButton
                              aria-label="Search database"
                              p={1}
                              mr={1}
                              size="60px"
                              icon={<Icon as={DownloadIcon} fontSize={20} />}
                              onClick={() =>
                                downloadFile({
                                  src: `${process.env.REACT_APP_API}/tmp/step_attachment/${i.url}`,
                                  uri: i.url,
                                })
                              }
                            />
                            {i.url.substring(i.url.indexOf("-") + 1)}
                          </Text>
                        );
                      })}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </HStack>
            </Flex>
          ))}
      </Accordion>
      {selectedMentor && (
        <ModalEditTime
          isOpen={isOpen}
          onClose={closeEditTimeModal}
          mentor={selectedMentor}
          historicoMentoriaId={historicoId}
          extraHours={extraHours}
          initialHours={mentorHours}
          onSave={async () => {
            // Lógica para salvar as horas editadas
            await Promise.all([
              ListarTrilhaUsuario(userSelected ? String(userSelected.id) : ""),
              FindUserById(userSelected ? String(userSelected.id) : ""),
            ]);
            setTimeout(
              async () =>
                await Promise.all([
                  ListarTrilhaUsuario(
                    userSelected ? String(userSelected.id) : ""
                  ),
                  FindUserById(userSelected ? String(userSelected.id) : ""),
                ]),
              750
            );
            // closeEditTimeModal();
          }}
        />
      )}
      <ModalPDFViewer
        isOpen={isPDFViewerOpen}
        onClose={handleClosePDFViewer}
        pdfUrl={pdfUrl}
      />
      <ModalDeleteHistoricoTrilha
        etapa_id={idDelete}
        mentorado_id={userSelected ? String(userSelected.id) : ""}
        isOpen={isDeleteOpen}
        onClose={()=>setIsDeleteOpen(false)}
        onSave={async () => {
          // Lógica para salvar as horas editadas
          await Promise.all([
            ListarTrilhaUsuario(userSelected ? String(userSelected.id) : ""),
            FindUserById(userSelected ? String(userSelected.id) : ""),
          ]);
          setTimeout(
            async () =>
              await Promise.all([
                ListarTrilhaUsuario(
                  userSelected ? String(userSelected.id) : ""
                ),
                FindUserById(userSelected ? String(userSelected.id) : ""),
              ]),
            750
          );
          // closeEditTimeModal();
        }}
      />
    </>
  );
};

interface IHorasExtras {
  data: {
    carga_horaria: number;
    id: string;
    data_cadastro: string;
  }[];
}
function HorasExtras({ data }: IHorasExtras) {
  var totalExtraMinutes = data
    ? data.reduce((acc, extra) => acc + extra.carga_horaria, 0)
    : 0;

  return (
    <Text fontSize={"sm"}>
      {totalExtraMinutes > 0 && (
        <Flex>+ {formatHours(totalExtraMinutes)} adicional</Flex>
      )}
    </Text>
  );
}
