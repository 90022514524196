import Cookies from "js-cookie";
import {
  ISearchUser,
  INewUser,
  IPayLoad,
  IUser,
  IUpdateProfileReq,
  IPayLoadLogin,
} from "./types";
import { AxiosRequestConfig } from "axios";
import apiXmentor from "../../server/apiServer";

export default function isAuthenticated(): boolean {
  const token = Cookies.get("@xmentor-token-auth");
  return !!token;
}
export function HearderAuthenticated() {
  // Configuração do header
  const json = Cookies.get("@xmentor-token-auth");
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${json}`,
    },
  };
  return config;
}

export function setUserLocalStorage(user: IPayLoad | null) {
  Cookies.set("@xmentor-user-data", JSON.stringify(user), {
    expires: 240 / 1440,
  });
  if (user && user.token) {
    Cookies.set("@xmentor-token-auth", user.token, {
      expires: 240 / 1440,
    });

    Cookies.remove("xmentor-user-isGoogle");
    if (user.user.googleOAuth2 == true) {
      Cookies.set("xmentor-user-isGoogle", "true", {
        expires: 240 / 1440,
      });
    }
  }
}

export function setLoginLocalStorage(data: IPayLoadLogin | null) {
  Cookies.set("@xmentor-login-data", JSON.stringify(data), {
    expires: 240 / 1440,
  });
}
export function getUserLocalStorage() {
  const json = Cookies.get("@xmentor-user-data");

  if (!json) {
    return null;
  }

  const user = JSON.parse(json);
  console.log(user);
  return user ?? null;
}
export function getTokenLocalStorage() {
  const token = Cookies.get("@xmentor-token-auth");

  if (!token) {
    return null;
  }
  return token;
}

export async function auth(email: string, password: string) {
  try {
    const request = await apiXmentor.post("/usuario-login", {
      email,
      password,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function authUser(loginId: string, mentoriaId: string) {
  try {
    const request = await apiXmentor.post("/autenticar-usuario", {
      loginId,
      mentoriaId,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function registerUser(form: FormData) {
  try {
    const request = await apiXmentor.post(`/usuario`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function updateUser(data: IUpdateProfileReq) {
  try {
    const request = await apiXmentor.patch(`/usuario`, data);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function searchUser(search: ISearchUser) {
  try {
    const request = await apiXmentor.get(
      `/usuario?nome=${search.nome}&perfil=${search.perfil}&mentoria_id=${search.mentoria_id}&pagina=${search.pagina}&mentor=${search.mentor}`
    );
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function findUserById(id: string) {
  try {
    const request = await apiXmentor.get(`/usuario-by-id/${id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateDescriptionProfile(
  id: string,
  description: string
) {
  try {
    const request = await apiXmentor.patch(`/usuario/descricao`, {
      id,
      description,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function findActvity(clienteId: string, status: string) {
  try {
    const request = await apiXmentor.get(
      `/atividade?clienteId=${clienteId}&status=${status}`
    );
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadAnexo(data: FormData) {
  try {
    const request = await apiXmentor.post(`/anexo`, data);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteAnexo(id: string) {
  try {
    const request = await apiXmentor.delete(`/anexo/${id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function criarVinculoMentorMentorado(
  usuario_mentor_id: string,
  usuario_mentorado_id: string
) {
  try {
    const request = await apiXmentor.post(`/vinculo-mentor-mentorado`, {
      usuario_mentor_id,
      usuario_mentorado_id,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deletarVinculoMentorMentorado(
  usuario_mentor_id: string,
  usuario_mentorado_id: string
) {
  try {
    const request = await apiXmentor.delete(
      `/vinculo-mentor-mentorado?usuario_mentor_id=${usuario_mentor_id}&usuario_mentorado_id=${usuario_mentorado_id}`
    );
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function criarAcao(
  titulo: string,
  data_fato: string,
  file: boolean,
  etapa_id: string,
  mentor_id: string,
  usuario_cliente_id: string,
  usuario_criador_id: string
) {
  try {
    const request = await apiXmentor.post(`/acao`, {
      titulo,
      data_fato,
      file,
      etapa_id,
      mentor_id,
      usuario_cliente_id,
      usuario_criador_id,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function atualizarAcao(
  id: string,
  titulo: string,
  status: boolean,
  data_fato: string
) {
  try {
    const request = await apiXmentor.put(`/acao`, {
      titulo,
      status,
      data_fato,
      id,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function responderAtividadeComTexto(id: string, resposta: string) {
  try {
    const request = await apiXmentor.patch(`/acao/resposta-em-texto`, {
      id,
      resposta,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function responderAtividadeComArquivo(data: FormData) {
  try {
    const request = await apiXmentor.post(`/acao/resposta-em-arquivo`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deletarAcao(id: string) {
  try {
    const request = await apiXmentor.delete(`/acao/${id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function criarAnotacoes(
  titulo: string,
  texto: string,
  usuario_cliente_id: string,
  usuario_criador_id: string
) {
  try {
    const request = await apiXmentor.post(`/anotacoes-admin`, {
      titulo,
      texto,
      usuario_cliente_id,
      usuario_criador_id,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function responderAnotacoes(id: string, resposta: string) {
  try {
    const request = await apiXmentor.patch(`/anotacoes-admin`, {
      id,
      resposta,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deletarAnotacoes(id: string) {
  try {
    const request = await apiXmentor.delete(`/anotacoes-admin/${id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function postCodeRecoverPassword(email: string) {
  try {
    const request = await apiXmentor.post(`/recover-password`, {
      email,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function postCreatePassword(
  code: string,
  pass: string,
  confirmPass: string
) {
  try {
    const request = await apiXmentor.post(`/create-password`, {
      code,
      pass,
      confirmPass,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function findNotifications(id: string) {
  try {
    const request = await apiXmentor.get(`/notificacao/${id}/find`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function checkNotifications(id: string) {
  try {
    const request = await apiXmentor.get(`/notificacao/${id}/check`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteMentorado(id: string) {
  try {
    const request = await apiXmentor.delete(`/usuario/mentorado/${id}/delete`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function atualizarRedesSociais(
  id: string,
  instagram: string,
  linkedin: string,
  youtube: string
) {
  try {
    const request = await apiXmentor.patch(`/usuario/redes-sociais`, {
      id,
      instagram,
      linkedin,
      youtube,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadBackground(id: string, data: FormData) {
  try {
    const request = await apiXmentor.patch(
      `/usuario/img-background/${id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadAvatar(id: string, data: FormData) {
  try {
    const request = await apiXmentor.patch(`/usuario/img-avatar/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function addExtraHours(
  mentorId: string,
  historicoMentoriaId: string,
  carga_horaria: number
) {
  try {
    const data = {
      carga_horaria: carga_horaria,
      mentorId: mentorId,
      historicoMentoriaId: historicoMentoriaId,
    };
    const request = await apiXmentor.patch(
      "/etapa-mentoria/hora-extra/mentor",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addTag(descricao: string, cor: string, usuarioId: string) {
  try {
    const request = await apiXmentor.post(`/tag`, {
      descricao, cor, usuarioId
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function deleteTag(id: string) {
  try {
    const request = await apiXmentor.delete(`/tag/${id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}