import { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Text,
  Tooltip,
  Heading,
  Box,
  Flex,
  Stack,
  Icon,
  Link,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import {
  getMentorsAgendaAPI,
  IMentorAgendamento,
} from "../../../helpers/agendaHelper";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { MentorList } from "./sections/MentorList";
import { Agendamentos } from "./sections/Agendamentos";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { FcGoogle } from "react-icons/fc";
import { getUserInfoAPI } from "../../../helpers/googleOAuthHelper";
import { useNavigate } from "react-router-dom";

export function Agenda() {
  // Estados
  const [mentorData, setMentorData] = useState<IMentorAgendamento[]>([]);
  const [isLoading, setLoading] = useState(true);

  // Hooks
  const auth = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  // Função para buscar dados dos mentores
  const fetchMentorData = async () => {
    try {
      const mentors = await getMentorsAgendaAPI();
      if (mentors.msg === "ok") {
        setMentorData(mentors.agendamentos);
      }
    } catch (error) {
      showErrorToast("Erro ao carregar dados dos mentores.");
    } finally {
      setLoading(false);
    }
  };

  // Efeito para carregar os dados ao montar o componente
  useEffect(() => {
    if (auth.isGoogle !== "true") {
      window.location.replace(`${process.env.REACT_APP_FRONT}/oauth/google`);
    }
    fetchMentorData();
  }, []);

  // Função para mostrar toast de erro
  const showErrorToast = (message: string) => {
    toast({
      position: "top",
      description: message,
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  };

  // Função para selecionar um mentor usando o ID
  const handleMentorSelection = (mentorId: string) => {
    navigate(`/admin/agenda/mentor/${mentorId}`);
    // setSelectedMentorId(mentorId);
  };

  return (
    <TemplateAdmin>
      <VStack align={"start"} my={"15px"} spacing={4} fontWeight={"bold"}>
        <Header />

        <Flex
          direction={{ base: "column", md: "row" }}
          w={"100%"}
          justifyContent="space-between"
          alignItems={"start"}
          gap={4}
        >
          <Box w={{ base: "100%", md: "75%" }}>
            <Agendamentos />
          </Box>
          <VStack
            border={"1px"}
            borderColor={"xmentor.borderCards"}
            borderRadius={"8px"}
            bgColor={"xmentor.background_content"}
            zIndex={1}
            w={{ base: "100%", md: "25%" }}
            align={"start"}
            h={{ base: "auto", md: "calc(100vh - 220px)" }}
            mt={{ base: 4, md: 0 }}
          >
            <MentorList
              isLoading={isLoading}
              mentorData={mentorData}
              handleMentorClick={(id) => handleMentorSelection(id)} // Seleciona mentor por ID
            />
          </VStack>
        </Flex>
      </VStack>
    </TemplateAdmin>
  );
}

// Componente para o cabeçalho da agenda
const Header = () => {
  const navigate = useNavigate()
  const { payLoad } = useAuth();
  const [email, setEmail] = useState<string | null>(null); // Armazenará o e-mail do usuário
  const [loading, setLoading] = useState<boolean>(true); // Indica se está carregando os dados

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userId = payLoad?.user.id || ""; // Substitua pelo ID do usuário autenticado
      try {
        const response = await getUserInfoAPI(userId);

        if ("userInfo" in response && response.msg === "ok") {
          // Verifica se a resposta contém userInfo
          setEmail(response.userInfo.email); // Armazena o e-mail
        }
      } catch (error) {
        navigate(`/oauth/google`);
        setEmail(null); // Se houver erro, zere o e-mail
      }


      setLoading(false); // Termina o carregamento
    };

    fetchUserInfo();
  }, []);

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ base: "flex-start", md: "center" }}
      w="100%"
      px={{ base: 3, md: 5 }}
      py={{ base: 3, md: 0 }}
      spacing={{ base: 3, md: 0 }}
    >
      <HStack spacing={2}>
        <Heading fontSize={{ base: 18, md: 24 }} my="24px" fontWeight={700}>
          Reuniões agendadas
        </Heading>
        <Tooltip
          label="Gerencie a agenda de reuniões dos mentores."
          fontSize="sm"
          borderRadius={"5px"}
        >
          <QuestionOutlineIcon fontSize={"14px"} />
        </Tooltip>
      </HStack>

      <VStack alignItems={"flex-start"}>
        <HStack spacing={1} alignItems={"start"}>
          <Icon as={FcGoogle} boxSize={{ base: 5, md: 6 }} />
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontSize={{ base: "sm", md: "md" }}>
              Conectado ao Google Calendar
            </Text>
            <HStack>
              <Text
                fontSize={{ base: "xs", md: "sm" }}
                color="gray.600"
                fontWeight={500}
              >
                {loading ? "Carregando..." : email || "Não conectado"}{" "}
                {/* Exibe o e-mail ou uma mensagem */}
              </Text>
              <Link
                color="blue.500"
                fontSize={{ base: "xs", md: "sm" }}
                href="/oauth/google"
                ml={1}
              >
                Alterar
              </Link>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Stack>
  );
};
