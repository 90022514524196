import React, { useState } from "react";
import {
    Button, Modal, ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Stack,
    Text, Input, Switch, Box, List, ListItem, ListIcon, IconButton,
    HStack,
    useTheme
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { ICreateStepModal } from "..";
import { CustomModalOverlay } from "./CustomModalOverlay";
import { useMentoriaGestao } from "../../../../context/MentoriaGestaoProvider/useMentoriaGestao";
import { FaCheckCircle, FaTrash } from 'react-icons/fa';

interface ICardUpdateStep {
    etapa_id: string;
    isOpen: boolean;
    onClose: () => void
}

export function UpdateStepModal({ isOpen, onClose, etapa_id }: ICardUpdateStep) {

    const { AlterarEtapaMentoria, etapasMentoria } = useMentoriaGestao();

    const etapa = etapasMentoria.find(i => i.id === etapa_id)
    const [newStepName, setNewStepName] = useState(etapa?.titulo || "");
    const [checklistMode, setChecklistMode] = useState(etapa && etapa?.CheckListGestao.length > 0 ? true : false);
    const [checklistItems, setChecklistItems] = useState<string[]>([]);
    const [checklistItemsRemove, setChecklistItemsRemove] = useState<string[]>([]);
    const [checklistInput, setChecklistInput] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const { payLoad } = useAuth();
    const theme = useTheme();
    const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;


    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewStepName(event.target.value);
    };

    const handleChecklistInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecklistInput(event.target.value);
    };

    const handleAddChecklistItem = () => {
        if (checklistInput.trim()) {
            setChecklistItems([...checklistItems, checklistInput.trim()]);
            setChecklistInput("");
        }
    };

    const handleRemoveChecklistItem = (index: number) => {
        const updatedChecklistItems = checklistItems.filter((_, i) => i !== index);
        setChecklistItems(updatedChecklistItems);
    };
    const handleRemoveChecklistItemAPI = (id: string) => {
        setChecklistItemsRemove([...checklistItemsRemove, id.trim()]);
    };

    const handleCreateStep = async () => {
        if (etapa) {
            setIsLoading(true)
            await AlterarEtapaMentoria(etapa?.id, newStepName, checklistItems, checklistItemsRemove)
        }
        onCancel();
    };

    const onCancel = () => {
        setIsLoading(false)
        setNewStepName("");
        setChecklistItems([]);
        setChecklistInput("");
        setChecklistMode(false);
        onClose();
    };
    return (
        <Modal isOpen={isOpen} closeOnEsc isCentered onClose={onClose}>
            <CustomModalOverlay />
            <ModalContent transition={"0.5s all"} >
                <ModalHeader>Editar etapa</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={4} transition={"0.5s all"}>
                        <Text>Digite o nome da nova etapa:</Text>
                        <Input
                            type="text"
                            value={newStepName}
                            onChange={handleNameChange}
                            required
                            placeholder="Nome da Etapa"
                        />
                        {/* Switch para ativar o modo checklist */}
                        <HStack justifyContent={'space-between'} w={'100%'}>
                            <Text>Adicionar checklist?</Text>
                            <Switch
                                isChecked={checklistMode}
                                onChange={() => setChecklistMode(!checklistMode)}
                                size='lg'
                            />
                        </HStack>

                        {/* Se o modo checklist estiver ativo, mostre o input de checklist */}
                        {checklistMode && (
                            <Box>
                                <Input
                                    type="text"
                                    value={checklistInput}
                                    onChange={handleChecklistInputChange}
                                    placeholder="Digite um item de checklist"
                                />
                                <Button mt={'20px'} variant={'outline'} w={'100%'} colorScheme="facebook" color='xmentor.primary' onClick={handleAddChecklistItem}>
                                    Adicionar
                                </Button>
                                <List mt={'20px'}>
                                    {checklistItems.map((item, index) => (
                                        <ListItem key={index} display="flex" justifyContent="space-between">
                                            <Box display="flex" alignItems="center">

                                                {item}
                                            </Box>
                                            <IconButton
                                                icon={<FaTrash />}
                                                aria-label="Remover"
                                                colorScheme="red"
                                                variant={'outline'}
                                                borderColor={'transparent'}
                                                size="sm"
                                                onClick={() => handleRemoveChecklistItem(index)}
                                            />
                                        </ListItem>
                                    ))}
                                    {etapa && etapa.CheckListGestao.map((item, index) => (
                                        !checklistItemsRemove.includes(item.id) && (
                                            <ListItem key={index} display="flex" justifyContent="space-between">
                                                <Box display="flex" alignItems="center">

                                                    {item.titulo}
                                                </Box>
                                                <IconButton
                                                    icon={<FaTrash />}
                                                    aria-label="Remover"
                                                    colorScheme="red"
                                                    variant={'outline'}
                                                    borderColor={'transparent'}
                                                    size="sm"
                                                    onClick={() => handleRemoveChecklistItemAPI(item.id)}
                                                />
                                            </ListItem>
                                        )
                                    ))}
                                </List>
                            </Box>
                        )}
                    </Stack>
                </ModalBody>
                <ModalFooter justifyContent={'space-between'} w={'100%'}>
                    <Button w={'40%'} colorScheme="black" variant={'outline'} mr={3} onClick={onCancel}>
                        Cancelar
                    </Button>
                    <Button
                        w={'40%'}
                        colorScheme="facebook"
                        backgroundColor='xmentor.primary'
                        onClick={handleCreateStep}
                        isDisabled={!newStepName.trim()}
                        isLoading={isLoading}
                        _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
                    >
                        Salvar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
