import apiXmentor from "../../server/apiServer";
import { IAtualizaEvento, INewEvento } from "./types";

export async function criarEvento(form: FormData | INewEvento) {
  try {
    // Se for FormData, altere o cabeçalho para 'multipart/form-data'
    let headers = { "Content-Type": "application/json" };
    if (form instanceof FormData) {
      headers = { "Content-Type": "multipart/form-data" };
    }

    const request = await apiXmentor.post("/evento", form, {
      headers,
    });

    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function consultaEvento(mentoria_id: string) {
  try {
    const request = await apiXmentor.get(`/evento/${mentoria_id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Nova função para consultar eventos futuros
export async function consultaEventosFuturos(mentoria_id: string) {
  try {
    const request = await apiXmentor.get(`/evento/futuros/${mentoria_id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Nova função para consultar eventos passados
export async function consultaEventosPassados(mentoria_id: string) {
  try {
    const request = await apiXmentor.get(`/evento/passados/${mentoria_id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
// Função para buscar um evento pelo ID
export async function buscarEventoPorId(id: string) {
  try {
    const request = await apiXmentor.get(`/evento/detalhes/${id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function atualizarEvento(
  id: string,
  form: FormData | IAtualizaEvento
) {
  try {
    // Configura os cabeçalhos para 'multipart/form-data' se o formulário for do tipo FormData
    let headers = { "Content-Type": "application/json" };
    if (form instanceof FormData) {
      headers = { "Content-Type": "multipart/form-data" };
    }

    const request = await apiXmentor.put(`/evento/${id}`, form, {
      headers,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deletarEvento(id: string) {
  try {
    const request = await apiXmentor.delete(`/evento/${id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Função para alternar a participação de um participante em um evento
export async function toggleParticipacao(
  evento_id: string,
  participante_id: string
) {
  try {
    const request = await apiXmentor.patch(
      `/evento/${evento_id}/participantes/${participante_id}/toggle`
    );
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Função para adicionar um participante a um evento
export async function adicionarParticipante(
  evento_id: string,
  participante_id: string
) {
  try {
    const request = await apiXmentor.post(`/evento/add-participantes`, {
      evento_id,
      participante_id,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
