import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import {
  Button,
  Box,
  Input,
  ButtonGroup,
  Image,
  Text,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { FaTrashAlt } from "react-icons/fa";

// Função utilitária para processar a imagem cortada
const getCroppedImg = async (imageSrc: string, croppedAreaPixels: any) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const { width, height } = croppedAreaPixels;
  canvas.width = width;
  canvas.height = height;

  ctx!.drawImage(
    image,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    width,
    height
  );

  // Retornando como blob
  return new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob || null); // Retorna o Blob da imagem cortada
    }, "image/jpeg");
  });
};

// Função para carregar a imagem a partir da URL (JavaScript nativo)
const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new window.Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

interface ImageCropperProps {
  setImage: (image: Blob | null) => void; // Função para definir a imagem cortada no componente pai
  imagePreview: string | null; // Pré-visualização da imagem no componente pai
  setImagePreview: (preview: string | null) => void; // Função para definir a pré-visualização no componente pai
  onRemoveImage?: () => void;
}

export const UploadEventCapa: React.FC<ImageCropperProps> = ({
  setImage,
  imagePreview,
  setImagePreview,
  onRemoveImage,
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null); // Armazena a imagem carregada
  const [crop, setCrop] = useState({ x: 0, y: 0 }); // Define a área de corte
  const [zoom, setZoom] = useState(1); // Nível de zoom
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null); // Área de corte final

  // Função chamada quando a área de corte é alterada
  const onCropChange = (newCrop: any) => {
    setCrop(newCrop);
  };

  // Função chamada quando o zoom é alterado
  const onZoomChange = (newZoom: number) => {
    setZoom(newZoom);
  };

  // Função chamada quando o usuário termina de selecionar a área de corte
  const onCropCompleteHandler = useCallback(
    (_: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  // Função para processar a imagem cortada
  const handleSave = useCallback(async () => {
    if (croppedAreaPixels && imageSrc) {
      try {
        const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels); // Obtém o Blob da imagem
        if (croppedBlob) {
          const croppedImageUrl = URL.createObjectURL(croppedBlob); // Cria uma URL para pré-visualizar
          setImagePreview(croppedImageUrl); // Atualiza a visualização da imagem cortada
          setImage(croppedBlob); // Envia o Blob da imagem cortada de volta para o componente pai
        }
      } catch (error) {
        console.error("Erro ao cortar a imagem:", error);
      }
    } else {
      console.error("Área de corte não está pronta.");
    }
  }, [croppedAreaPixels, imageSrc, setImage, setImagePreview]);

  // Função para cancelar o corte da imagem
  const handleCancel = () => {
    setImageSrc(null);
    setImagePreview(null);
    setCroppedAreaPixels(null);
    setImage(null);
    if (onRemoveImage) {
      onRemoveImage();
    }
  };

  // Carregar uma nova imagem
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  // Função para processar o arrasto de arquivos (drag-and-drop)
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      {!imageSrc && !imagePreview ? (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          w="100%"
          h="200px"
          border="2px solid #E2E8F0"
          bg={"xmentor.background_template"}
          borderRadius="md"
          p={4}
          textAlign="center"
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <Input
            type="file"
            accept="image/*"
            onChange={onFileChange}
            id="upload-input"
            style={{ display: "none" }} // Esconder o input real
          />
          <label htmlFor="upload-input">
            <Button
              as="span"
              bg={"xmentor.primary"}
              color={"white"}
              _hover={{ bg: "xmentor.primaryDark" }}
              cursor={"pointer"}
              fontSize={"sm"}
            >
              Importar do computador
            </Button>
          </label>
          <Text mt={2} fontSize={"sm"} color="gray.500">
            ou arraste a imagem aqui
          </Text>
        </Flex>
      ) : imagePreview ? (
        // Exibe a pré-visualização quando a imagem está disponível
        <Flex
          mt={4}
          textAlign="center"
          justifyContent={"center"}
          alignItems={"center"}
          position={"relative"}
        >
          <Flex position={"relative"}>
            <Image
              src={imagePreview}
              alt="Imagem Cortada"
              maxH="200px"
              borderRadius={"md"}
            />
            <IconButton
              icon={<FaTrashAlt />}
              border={"none"}
              colorScheme="red"
              aria-label="Remove Image"
              onClick={handleCancel}
              position={"absolute"}
              w={"30px"}
              minW={"30px"}
              h={"30px"}
              borderRadius={"full"}
              right={1}
              top={1}
              zIndex={10}
              _hover={{ bg: "none", color: "red.500" }}
            />
          </Flex>
        </Flex>
      ) : (
        <>
          <Box position="relative" width="100%" height="400px">
            <Cropper
              image={imageSrc || ""}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9} // Define a proporção
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropCompleteHandler}
            />
          </Box>
          <ButtonGroup mt={4} w="full" justifyContent="center">
            <Button variant="outline" colorScheme="red" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button
              onClick={handleSave}
              bg={"xmentor.primary"}
              color={"white"}
              _hover={{ bg: "xmentor.primaryDark" }}
            >
              Salvar imagem
            </Button>
          </ButtonGroup>
        </>
      )}
    </Box>
  );
};
