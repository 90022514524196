import "./Calendar.css";
import { useState } from "react";
import Calendar from "react-calendar";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
} from "@chakra-ui/react";
import { CustomModalOverlay } from "../pages/admin/trilha/components/CustomModalOverlay";

interface CalendarioMentoradoProps {
  markedDates: IEventsProps[];
}

interface IEventsProps {
  date: string;
  info: string;
}

export function CalendarioMentorado({ markedDates }: CalendarioMentoradoProps) {
  const [value, onChange] = useState<Date | null>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoForSelectedDate, setInfoForSelectedDate] = useState<string[]>([]);

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
    const isoDateString = date.toISOString().split("T")[0];
    const info = markedDates.find(
      (markedDate) => markedDate.date === isoDateString
    )?.info;
    setInfoForSelectedDate(info ? [info] : []);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <VStack>
      <Calendar
        tileClassName={({ date }) => {
          const isoDateString = date.toISOString().split("T")[0];
          return markedDates.some(
            (markedDate) => markedDate.date === isoDateString
          )
            ? "highlight"
            : "";
        }}
        onChange={(date) => onChange(date as Date)}
        value={value}
        onClickDay={(value) => handleDateClick(value)}
      />

      <Modal isOpen={isModalOpen} isCentered onClose={handleCloseModal}>
        <CustomModalOverlay />
        <ModalContent>
          <ModalHeader>Informações do Dia</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Data selecionada: {selectedDate?.toLocaleDateString()}</p>
            <ul>
              {infoForSelectedDate.map((info, index) => (
                <li key={index}>{info}</li>
              ))}
            </ul>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
