import React, { useState } from "react";
import {
  HStack,
  Text,
  IconButton,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Circle,
  Box,
} from "@chakra-ui/react";
import {
  IoDocumentOutline,
  IoWarning,
  IoWarningOutline,
} from "react-icons/io5";
import { FaDownload, FaEye, FaRegTrashAlt, FaTrashAlt } from "react-icons/fa";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { downloadFile } from "../../utils/downloadFile";
import { CustomModalOverlay } from "../../pages/admin/trilha/components/CustomModalOverlay";
import { IoMdDownload } from "react-icons/io";
import ModalPDFViewer from "../ModalPDFViewer";


interface MeetingDocumentProps {
  name: string;
  uri: string;
  id: string;
  dataCriacao: string;
  idUser: string;
}

const MeetingDocument: React.FC<MeetingDocumentProps> = ({
  name,
  id,
  idUser,
  uri,
  dataCriacao,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);

  const handleViewPDF = () => {
    setIsPDFViewerOpen(true);
  };

  const handleClosePDFViewer = () => {
    setIsPDFViewerOpen(false);
  };

  const pdfUrl = `${process.env.REACT_APP_API}/tmp/attachment/${uri}`;

  const isPDF = uri.toLowerCase().endsWith(".pdf");

  return (
    <VStack
      bgColor={"white"}
      p={"2"}
      align={"start"}
      whiteSpace="pre-wrap"
      w="100%"
      borderWidth="1px"
      borderRadius="lg"
    >
      <HStack justifyContent={"space-between"} w={"100%"}>
        <HStack spacing={3}>
          <IconButton
            variant="ghost"
            aria-label={""}
            color="xmentor.text_gray"
            fontSize="24px"
            icon={<IoDocumentOutline size={30} />}
          />
          <Box>
            <Text
              justifySelf={"start"}
              fontSize={"14px"}
              fontWeight={500}
              color={"xmentor.text_black"}
            >
              {name}
            </Text>
            <Text
              justifySelf={"start"}
              fontSize={"14px"}
              fontWeight={500}
              color={"xmentor.text_gray"}
            >
              Anexado em {dataCriacao}
            </Text>
          </Box>
        </HStack>
        <VStack spacing={0}>
          
          <IconButton
            icon={<IoMdDownload />}
            variant="ghost"
            aria-label="Download"
            color={"xmentor.primary"}
            ml={{ base: 0, md: "auto" }}
            mt={{ base: 3, md: 0 }}
            onClick={(event) => {
              event.stopPropagation(); // Impede a propagação do evento de clique
              downloadFile({
                src: `${process.env.REACT_APP_API}/tmp/attachment/${uri}`,
                uri: uri,
              });
            }}
          />
          {isPDF && (
           <IconButton
            icon={<FaEye />} // Ícone para visualização
            variant="ghost"
            aria-label="Visualizar"
            color={"xmentor.primary"}
            onClick={handleViewPDF}
          />)}
          <IconButton
            icon={<FaRegTrashAlt />}
            variant="ghost"
            aria-label="Delete"
            color={"xmentor.text_gray"}
            ml={{ base: 0, md: "auto" }}
            mt={{ base: 3, md: 0 }}
            onClick={(event) => {
              event.stopPropagation(); // Impede a propagação do evento de clique
              onOpen();
            }}
          />
        </VStack>
      </HStack>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        idAnexo={id}
        idUser={idUser}
        name={name}
      />
       <ModalPDFViewer
        isOpen={isPDFViewerOpen}
        onClose={handleClosePDFViewer}
        pdfUrl={pdfUrl}
      />
    </VStack>
  );
};

export default MeetingDocument;

interface IModalUpload {
  isOpen: boolean;
  onClose: () => void;
  idAnexo: string;
  idUser: string;
  name: string;
}

function ModalDelete({ isOpen, onClose, idUser, idAnexo, name }: IModalUpload) {
  const { DeleteAnexo } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  async function handleDelete() {
    setIsLoading(true);
    await DeleteAnexo(idAnexo, idUser);
    onClose();
    setIsLoading(false);
  }
  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <CustomModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Circle bg="yellow.100" p={2}>
            <IoWarningOutline color="red" size={28} />
          </Circle>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>Deseja excluir o anexo "{name}"?</ModalBody>
        <ModalFooter gap={2}>
          <Button variant="outline" size="lg" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={handleDelete}
            isLoading={isLoading}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
