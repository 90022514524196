import React, { useState } from "react";
import {
  VStack,
  Text,
  Heading,
  InputGroup,
  InputLeftElement,
  Input,
  HStack,
  Box,
  Avatar,
  Icon,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { SearchIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { IUser } from "../../../../context/AuthProvider/types";

interface MentorListProps {
  mentorData: IUser[] | undefined;
  handleMentorClick: (id: string) => void;
  isLoading: boolean;
}

export const MentorList: React.FC<MentorListProps> = ({
  mentorData,
  handleMentorClick,
  isLoading,
}) => {
  // Estado para armazenar o termo de pesquisa
  const [searchTerm, setSearchTerm] = useState("");

  // Função para lidar com a mudança no campo de pesquisa
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  if (!mentorData) {
    return null;
  }

  // Filtra os mentores com base no termo de pesquisa
  const filteredMentors = mentorData?.filter((mentor) =>
    mentor?.nome?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <VStack alignItems="start" w="100%" spacing={4} p={4}>
      {/* Cabeçalho */}
      <Heading fontSize="md">Conheça nossos mentores</Heading>

      {/* Campo de Pesquisa */}
      <InputGroup maxW="100%">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.500" />
        </InputLeftElement>
        <Input
          placeholder="Pesquisar mentor"
          bg="#FFF"
          border="1px solid #EAECF0"
          h={10}
          pl={10}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </InputGroup>

      {/* Lista de Mentores */}
      {!isLoading ? (
        filteredMentors.length > 0 ? (
          <VStack
            w="100%"
            maxH={"100vh"}
            alignItems="stretch"
            spacing={3}
            overflowY={"auto"}
          >
            {filteredMentors.map((mentor, index) => {
              return (
                <HStack
                  key={index}
                  onClick={() => handleMentorClick(mentor?.id || "")}
                  w="100%"
                  p={3}
                  bg="white"
                  border="1px solid #EAECF0"
                  borderRadius="md"
                  alignItems="center"
                  cursor="pointer"
                  _hover={{ bg: "#f9f9f9" }}
                >
                  {/* Avatar do Mentor */}
                  <Avatar
                    size="md"
                    borderRadius={"md"}
                    src={
                      mentor.foto
                        ? `${process.env.REACT_APP_API}/tmp/user/${mentor.foto}`
                        : ""
                    }
                  />

                  {/* Detalhes do Mentor */}
                  <Box flex="1" ml={1}>
                    <Text fontWeight="bold" fontSize={"sm"}>
                      {mentor.nome}
                    </Text>
                  </Box>

                  {/* Ícone de Ação */}
                  <Icon as={ChevronRightIcon} boxSize={6} color="gray.500" />
                </HStack>
              );
            })}
          </VStack>
        ) : (
          <Text>Não foi localizado nenhum mentor</Text>
        )
      ) : (
        <Center w={"100%"} h={"calc(100vh - 345px)"}>
          <Spinner color="xmentor.primary" size="xl" />
        </Center>
      )}
    </VStack>
  );
};
