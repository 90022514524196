import {
  Flex,
  HStack,
  VStack,
  Text,
  Box,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Icon,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CustomModalOverlay } from "../pages/admin/trilha/components/CustomModalOverlay";
import { IoIosArrowForward } from "react-icons/io";

interface Props {
  mentor: string;
  horario: string;
  dia: string;
  isMentorado?: boolean;
  href?: string;
}

export function CardMentorias({
  mentor,
  horario,
  dia,
  isMentorado,
  href,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Flex>
      <HStack
        border="1px solid #D0D5DD"
        boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.08)"
        rounded={14}
        cursor={"pointer"}
        bg="xmentor.background_content"
        pr={5}
        w={"247px"}
        h={"87px"}
        transition={"0.5s"}
        _hover={{
          backgroundColor: "gray.100",
        }}
        onClick={handleToggle} // Adiciona onClick aqui
      >
        <Box p={1.5} bg="xmentor.primary" h="100%" borderLeftRadius={14} />
        <VStack
          align="flex-start"
          p={3}
          spacing={3}
          style={{
            zoom: 0.7,
          }}
        >
          <HStack spacing={3}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ellipse cx="12" cy="6.5" rx="4.5" ry="5" fill="#1A1A1A" />
              <path
                d="M21 19C21 22.0376 16.4183 21 12 21C7.58172 21 3 22.0376 3 19C3 15.9624 7.58172 13.5 12 13.5C16.4183 13.5 21 15.9624 21 19Z"
                fill="#1A1A1A"
              />
            </svg>
            <VStack align={"flex-start"} spacing={1}>
              <Text
                color="xmentor.text_black"
                fontStyle="normal"
                fontWeight={600}
                lineHeight="14px"
                w={"160px"}
                letterSpacing="0.75px"
              >
                {mentor}
              </Text>
              <Text
                color="xmentor.text_gray"
                fontStyle="normal"
                fontWeight={400}
                lineHeight="14px"
                letterSpacing="0.75px"
              >
                {isMentorado ? "Mentorado" : "Mentor"}
              </Text>
            </VStack>
          </HStack>
          <HStack spacing={3}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.9999"
                cy="11.9999"
                r="10"
                stroke="#1A1A1A"
                stroke-width="1.8"
              />
              <path
                d="M12 6.5V12L15 15"
                stroke="#1A1A1A"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <VStack align={"flex-start"} spacing={1}>
              <Text
                color="xmentor.text_black"
                fontStyle="normal"
                fontWeight={600}
                lineHeight="14px"
                letterSpacing="0.75px"
              >
                {dia}
              </Text>
              <Text
                color="xmentor.text_gray"
                fontStyle="normal"
                fontWeight={400}
                lineHeight="14px"
                letterSpacing="0.75px"
              >
                {horario}
              </Text>
            </VStack>
          </HStack>
        </VStack>
        <IoIosArrowForward size={28} color="gray.800" />
      </HStack>

      <Modal isCentered isOpen={isOpen} onClose={handleToggle}>
        <CustomModalOverlay />
        <ModalContent>
          <ModalHeader>Link do Meet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{href}</ModalBody>

          <ModalFooter>
            {href !== "Aguardando mentorado confirmar data" && (
              <Button
                colorScheme="blue"
                onClick={() => window.open(href || "", "_blank")}
              >
                Iniciar reunião
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
