import apiXmentor from "../../server/apiServer"
import { IDataReordenarEtapasGestao } from "./types"


export async function criarEtapaMentoria(titulo: string, mentoria_id: string, checkList: string[]) {
  try {
    const request = await apiXmentor.post("/etapa-gestao-mentoria", { titulo, mentoria_id, checkList })
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function consultaEtapaMentoria(mentoria_id: string) {
  try {
    const request = await apiXmentor.get(`/etapa-gestao-mentoria/${mentoria_id}`)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function avancarEtapaMentoria(mentoria_id: string, etapa_id: string, usuario_id: string) {
  try {
    const request = await apiXmentor.put(`/etapa-gestao-mentoria/avancar`, { mentoria_id, etapa_id, usuario_id })
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function deletarEtapaMentoria(id: string) {
  try {
    const request = await apiXmentor.delete(`/etapa-gestao-mentoria/${id}`)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function alterarEtapaMentoria(id: string, titulo: string, newCheckList: string[], removeCheckList: string[]) {
  try {
    const request = await apiXmentor.put("/etapa-gestao-mentoria", { id, titulo, newCheckList, removeCheckList })
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function checkEtapa(
  usuario_id: string,
  mentoria_id: string,
  etapa_id: string,
  ckeck_list_item_id: string
) {
  try {
    const request = await apiXmentor.post("/etapa-gestao-mentoria/check", {
      usuario_id,
      mentoria_id,
      etapa_id,
      ckeck_list_item_id
    })
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }
}


export async function reordenarEtapasMentoria(data: IDataReordenarEtapasGestao ){
  try {
    const request = await apiXmentor.patch("/etapa-gestao-mentoria/reordenar", data);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}