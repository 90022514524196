import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CardMentorias } from "../../../components/CardMentorias";
import { CardAtividadesMentorado } from "../../../components/CardAtividadesMentorado";
import { CalendarioMentorado } from "../../../components/CalendarioMentorado";
import { TemplateMentorado } from "../../../components/layout/mentorado/TemplateMentorado";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { useNavigate } from "react-router-dom";
import { CardMentorAgendaAberta } from "../../../components/CardMentorAgendaAberta";
import {
  IAgendamentoClosed,
  IAgendamentoFreeAPI,
  getUserAgendaAPI,
} from "../../../helpers/agendaHelper";
import { useEvento } from "../../../context/EventoProvider/useEvento";
import { EventoProvider } from "../../../context/EventoProvider";
import { MentorList } from "./sections/MentorList";
import EventCard from "../../../components/EventCard";
import { ConvertedTime, convertToClientTimezone } from "../../../utils/convertTimezone";
import { formatarData } from "../../../utils/formatarData";

interface IEventsProps {
  date: string;
  info: string;
}
const convertDateToISO = (date: string): string => {
  const [day, month, year] = date.split("/").map(Number);
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
};
function Mentorado() {
  const { ConsultaEventosFuturos, eventosFuturos: eventos } = useEvento();
  const [closedAgenda, setClosedAgenda] = useState<IAgendamentoClosed[]>([]);
  const [openAgenda, setOpenAgenda] = useState<IAgendamentoFreeAPI[]>([]);
  const [formatedDate, setFormatedDate] = useState<IEventsProps[]>([]);
  const { FindActivity, activity, payLoad, SearchUser, users } = useAuth();

  const navigate = useNavigate();

  const Auth = useAuth();
  const getCurrentTimezone = (): string => {
    // Captura o fuso horário atual usando a API Intl
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  const timezone = getCurrentTimezone()
  const RefreshAgenda = async () => {
    const response = await getUserAgendaAPI(
      Auth.payLoad?.user.id as string,
      false
    );
    if (response.msg === "ok") {
      setOpenAgenda(response.events.free);
      setClosedAgenda(response.events.marked);
      const dates: IEventsProps[] = [];
      response.events.marked.forEach((e) => {
        const fDate = e.data.split("/");
        dates.push({
          date: `${fDate[2]}-${fDate[1]}-${fDate[0]}`,
          info: `Reunião marcada com ${e.mentor}`,
        });
      });
      setFormatedDate((prev) => [...prev, ...dates]);
    }
  };

  useEffect(() => {
    if (payLoad && payLoad.user.id) FindActivity(payLoad?.user.id, "0");

    if (payLoad?.user.mentoria_id)
      SearchUser({
        nome: "",
        pagina: 0,
        perfil: "mentor",
        mentoria_id: payLoad?.user.mentoria_id,
        mentor: "",
      });

    RefreshAgenda();
  }, []);

  useEffect(() => {
    if (payLoad?.user.mentoria_id) {
      ConsultaEventosFuturos(payLoad.user.mentoria_id);
    }
  }, [payLoad]);

  useEffect(() => {
    if (activity.length > 0) {
      const dates: IEventsProps[] = [];
      activity.forEach((e) => {
        return dates.push({
          date: e.data_fato,
          info: `Atividade - ${e.titulo}`,
        });
      });
      setFormatedDate((prev) => [...prev, ...dates]);
    }
  }, [activity]);

  const updateData = () => {
    if (payLoad && payLoad.user.id) {
      const idUser = payLoad.user.id;
      new Promise(async (resolve) => {
        FindActivity(idUser, "0");
      });
      setTimeout(async () => {
        FindActivity(idUser, "0");
      }, 750);
    }
  };

  const isFutureDate = (dateStr: string, timeStr: string) => {
    const [day, month, year] = dateStr.split("/");
    const [hour, minute] = timeStr.split(":");
    const eventDate = new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);

    // Adicionando 1 hora de tolerância ao horário atual
    const now = new Date();
    now.setHours(now.getHours() - 2);

    return eventDate >= now;
  };

  const handleMentorSelection = (mentorId: string) => {
    navigate(`/mentorado/mentor/${mentorId}`);
  };

  return (
    <TemplateMentorado updateNotification={updateData}>
      <VStack align={"start"} my={"15px"} spacing={0} fontWeight={"bold"}>
        <Text fontSize={"3xl"}>Bem-vindo(a), {payLoad?.user.nome}!</Text>
        <Text color={"#494949"}>Veja o resumo da sua semana</Text>
      </VStack>

      <Flex
        direction={{ base: "column", md: "row" }}
        w={"100%"}
        justifyContent="space-between"
        alignItems={"start"}
        gap={4}
      >
        <Box
          w={{ base: "100%", md: "100%" }}
          // bg="#FFF"
          // borderRadius="8px"
          // border="1px solid #EAECF0"
          // p={4}
        >
          <VStack align={"start"} mb={"36px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={20} mb={"24px"} fontWeight={"semibold"}>
              Próximas reuniões
            </Text>
            <HStack
              overflow={"auto"}
              maxW={"100%"}
              css={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {closedAgenda.filter((event) =>
                isFutureDate(event.data, event.time)
              ).length > 0 ? (
                closedAgenda
                  .filter((event) => isFutureDate(event.data, event.time))
                    .map((event) => {
                      const data: ConvertedTime = convertToClientTimezone({
                        date: convertDateToISO(event.data),
                        time: event.time,
                        clientTimezone: timezone,
                        serverTimezone: event.timezone
                      })
                    return (
                      <CardMentorias
                        dia={formatarData(data.convertedDate.date)}
                        horario={data.convertedDate.time}
                        mentor={event.mentor || ""}
                        href={event.meet_link}
                      />
                    );
                  })
              ) : (
                <Text fontSize={"lg"} color={"gray"}>
                  Não há reuniões marcadas
                </Text>
              )}
            </HStack>
          </VStack>
          <VStack align={"start"} my={"40px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={20} mb={"16px"} fontWeight={"semibold"}>
              Atividades atuais
            </Text>
            <VStack
              // bgColor="xmentor.background_content"
              w={["100%"]}
              borderRadius={10}
              spacing={0}
            >
              {activity.length > 0 ? (
                activity.map((data, index) => (
                  <React.Fragment key={index}>
                    <CardAtividadesMentorado
                      mentor={data.mentor.nome}
                      foto={data.mentor.foto || ""}
                      horario={data.data_cadastro}
                      atividade={data.titulo}
                      vencimento={data.data_fato}
                      feedback={data.file}
                      id={data.id}
                    />
                    {index !== activity.length - 1 && (
                      <Divider borderColor="gray.200" />
                    )}
                  </React.Fragment>
                ))
              ) : (
                <Text
                  align={"start"}
                  w={"full"}
                  fontSize={"lg"}
                  textColor={"gray"}
                >
                  Sem atividades
                </Text>
              )}
            </VStack>
          </VStack>

          <VStack align={"start"} my={"40px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={20} mb={"16px"} fontWeight={"600"}>
              Agenda aberta
            </Text>
            <GridItem colSpan={1} rowSpan={1} w={"100%"}>
              {closedAgenda.filter((event) =>
                isFutureDate(event.data, event.time)
              ).length <= 0 && openAgenda.length > 0 ? (
                <SimpleGrid
                  columns={{ base: 1, md: 1, lg: 2 }}
                  w={"100%"}
                  spacing={"24px"}
                >
                  {openAgenda.map((agenda) => {
                    if (!closedAgenda.find((i) => i.mentor === agenda.nome)) {
                      //GAMBIARRA (FILTRAR PELA API POR FUTURA VARIAVEL GLOBAL QUE DEFINE A QUANTIDADE DE REUNIOES SIMULTANEAS PERMITIDAS)
                      if (
                        agenda.agendamentos &&
                        Object.keys(agenda.agendamentos).length > 0
                      ) {
                        return (
                          <CardMentorAgendaAberta
                            mentor={agenda.nome}
                            img={`${process.env.REACT_APP_API}/tmp/user/${agenda.foto}`}
                            desc={"Mentor"}
                            availableDates={agenda.agendamentos}
                            refreshCB={RefreshAgenda}
                          />
                        );
                      }
                    }
                  })}
                </SimpleGrid>
              ) : (
                <Text fontSize={"lg"} color={"gray"}>
                  Não há horários disponíveis
                </Text>
              )}
            </GridItem>
          </VStack>

          <VStack align={"start"} my={"40px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={20} mb={"16px"} fontWeight={"600"}>
              Eventos
            </Text>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              w={"100%"}
              spacing={4}
            >
              {eventos.length > 0 &&
                eventos.map((evento) => <EventCard evento={evento} />)}
            </SimpleGrid>
          </VStack>
        </Box>
        <VStack
          spacing={6}
          w={{ base: "100%", md: "30%" }}
          maxW={{ base: "100%", md: "350px" }}
        >
          <VStack align={"start"} w="full" spacing={0} fontWeight={"normal"}>
            <Text fontSize={20} mb={"16px"} fontWeight={"semibold"}>
              Calendário
            </Text>
            <CalendarioMentorado markedDates={formatedDate} />
          </VStack>
          <VStack
            border={"1px"}
            borderColor={"xmentor.borderCards"}
            borderRadius={"8px"}
            bgColor={"xmentor.background_content"}
            zIndex={1}
            align={"start"}
            h={"auto"}
            w="full"
            mt={{ base: 4, md: 0 }}
          >
            <MentorList
              mentorData={users?.data}
              handleMentorClick={handleMentorSelection}
              isLoading={false}
            />
          </VStack>
          {/* {users &&
              users.data.map((data) => (
                <CardMentor
                  key={data.nome}
                  image={data.foto || ""}
                  mentor={String(data.nome)}
                  onClick={() => navigate("/mentorado/mentor", { state: data })}
                />
              ))} */}
        </VStack>
      </Flex>

      <Grid
        templateColumns={{ base: "100%", lg: "60% calc(60% - 336px)" }}
        gap={"24px"}
        w="100%"
        h="100%"
      >
        <GridItem colSpan={1} rowSpan={1}></GridItem>
        <GridItem rowSpan={2} colSpan={1} w={"100%"}></GridItem>
      </Grid>
    </TemplateMentorado>
  );
}

export function MentoradoHome() {
  return (
    <EventoProvider>
      <Mentorado />
    </EventoProvider>
  );
}
