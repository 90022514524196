import React, { useState } from "react";
import {
  Button, Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Circle
} from "@chakra-ui/react";
import { CustomModalOverlay } from "../../trilha/components/CustomModalOverlay";
import { IoWarningOutline } from "react-icons/io5";
import { useMentoria } from "../../../../context/MentoriaProvider/useMentoria";
interface IModal {
  isOpen: boolean;
  onClose: () => void;
  etapa_id: string;
  mentorado_id: string;
  onSave: () => void;
}
export function ModalDeleteHistoricoTrilha({ isOpen, onClose, etapa_id, mentorado_id,onSave }: IModal) {
  const {DeletarHistoricoTrilha} = useMentoria()
  const [isLoading, setIsLoading] = useState(false);
  async function handleDelete() {
    setIsLoading(true);
    await DeletarHistoricoTrilha(etapa_id, mentorado_id);
    onSave()
    onClose();
    setIsLoading(false);
  }
  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <CustomModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" justifyContent="flex-start" alignContent="flex-start" alignItems="flex-start">
          <Circle bg="yellow.100" p={2}>
            <IoWarningOutline color="red" size={28} />
          </Circle>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Deseja realmente excluir?
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="outline" size="lg" onClick={onClose}>
            Cancelar
          </Button>
          <Button colorScheme="blue" size="lg" onClick={handleDelete} isLoading={isLoading}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
