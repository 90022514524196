import React, { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Input,
  Textarea,
  Select,
  useToast,
  Divider,
  FormLabel,
  VStack,
  IconButton,
  Flex,
  ModalOverlay,
  Box,
  Icon,
  Text
} from "@chakra-ui/react";
import { useMentoria } from "../../../../context/MentoriaProvider/useMentoria";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { ICardStep, ICreateStepModal, IMentor, IMentoresCargaHoraria } from "..";
import { CustomModalOverlay } from "./CustomModalOverlay";
import { searchUser } from "../../../../context/AuthProvider/util";
import { IUser } from "../../../../context/AuthProvider/types";
import { FileUpload } from "../../../../components/uploadFile";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { timeMapping, minutesToIndex, formatHours } from "../../../../utils/calculateHours";
import { IMentores } from "../DTOs/mentor.dto";
import { IAnexo } from "../../../../context/MentoriaProvider/types";
import { BsTrash } from "react-icons/bs";



interface ICardUpdateStep {
  title: string;
  description: string;
  mentoria_id: string;
  etapa_id: string;
  isOpenModal: boolean;
  mentoresAtuais: IMentores[];
  anexos: IAnexo[]
  onClose: () => void
}

export function UpdateStepModal({
  isOpenModal,
  title,
  mentoria_id,
  etapa_id,
  description,
  mentoresAtuais,
  onClose,
  anexos
}: ICardUpdateStep) {
  const [titleUpdate, setTitleUpdate] = useState(title);
  const [descriptionUpdate, setDescriptionUpdate] = useState(description);
  const [newStepHours, setNewStepHours] = useState<number>(0);
  const [mentoresSelected, setMentoresSelected] = useState<IMentores[]>(mentoresAtuais);
  const { payLoad } = useAuth();
  const [mentores, setMentores] = useState<{ id: string; name: string }[]>([]);
  const { ConsultaEtapaMentoria, AlterarEtapaMentoria, DeletarAnexoEtapaMentoria } = useMentoria();
  const toast = useToast();
  const [startUpload, setStartUpload] = useState(false);
  const [mentorSelected, setMentorSelected] = useState("");
  const [statusUpload, setStatusUpload] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);


  useEffect(() => {
    if (isOpenModal == true) {
      loadMentor();
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (startUpload) {
      setTimeout(async () => await ConsultaEtapaMentoria(mentoria_id), 750);
    }
  }, [startUpload])
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleUpdate(event.target.value);
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value, 10);
    const minutes = timeMapping[selectedValue];
    setNewStepHours(minutes);
  };

  const addMentor = () => {
    if (mentorSelected !== "" && newStepHours > 0) {
      const selectedMentor = mentores.find(
        (mentor) => mentor.id === mentorSelected
      );
      if (selectedMentor) {
        setMentoresSelected([
          ...mentoresSelected,
          { mentorId: selectedMentor.id, carga_horaria: newStepHours },
        ]);
        setMentorSelected("");
        setNewStepHours(0);
      }
    }
  };

  const removeMentor = (MentorId: string) => {
    setMentoresSelected(
      mentoresSelected.filter((mentor) => mentor.mentorId !== MentorId)
    );
  };


  const handleCreateStep = async () => {
    if (payLoad && payLoad.user && payLoad.user.mentoria_id) {
      setIsDisabled(true);
      const mentoria_id = payLoad.user.mentoria_id;
      var dataMentores: IMentores[]
      if (mentorSelected !== "" && newStepHours > 0) {
        dataMentores = [...mentoresSelected, {
          mentorId: mentorSelected, carga_horaria: newStepHours
        }]
      } else {
        dataMentores = mentoresSelected
      }
      await AlterarEtapaMentoria(
        mentoria_id,
        etapa_id,
        titleUpdate,
        descriptionUpdate,
        dataMentores
      );    
      if (etapa_id) {
        new Promise((resolve) => setTimeout(resolve, 500));
        setStartUpload(true);
      }
      if (!statusUpload) {
        onCancel();
      }
    }
  };

  const loadMentor = async () => {
    
      try {
        const response = await searchUser({
          mentoria_id: mentoria_id,
          nome: "",
          pagina: 0,
          perfil: "mentor",
          mentor:''
        });
        const prev = response.data.map((i: IUser) => ({
          id: i.id,
          name: i.nome,
        }));
        setMentores(prev);
      } catch (error: any) {
        toast({
          position: "top",
          description: "Error",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    
  };

  const onCancel = async () => {
    
    await ConsultaEtapaMentoria(mentoria_id);
    onClose();
    setTimeout(async () => await ConsultaEtapaMentoria(mentoria_id), 750);
    // setTimeout(async () => await ConsultaEtapaMentoria(mentoria_id), 750);
    setIsDisabled(false);
    setStatusUpload(false)
    setStartUpload(false)  
  };
  return (
    <Modal isOpen={isOpenModal} closeOnEsc isCentered size={"xl"} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar etapa</ModalHeader>
        <ModalCloseButton mt={2} />
        <Divider />
        <ModalBody w={"70%"} mx="auto" mt={2}>
          <Stack spacing={4}>
            <VStack alignItems={"left"} spacing={0}>
              <FormLabel
                fontSize={"sm"}
                fontWeight="bold"
                mb={1}
                htmlFor={"name"}
              >
                Nome da etapa:
              </FormLabel>
              <Input
                id={"name"}
                bg="xmentor.background_template"
                type="text"
                fontSize={"sm"}
                color={"black"}
                placeholder="Escreva um nome para a etapa"
                value={titleUpdate}
                onChange={handleNameChange}
              />
            </VStack>

            <VStack alignItems={"left"} spacing={0}>
              <FormLabel
                fontSize={"sm"}
                fontWeight="bold"
                mb={1}
                htmlFor={"description"}
              >
                Descrição:
              </FormLabel>
              <Textarea
                id="description"
                bg="xmentor.background_template"
                fontSize={"sm"}
                color={"black"}
                value={descriptionUpdate}
                onChange={(e) => setDescriptionUpdate(e.target.value)}
                placeholder="Descrição da Etapa"
              />
            </VStack>

            <VStack alignItems={"left"} spacing={0}>
              <Flex
                alignItems={"center"}
                mb={2}
                justifyContent={"space-between"}
              >
                <FormLabel
                  fontSize={"sm"}
                  fontWeight="bold"
                  mb={1}
                  htmlFor={"addMentor"}
                >
                  Adicione mentores e horas
                </FormLabel>
                <IconButton
                  colorScheme="primary"
                  bgColor={"xmentor.primary"}
                  color={"white"}
                  icon={<FaPlus />}
                  aria-label="Add Mentor"
                  onClick={addMentor}
                />
              </Flex>
              <Select
                id="addMentor"
                bg="xmentor.background_template"
                fontSize={"sm"}
                color={"black"}
                placeholder="Adicionar mentores"
                value={mentorSelected}
                onChange={(e) => setMentorSelected(e.target.value)}
              >
                {mentores &&
                  mentores
                    .filter(
                      (itemPrincipal) =>
                        !mentoresSelected.some(
                          (itemExcluir) =>
                            itemExcluir.mentorId === itemPrincipal.id
                        )
                    )
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
              </Select>
              <Select
                mt={3}
                fontSize={"sm"}
                value={minutesToIndex(newStepHours)}
                onChange={handleHoursChange}
                bg={"xmentor.background_template"}
                placeholder="Selecione a carga horária"
              >
                <option value={1}>15 Min</option>
                <option value={2}>30 Min</option>
                <option value={3}>1 Hora</option>
                <option value={4}>1 Hora e 30 Min</option>
                <option value={5}>2 Horas</option>
                <option value={6}>2 Horas e 30 Min</option>
                <option value={7}>3 Horas</option>
              </Select>

              <VStack alignItems={"left"} spacing={2} mt={5}>
                {mentoresSelected.map((mentor, index) => (
                  <Flex key={index} alignItems="center" width="100%">
                    <Input
                      isReadOnly
                      value={
                        mentores.find((m) => m.id === mentor.mentorId)?.name ||
                        ""
                      }
                      bg="xmentor.background_template"
                      fontSize={"sm"}
                      color={"black"}
                      mr={2}
                    />
                    <Select
                      mr={2}
                      fontSize={"sm"}
                      value={minutesToIndex(mentor.carga_horaria)}
                      onChange={(event) => {
                        const selectedValue = parseInt(event.target.value, 10);
                        const minutes = timeMapping[selectedValue];

                        setMentoresSelected((prev) =>
                          prev.map((item) =>
                            item.mentorId === mentor.mentorId
                              ? { ...item, carga_horaria: minutes }
                              : item
                          )
                        );
                      }}
                      bg={"xmentor.background_template"}
                      placeholder="Selecione a carga horária"
                    >
                      <option value={1}>15 Min</option>
                      <option value={2}>30 Min</option>
                      <option value={3}>1 Hora</option>
                      <option value={4}>1 Hora e 30 Min</option>
                      <option value={5}>2 Horas</option>
                      <option value={6}>2 Horas e 30 Min</option>
                      <option value={7}>3 Horas</option>
                    </Select>

                    <IconButton
                      icon={<FaRegTrashAlt />}
                      aria-label="Remove Mentor"
                      onClick={() => removeMentor(mentor.mentorId)}
                    />
                  </Flex>
                ))}
              </VStack>

              <VStack alignItems={"left"} spacing={2} mt={5}>
                <FormLabel
                  fontSize={"sm"}
                  fontWeight="bold"
                  mb={1}
                  htmlFor={"uploadFile"}
                >
                  Faça upload de um arquivo
                </FormLabel>
                <FileUpload
                  maxLenght={0}
                  status={setStatusUpload}
                  sendRequest={startUpload}
                  id={etapa_id}
                  clear={onCancel}
                />
                <VStack w="100%" spacing="10px">
                  {anexos.map((file, index) => {
                    return (
                      <Flex key={index} alignItems="center" justifyContent="space-between" width="100%">
                        <Text>{file.url.substring(file.url.indexOf("-") + 1)}</Text>
                        <IconButton
                          icon={<FaRegTrashAlt />}
                          aria-label="Remove anexo"
                          onClick={() => DeletarAnexoEtapaMentoria(file.id, etapa_id)}
                        />
                      </Flex>
                    );
                  })}
                </VStack>
              </VStack>
            </VStack>
          </Stack>
        </ModalBody>
        <Divider />
        <ModalFooter gap={6}>
          <Button
            fontSize={"sm"}
            px={7}
            variant="outline"
            size="lg"
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            fontSize={"sm"}
            px={8}
            size="lg"
            bg={"xmentor.primary"}
            textColor={"white"}
            onClick={handleCreateStep}
            isLoading={isDisabled}
            isDisabled={!title.trim()}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
