import React, { ReactNode, useEffect, useState } from "react";
import {
  Button,
  HStack,
  Tooltip,
  Heading,
  Stack,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { QuestionOutlineIcon, DownloadIcon } from "@chakra-ui/icons";
import { MentoriaProvider } from "../../../context/MentoriaProvider";
import { useMentoria } from "../../../context/MentoriaProvider/useMentoria";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { ItemMentorado } from "./components/ItemMentorado";
import { CreateStepModal } from "./components/CreateStepModal";
import { CardStep } from "./components/CardStep";
import "./style.css";
import { downloadFile } from "../../../utils/downloadFile";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import ConfirmationModal from "./components/ConfirmationModal";
import { IAnexo } from "../../../context/MentoriaProvider/types";

function JornadaTrilhaChildren() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const { payLoad } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { ConsultaEtapaMentoria, etapasMentoria, AvancarEtapaMentoria, ReordenarEtapasMentoria } = useMentoria();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [etapaIndex, setEtapaIndex] = useState(-1);
  const [dragResult, setDragResult] = useState<DropResult | null>(null);
  const [selectedMentorado, setSelectedMentorado] = useState<{
    nome: string;
    destino: string;
  } | null>(null);
  const toast = useToast()
  useEffect(() => {
    if (payLoad && payLoad.user && payLoad.user.mentoria_id) {
      ConsultaEtapaMentoria(payLoad.user.mentoria_id);
    }

  }, []);

  const onDragEnd = (result: DropResult) => {
    setDragResult(result);
    if (!result.destination) return;

    if (result.type === "COLUMN") {
      // Verifica se a primeira etapa está sendo movida
      if (result.source.index === 0 || result.destination.index === 0) {
        toast({
          title: "A etapa inicial não pode ser movida!",
          status: "warning",
          position: "top",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const newEtapas = Array.from(etapasMentoria);
      const [removed] = newEtapas.splice(result.source.index, 1);
      newEtapas.splice(result.destination.index, 0, removed);

      // Mapear etapas para incluir apenas id e posicao
      const mappedEtapas: { id: string, posicao: number }[] = newEtapas.map((etapa, index) => ({
        id: etapa.id,
        posicao: index
      }));

      ReordenarEtapasMentoria({
        mentoria_id: String(payLoad?.user.mentoria_id),
        etapas: mappedEtapas
      }, newEtapas);
    } else {
      const sourceEtapa = etapasMentoria.find(
        (etapa) => etapa.id === result.source.droppableId
      );
      const destinoEtapa = etapasMentoria.find(
        (etapa) => etapa.id === result.destination?.droppableId
      );
      const mentorado = sourceEtapa?.mentorados.find(
        (mentorado) => mentorado.id === result.draggableId
      );
      if (mentorado && destinoEtapa) {
        setSelectedMentorado({
          nome: mentorado.nome || "",
          destino: destinoEtapa.titulo,
        });
        setIsConfirmationOpen(true);
      }
    }
  };

  const handleConfirm = () => {
    if (dragResult) {
      const { source, destination } = dragResult;

      if (destination && source) {
        AvancarEtapaMentoria(
          source.droppableId,
          String(payLoad?.user.mentoria_id),
          destination.droppableId,
          dragResult.draggableId
        );
      }
    }
    setIsConfirmationOpen(false);
  };

  return (
    <>
      <TemplateAdmin isWidthFull>
        <VStack
          align="start"
          mt={1}
          minH={"87vh"}
          maxH={"87vh"}
          overflowX="auto"
          overflowY="hidden"
        >
          <HStack spacing={5}>
            <Heading fontSize={18} my="24px" fontWeight={700}>
              Gestão de trilha de mentoria
            </Heading>
            <Tooltip
              label="Gestão de trilha de mentoria, onde os administradores podem criar e gerenciar etapas da jornada do mentorado, mover mentorados reflete na posição atual do mentorado na trilha."
              fontSize="sm"
              borderRadius={"5px"}
            >
              <QuestionOutlineIcon fontSize={"20px"} />
            </Tooltip>
          </HStack>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="all-columns" direction="horizontal" type="COLUMN">
              {(provided) => (
                <Stack
                  gap={"15px"}
                  cursor={"default"}
                  display="flex"
                  flexDirection={["column", "row"]}
                  minW={"100%"}
                  whiteSpace="nowrap"
                  zIndex={0}
                  overflowX="hidden"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {etapasMentoria &&
                    etapasMentoria.map((i, index) => (
                      <Draggable draggableId={i.id} index={index} key={i.id}>
                        {(provided) => (
                          <Stack
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            borderRadius={"14px"}
                          >
                            <Droppable key={`${i.id}`} droppableId={`${i.id}`}>
                              {(provided, snapshot) => (
                                <Stack
                                  ref={provided.innerRef}
                                  borderRadius={"14px"}
                                  {...provided.droppableProps}
                                >
                                  <CardStep
                                    key={index}
                                    index={index}
                                    edit={!(i.mentorados.length > 0)}
                                    title={i.titulo}
                                    description={i.descricao || ""}
                                    qtl={i.mentorados.length}
                                    colorHeader="gray.200"
                                    etapa_id={i.id}
                                    mentoria_id={i.mentoria_id}
                                    isOpenModal={onOpen}
                                    anexos={i.anexos}
                                    mentores={i.mentores.map((i) => {
                                      return {
                                        id: i.id,
                                        carga_horaria: i.carga_horaria,
                                        mentor: {
                                          id: i.mentor.id,
                                          nome: i.mentor.nome,
                                          foto: i.mentor.foto || ""
                                        }
                                      };
                                    }) || []
                                    }
                                    sendIndex={() => setEtapaIndex(index)}
                                  >
                                    {i.mentorados &&
                                      i.mentorados.map((j, index) => (
                                        <Draggable
                                          key={j.id}
                                          draggableId={String(j.id)}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <Stack
                                              w="full"
                                              bgColor={'white'}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              borderRadius={'14px'}
                                              boxShadow={snapshot.isDragging ? "0px 4px 12px rgba(0, 0, 0, 0.1)" : "none"}>

                                              <ItemMentorado
                                                key={index}
                                                foto={j.foto || ""}
                                                nome={j.nome || ""}
                                                mentoria_id={i.mentoria_id}
                                                usuario_id={j.id || ""}
                                                etapa_id={i.id}
                                              />
                                            </Stack>
                                          )}
                                        </Draggable>
                                      ))}
                                  </CardStep>
                                </Stack>
                              )}
                            </Droppable>

                          </Stack>
                        )}
                      </Draggable>
                    ))}
                  <Button
                    borderRadius={"14px"}
                    w={{ base: "100%", md: "100%" }}
                    bgColor={"xmentor.background_content"}
                    p={"16px"}
                    color={"xmentor.primary"}
                    h={"55px"}
                    minW={100}
                    fontSize={"16px"}
                    borderColor={"xmentor.borderCards"}
                    onClick={openModal}
                  >
                    Nova etapa
                  </Button>
                  {provided.placeholder}

                </Stack>

              )}

            </Droppable>
          </DragDropContext>
        </VStack>
      </TemplateAdmin>
      <CreateStepModal
        mentoria_id={String(payLoad?.user.mentoria_id)}
        isOpen={isModalOpen}
        onClose={() => {
          closeModal();
          setEtapaIndex(-1);
        }}
      />
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirm}
        title={`Tem certeza que deseja mover ${selectedMentorado?.nome} para a ${selectedMentorado?.destino}?`}
        description={`Você quer mesmo mover ${selectedMentorado?.nome} para a ${selectedMentorado?.destino}?`}
      />
    </>
  );
}

export interface ICardStep {
  colorHeader: string;
  title: string;
  qtl: number;
  description: string;
  mentoria_id: string;
  etapa_id: string;
  isOpenModal: () => void;
  sendIndex: () => void;
  children?: ReactNode;
  edit?: boolean;
  mentores: IMentoresCargaHoraria[];
  index: number;
  anexos: IAnexo[]
}
export interface IMentoresCargaHoraria {
  carga_horaria: number;
  id: string;
  mentor: {
    id: string;
    nome: string;
    foto: string;
  };
}
export interface IMentor {
  id: string;
  name: string;
}

export interface IItemMentorado {
  foto: string;
  nome: string;
  mentoria_id: string;
  usuario_id: string;
  etapa_id: string;
}

export interface ICreateStepModal {
  isOpen: boolean;
  mentoria_id: string;
  onClose: () => void;
}

export function JornadaTrilha() {
  return (
    <MentoriaProvider>
      <JornadaTrilhaChildren />
    </MentoriaProvider>
  );
}
