import apiXmentor from "../../server/apiServer";
import { IMentores } from "../../pages/admin/trilha/DTOs/mentor.dto";
import { IDataHoraAdicional, IDataReordenarEtapas } from "./types";

export async function criarEtapaMentoria(
  titulo: string,
  descricao: string,
  mentoria_id: string,
  mentores: IMentores[]
) {
  try {
    const request = await apiXmentor.post("/etapa-mentoria", {
      titulo,
      mentoria_id,
      descricao,
      mentores,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadFileEtapaMentoria(data: FormData) {
  try {
    const request = await apiXmentor.post("/etapa-mentoria/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function consultaEtapaMentoria(mentoria_id: string) {
  try {
    const request = await apiXmentor.get(`/etapa-mentoria/${mentoria_id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function listarTrilhaUsuario(usuario_id: string) {
  try {
    const request = await apiXmentor.get(
      `/etapa-mentoria/usuario/${usuario_id}`
    );
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function avancarEtapaMentoria(
  mentoria_id: string,
  etapa_id: string,
  usuario_id: string
) {
  try {
    const request = await apiXmentor.put(`/etapa-mentoria/avancar`, {
      mentoria_id,
      etapa_id,
      usuario_id,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deletarEtapaMentoria(id: string) {
  try {
    const request = await apiXmentor.delete(`/etapa-mentoria/${id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}


export async function deletarHistoricoTrilha(etapa_id: string, mentorado_id: string) {
  try {
    const request = await apiXmentor.delete(`/etapa-mentoria/historico-mentorado/${etapa_id}/${mentorado_id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function deletarAnexoEtapaMentoria(id: string) {
  try {
    const request = await apiXmentor.delete(`/etapa-mentoria/anexo/${id}`);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function alterarEtapaMentoria(
  id: string,
  titulo: string,
  descricao: string,  
  mentores: IMentores[]
) {
  try {
    const request = await apiXmentor.patch("/etapa-mentoria", {
      id,
      titulo,
      descricao,
      mentores,
    });
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}


export async function cadastrarHorariaExtra(data: IDataHoraAdicional) {
  try {
    const request = await apiXmentor.post("/etapa-mentoria/hora-extra/mentor", data);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function reordenarEtapasMentoria(data: IDataReordenarEtapas ){
  try {
    const request = await apiXmentor.patch("/etapa-mentoria/reordenar", data);
    return request.data;
  } catch (error) {
    return Promise.reject(error);
  }
}