import React, { useEffect, useState } from "react";

import {
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { TemplateMentorado } from "../../../components/layout/mentorado/TemplateMentorado";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { CardMentor } from "../../../components/CardMentor";
import { LogoSvg } from "../../../components/layout/LogoSvg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IUser } from "../../../context/AuthProvider/types";

export function MentoradoMentor() {
  const { id } = useParams();
  console.log(id);
  const { SearchUser, users, payLoad, FindUserById } = useAuth();
  const [data, setData] = useState<IUser>();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (payLoad?.user.mentoria_id)
      SearchUser({
        nome: "",
        pagina: 0,
        perfil: "mentor",
        mentoria_id: payLoad?.user.mentoria_id,
        mentor: "",
      });
  }, []);

  useEffect(() => {
    const userSelected = users?.data.find((user) => user.id === id);
    setData(userSelected);
  }, [id]);

  if (!id) {
    return <Text>Mentor não encontrado</Text>;
  }

  async function copiar(texto: string) {
    if (texto) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard
          .writeText(texto)
          .then(() => {
            toast({
              position: "top",
              description: "Copiado para área de transferência",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          })
          .catch(() => {
            toast({
              position: "top",
              description: "Erro ao copiar para área de transferência",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          });
      } else {
        toast({
          position: "top",
          description: "Seu navegador não suporta esse recurso!",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  }

  const handleMentorSelection = (mentorId: string) => {
    navigate(`/mentorado/mentor/${mentorId}`);
  };

  return (
    <TemplateMentorado>
      <VStack align={"start"} spacing={0} fontWeight={"normal"}>
        <Button
          px={0}
          variant={"ghost"}
          leftIcon={<IoIosArrowRoundBack size={30} />}
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
        <Text fontSize={24}>Mentor</Text>
      </VStack>

      <VStack
        align={"start"}
        my={"40px"}
        spacing={0}
        fontWeight={"normal"}
        bgColor="xmentor.background_content"
        p={3}
        rounded={10}
      >
        <HStack
          display="flex"
          bgColor="xmentor.primary"
          w={["100%"]}
          borderTopRadius={10}
          spacing={0}
          pt={0}
          px={0}
          align="center"
          justifyContent="center"
        >
          <Image
            src={`${process.env.REACT_APP_API}/tmp/user/${data?.imagem_background}`}
            alt=""
            // h={300}
            w="100%"
            objectFit={{ base: "scale-down", lg: "cover" }}
            rounded={10}
          />
        </HStack>
        <Center display="flex" flex={1} my="auto" pb={5}>
          <Text fontSize="30px" fontWeight="bold" mt={5}>
            {data?.nome}
          </Text>
        </Center>
        <Text
          color={"xmentor.text_gray"}
          fontSize={"15px"}
          fontWeight={"400"}
          // hidden={resultRoute.perfil == "Administrador" || resultRoute.perfil == "Mentor"}
        >
          Redes Sociais
        </Text>
        <HStack
          overflow={"auto"}
          maxW={"100%"}
          w="100%"
          bgColor="xmentor.background_content"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {data?.redes_sociais && data.redes_sociais.instagram && (
            <Button
              bgColor={"white"}
              border="1px"
              borderColor={"xmentor.borderCards"}
              size={"sm"}
              minW={"100px"}
              onClick={() => copiar(data.redes_sociais.instagram)}
            >
              <FaInstagram style={{ marginRight: 2 }} />
              {data.redes_sociais.instagram}
            </Button>
          )}
          {data?.redes_sociais && data.redes_sociais.linkedin && (
            <Button
              bgColor={"white"}
              border="1px"
              borderColor={"xmentor.borderCards"}
              size={"sm"}
              minW={"100px"}
              onClick={() => copiar(data.redes_sociais.linkedin)}
            >
              <FaLinkedin style={{ marginRight: 2 }} />
              {data.redes_sociais.linkedin}
            </Button>
          )}
        </HStack>
        <HStack
          display="flex"
          bgColor="xmentor.background_content"
          w={"100%"}
          borderBottomRadius={10}
          spacing={0}
          pt={5}
          align="flex-start"
          justifyContent="space-between"
        >
          <Text maxW={"100%"}>{data?.descricao}</Text>
        </HStack>
      </VStack>
      <VStack
        border={"1px"}
        borderColor={"xmentor.borderCards"}
        borderRadius={"8px"}
        bgColor={"xmentor.background_content"}
        zIndex={1}
        align={"start"}
        h={"auto"}
        w="full"
        mt={{ base: 4, md: 0 }}
        p={6}
      >
        {users && users.data.length > 1 && (
          <Text fontWeight="bold" fontSize={"xl"} mb={"16px"}>
            Conheça nossos mentores
          </Text>
        )}
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          w={"100%"}
          spacing={"24px"}
        >
          {users &&
            users.data.map((data) => {
              if (data.id !== id)
                return (
                  <CardMentor
                    image={String(data.foto)}
                    mentor={String(data.nome)}
                    onClick={() => handleMentorSelection(data.id || "")}
                  />
                );
            })}
        </SimpleGrid>
      </VStack>
    </TemplateMentorado>
  );
}
