import { SearchIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputGroupProps,
  InputLeftElementProps,
} from "@chakra-ui/react";

interface SearchProps {
  search?: string;
  setSearch?: (search: string) => void;
  inputProps?: InputProps; // Props personalizáveis para o Input
  inputGroupProps?: InputGroupProps; // Props personalizáveis para o InputGroup
  inputLeftElementProps?: InputLeftElementProps; // Props para o InputLeftElement
}

export const Search = ({
  search,
  setSearch,
  inputProps = {},
  inputGroupProps = {},
  inputLeftElementProps = {},
}: SearchProps) => {
  const OnSearch = (term: string) => {
    if (setSearch) {
      setSearch(term);
    }
  };

  return (
    <InputGroup maxW="300px" {...inputGroupProps}>
      <InputLeftElement
        pointerEvents="none"
        display="flex"
        h="100%"
        alignItems="center"
        justifyContent="center"
        pl={2}
        {...inputLeftElementProps} // Estilos customizáveis aplicados ao InputLeftElement
      >
        <SearchIcon color="gray.500" fontSize="20px" />
      </InputLeftElement>
      <Input
        placeholder="Pesquisar..."
        value={search}
        onChange={(e) => OnSearch(e.target.value)}
        size="md"
        w={{ base: "full", md: "300px" }}
        bg="#FFF"
        border="1px solid #EAECF0"
        h="56px"
        pl="48px"
        {...inputProps} // Estilos customizáveis aplicados ao Input
      />
    </InputGroup>
  );
};
