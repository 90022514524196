import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Box, HStack, Button, Flex } from '@chakra-ui/react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom'; // Importando o plugin de zoom
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import 'pdfjs-dist/build/pdf.worker.entry';
import { IoIosArrowRoundBack } from 'react-icons/io';

interface ModalPDFViewerProps {
  isOpen: boolean;
  onClose: () => void;
  pdfUrl: string;
}

const ModalPDFViewer: React.FC<ModalPDFViewerProps> = ({ isOpen, onClose, pdfUrl }) => {
  // Inicializar o plugin de zoom
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover, zoomTo } = zoomPluginInstance;

  useEffect(() => {
    // Definir o zoom inicial quando o modal é aberto
    if (isOpen) {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        // Define o zoom para 60% em telas menores
        zoomTo(0.5);
      } else {
        // Define o zoom padrão para 100% em telas maiores
        zoomTo(1);
      }
    }
  }, [isOpen, zoomTo]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Visualizar PDF</ModalHeader>
        <ModalBody padding={0}>
          {/* Controles de zoom */}
          <Flex w="100%" justifyContent="flex-end" alignItems="center" px={{ base: 10, lg: 10 }}>
            <Button
              px={0}
              variant={"ghost"}
              leftIcon={<IoIosArrowRoundBack size={30} />}
              onClick={onClose}
            >
              Voltar
            </Button>
          </Flex>
          <HStack justifyContent="center" spacing={4} px={{ base: 0, lg: 10 }}>
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </HStack>
          
          <Box
            height="80vh"
            overflow="auto"
            px={{ base: 0, lg: 10 }}
          >
            <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
              <Viewer fileUrl={pdfUrl} plugins={[zoomPluginInstance]} />
            </Worker>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalPDFViewer;
