import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Center,
  Heading,
  Stack,
  HStack,
  Text,
  Button,
  Image,
  VStack,
  AvatarGroup,
  Avatar, // Chakra Image component
} from "@chakra-ui/react";
import { IoCalendarClearOutline } from "react-icons/io5";
import { TbClockHour5 } from "react-icons/tb";
import { IEvento } from "../../../../context/EventoProvider/types";
import { useNavigate } from "react-router-dom";

import { formatarHora } from "../../../../utils/formatarData";

interface EventCardMentorProps {
  evento: IEvento;
}

const EventCardMentor: React.FC<EventCardMentorProps> = ({ evento }) => {
  const navigate = useNavigate();
  // Função para calcular o horário de término
  const calculateEndTime = (startTime: string, duration: number): string => {
    const [hours, minutes] = startTime.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes + duration;
    const endHours = Math.floor(totalMinutes / 60);
    const endMinutes = totalMinutes % 60;
    const formattedEndHours = endHours.toString().padStart(2, "0");
    const formattedEndMinutes = endMinutes.toString().padStart(2, "0");

    return `${formattedEndHours}:${formattedEndMinutes}`;
  };

  // Função para formatar a data
  const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("pt-BR", { month: "long" });
    const year = date.getFullYear();

    return `${day} de ${month}, ${year}`;
  };

  const handleClickDetails = () => {
    navigate(`/admin/eventos/${evento.id}`);
    return;
  };

  return (
    <Card
      key={evento.id}
      borderRadius={"lg"}
      overflow={"hidden"}
      shadow={"none"}
      border={"1px"}
      borderColor={"xmentor.borderCards"}
    >
      <CardBody p={0}>
        <div>
          {evento.img ? (
            <Image
              src={`${process.env.REACT_APP_API}/tmp/event/${evento.img}`}
              alt={`Banner do evento ${evento.nome}`}
              objectFit="cover"
              w="full"
              h="200px"
            />
          ) : (
            <Center
              w="full"
              h="200px"
              bg={"xmentor.primary"}
              p={4}
              overflow={"hidden"}
            >
              <Heading textAlign={"center"} color={"rgba(255,255,255,0.6)"}>
                <i>{evento.nome}</i>
              </Heading>
            </Center>
          )}
        </div>
        <Stack p={4} spacing="3">
          <Heading fontSize={"xl"}>{evento.nome}</Heading>
          <HStack>
            <HStack
              textColor={"xmentor.primary"}
              alignItems={"start"}
              spacing={1}
            >
              <IoCalendarClearOutline />
              <Text fontSize={"xs"} textColor={"black"}>
                {formatDate(evento.data_evento)} {/* Exibe a data formatada */}
              </Text>
            </HStack>
            <HStack
              textColor={"xmentor.primary"}
              alignItems={"start"}
              spacing={1}
            >
              <TbClockHour5 />
              <Text fontSize={"xs"} textColor={"black"}>
                {formatarHora(evento.data_evento) || ""} as{" "}
                {calculateEndTime(
                  formatarHora(evento.data_evento) || "",
                  evento.duracao
                )}{" "}
                {/* Exibe o horário inicial e final */}
              </Text>
            </HStack>
          </HStack>
          <VStack w="full" justifyContent="start" mt={2} alignItems="start">
            {evento.participantes && evento.participantes.length > 0 && (
              <>
                <Text color={"GrayText"} fontSize={"sm"}>
                  Lista de presença
                </Text>
                <AvatarGroup size="sm" max={3} borderRadius={"md"}>
                  {evento.participantes.map((participant) => (
                    <Avatar
                      key={participant.id}
                      borderRadius={"md"}
                      name={participant.nome}
                      src={`${process.env.REACT_APP_API}/tmp/user/${participant.foto}`}
                    />
                  ))}
                </AvatarGroup>
              </>
            )}
          </VStack>
        </Stack>
      </CardBody>
      <CardFooter>
        <Button
          size="sm"
          variant="solid"
          bg="xmentor.primary"
          _hover={{ bg: "xmentor.primaryDark" }}
          color={"white"}
          py={5}
          onClick={handleClickDetails}
        >
          Ver detalhes
        </Button>
      </CardFooter>
    </Card>
  );
};

export default EventCardMentor;
