import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  List,
  ListItem,
  Text,
  VStack,
  Spinner,
  Avatar,
  Checkbox,
  Center,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { useEvento } from "../../../../context/EventoProvider/useEvento";
import { EventoProvider } from "../../../../context/EventoProvider";
import { formatarHora } from "../../../../utils/formatarData";
import { IoArrowBackOutline } from "react-icons/io5";
import { TemplateAdmin } from "../../../../components/layout/admin/TemplateAdmin";
import { MdModeEditOutline } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { IoCalendarClearOutline } from "react-icons/io5";
import { TbClockHour5, TbUserCheck } from "react-icons/tb";
import { ModalEditEvent } from "../sections/modalEditEvent";

const calculateEndTime = (startTime: string, duration: number): string => {
  const [hours, minutes] = startTime.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes + duration;
  const endHours = Math.floor(totalMinutes / 60);
  const endMinutes = totalMinutes % 60;
  const formattedEndHours = endHours.toString().padStart(2, "0");
  const formattedEndMinutes = endMinutes.toString().padStart(2, "0");

  return `${formattedEndHours}:${formattedEndMinutes}`;
};

const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("pt-BR", { month: "long" });
  const year = date.getFullYear();

  return `${day} de ${month}, ${year}`;
};

const EventDetailsPage = ({ id }: { id: string | undefined }) => {
  const navigate = useNavigate();
  const { BuscarEventoPorId, eventoDetalhes, ToggleParticipacao } = useEvento();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [loadingParticipant, setLoadingParticipant] = useState<string | null>(
    null
  ); // Estado de carregamento para participantes

  useEffect(() => {
    if (id) {
      setLoading(true); // Inicia o carregamento
      BuscarEventoPorId(id).finally(() => setLoading(false)); // Finaliza o carregamento após a busca
    }
  }, [id]);

  const handleToggleParticipation = async (
    evento_id: string,
    participante_id: string
  ) => {
    setLoadingParticipant(participante_id); // Define o participante em processo de carregamento
    try {
      await ToggleParticipacao(evento_id, participante_id);
    } catch (error) {
      console.error("Erro ao atualizar participação:", error);
    } finally {
      setLoadingParticipant(null); // Limpa o estado de carregamento
    }
  };

  return (
    <TemplateAdmin>
      {loading ? (
        <VStack align="center" justify="center" h="100vh">
          <Spinner size="xl" color="xmentor.primary" />
        </VStack>
      ) : eventoDetalhes ? (
        <>
          <Button
            alignSelf="flex-start"
            color="blue.500"
            onClick={() => navigate(-1)}
            fontSize={"sm"}
            gap={2}
            variant={"outline"}
            border={"none"}
          >
            <IoArrowBackOutline /> <span>Voltar</span>
          </Button>

          <Heading fontSize={"2xl"} fontWeight={700} mb={2}>
            Detalhes do evento
          </Heading>
          <Flex
            direction={{ base: "column", md: "row" }}
            w={"100%"}
            justifyContent="space-between"
            alignItems={"start"}
            gap={4}
          >
            <VStack
              border={"1px"}
              borderColor={"xmentor.borderCards"}
              borderRadius={"8px"}
              bgColor={"xmentor.background_content"}
              align={"start"}
              zIndex={1}
              w={{ base: "100%", md: "70%" }}
              p={6}
              spacing={6}
            >
              <Box
                w="full"
                borderRadius="md"
                overflow="hidden"
                position="relative"
              >
                {eventoDetalhes.img ? (
                  <Image
                    src={`${process.env.REACT_APP_API}/tmp/event/${eventoDetalhes.img}`}
                    alt="Banner do evento"
                    w="full"
                    h="250px"
                    objectFit="cover"
                  />
                ) : (
                  <Center
                    w="full"
                    h="250px"
                    bg={"xmentor.primary"}
                    p={4}
                    overflow={"hidden"}
                  >
                    <Heading
                      textAlign={"center"}
                      color={"rgba(255,255,255,0.6)"}
                    >
                      <i>{eventoDetalhes.nome}</i>
                    </Heading>
                  </Center>
                )}
              </Box>
              <HStack w="full" justifyContent={"space-between"}>
                <Heading as="h3" fontSize="2xl">
                  {eventoDetalhes.nome}
                </Heading>
                <IconButton
                  colorScheme="primary"
                  bgColor={"xmentor.primary"}
                  color={"white"}
                  fontSize="20px"
                  icon={<MdModeEditOutline />}
                  aria-label="Edit"
                  minW={8}
                  h={8}
                  onClick={() => setIsEditModalOpen(true)}
                />
              </HStack>
              <HStack align={"start"} spacing={4} color={"GrayText"}>
                <VStack>
                  <HiMenuAlt2 size={18} />
                </VStack>
                <VStack align={"start"} spacing={1}>
                  <Text fontSize={"sm"} color="black" fontWeight={700}>
                    Descrição
                  </Text>
                  <Text fontSize={"sm"}>
                    {eventoDetalhes.descricao_instrucoes}
                  </Text>
                </VStack>
              </HStack>
              <HStack align={"start"} spacing={4} color={"GrayText"}>
                <VStack>
                  <IoCalendarClearOutline size={18} />
                </VStack>
                <VStack align={"start"} spacing={1}>
                  <Text fontSize={"sm"} color="black" fontWeight={700}>
                    Data
                  </Text>
                  <Text fontSize={"sm"}>
                    {formatDate(eventoDetalhes.data_evento)}
                  </Text>
                </VStack>
              </HStack>
              <HStack align={"start"} spacing={4} color={"GrayText"}>
                <VStack>
                  <TbClockHour5 size={18} />
                </VStack>
                <VStack align={"start"} spacing={1}>
                  <Text fontSize={"sm"} color="black" fontWeight={700}>
                    Hora
                  </Text>
                  <Text fontSize={"sm"}>
                    {formatarHora(eventoDetalhes.data_evento) || ""} às{" "}
                    {calculateEndTime(
                      formatarHora(eventoDetalhes.data_evento) || "",
                      eventoDetalhes.duracao
                    )}
                  </Text>
                </VStack>
              </HStack>
            </VStack>

            <VStack
              border={"1px"}
              borderColor={"xmentor.borderCards"}
              borderRadius={"8px"}
              bgColor={"xmentor.background_content"}
              zIndex={1}
              w={{ base: "100%", md: "30%" }}
              align={"start"}
              maxH={{ base: "auto", md: "calc(100vh - 220px)" }}
              mt={{ base: 4, md: 0 }}
              p={3}
            >
              <Heading
                as="h3"
                fontSize="md"
                mb={2}
                display="flex"
                alignItems="center"
                gap={2}
              >
                <TbUserCheck size={18} />
                <span>Lista de presença</span>
              </Heading>
              <List spacing={3} w="full">
                {eventoDetalhes.participantes.map((participant) => (
                  <ListItem
                    key={participant.id}
                    display="flex"
                    alignItems="center"
                  >
                    <HStack justifyContent={"space-between"} w={"full"}>
                      <HStack>
                        <Avatar
                          name={participant.nome}
                          src={`${process.env.REACT_APP_API}/tmp/user/${participant.foto}`}
                          borderRadius="lg"
                          size={"sm"}
                        />
                        <VStack align={"start"} spacing={0}>
                          <Text fontWeight={700} fontSize={"sm"}>
                            {participant.nome}
                          </Text>
                          <Text color={"GrayText"} fontSize={"xs"}>
                            {participant.email}
                          </Text>
                        </VStack>
                      </HStack>
                      <HStack spacing={1}>
                        {loadingParticipant === participant.participante_id ? (
                          <Spinner size="sm" />
                        ) : (
                          <Checkbox
                            isChecked={participant.confirmado}
                            onChange={() =>
                              handleToggleParticipation(
                                eventoDetalhes.id,
                                participant.participante_id
                              )
                            }
                            borderRadius="full"
                            colorScheme="green"
                            iconColor="white"
                            iconSize="0.8rem"
                            sx={{
                              "& .chakra-checkbox__control": {
                                borderRadius: "full",
                              },
                            }}
                          />
                        )}
                        <Text color={"GrayText"} fontSize={"xs"}>
                          Participou
                        </Text>
                      </HStack>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </VStack>
          </Flex>
          <ModalEditEvent
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            eventoDetalhes={eventoDetalhes}
          />
        </>
      ) : (
        <VStack align="center" justify="center" h="100vh">
          <Text>Evento não encontrado.</Text>
        </VStack>
      )}
    </TemplateAdmin>
  );
};

export const EventDetails = () => {
  const { id } = useParams();
  return (
    <EventoProvider>
      <EventDetailsPage id={id} />
    </EventoProvider>
  );
};
